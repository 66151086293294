import Api from 'api';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import {
  ISetDocumentsAction,
  IDocumentsPendingAction,
  IDocumentsSetDefaultTab,
} from './types';
import { DocumentsModel } from './types';
import { createGetParams } from '../../utils/getParams';
import { IDocumentSortByDate, IDocumentSortByAlphabet } from './types';

export const sortDocsByDateAction = (sortAsc: boolean): IDocumentSortByDate => {
  return { type: 'DOCUMENTS_SORT_BY_DATE', payload: sortAsc };
};

export const sortDocsByAlphabetAction = (
  sortAsc: boolean,
): IDocumentSortByAlphabet => {
  return { type: 'DOCUMENTS_SORT_BY_ALPHABET', payload: sortAsc };
};

export const setDefaultTabAction = (): IDocumentsSetDefaultTab => ({
  type: 'DOCUMENTS_SET_DEFAULT_TAB',
});

export const getApiDocuments: ActionCreator<
  ThunkAction<
    Promise<ISetDocumentsAction | IDocumentsPendingAction>,
    any,
    null,
    ISetDocumentsAction
  >
> = (filters = {}, filterObjectForReducer:any = {}) => {
  return async (dispatch: Dispatch) => {
    const documentPendingAction: IDocumentsPendingAction = {
      type: 'DOCUMENTS_PENDING',
      payload: true,
    };
    dispatch(documentPendingAction);

    let getParamsString: string = '';
    if (Object.entries(filters).length) {
      getParamsString = createGetParams(filters);
    }

    try {
      const response: AxiosResponse<DocumentsModel> = await Api(false).get(
        `/api/Documents${getParamsString}`,
      );
      if (response.status === 200) {
        const documentsData: DocumentsModel = response.data;
        const setDocuments: ISetDocumentsAction = {
          type: 'SET_DOCUMENTS',
          documentsData: { ...documentsData, ...filters, ...filterObjectForReducer}
        };
        return dispatch(setDocuments);
      }
    } catch (e) {
      console.log(e);
    } finally {
      const documentFinishAction: IDocumentsPendingAction = {
        type: 'DOCUMENTS_PENDING',
        payload: false,
      };
      dispatch(documentFinishAction);
    }
  };
};
