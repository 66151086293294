import { Reducer } from 'redux';
import {
  ProcessorRetailerAction,
  IProcessorRetailerDocumentsState,
} from './types';

const initialValues: IProcessorRetailerDocumentsState = {
  Results: [],
  SearchTerms: '',
  FilterValues: {},
  TotalNumberPages: 1,
  ResultsPerPage: 10,
  TotalResults: 1,
  Error: null,
  PageNum: 1,
  isLoading: false,
  sortedByDate: false,
  sortedByFarmerName: false,
  sortedByFarmName: false,
  sortedByMemberNumber: false,
  sortedByType: false,
  SortKey: [],
  SortDescending: false
};

export const processorRetailerReducer: Reducer<
  IProcessorRetailerDocumentsState,
  ProcessorRetailerAction
> = (state = initialValues, action) => {
  switch (action.type) {
    case 'PROCESSOR_RETAILER_DOCUMENTS_PENDING':
      return { ...state, isLoading: action.payload };
    case 'SET_PROCESSOR_RETAILER_DOCUMENTS':
      return { ...state, ...action.payload };
    case 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_DATE':
      return {
        ...state,
        Results: state.Results.sort((firstDoc, secondDoc) => {
          return action.payload
            ? Date.parse(firstDoc.DateCreated) -
                Date.parse(secondDoc.DateCreated)
            : Date.parse(secondDoc.DateCreated) -
                Date.parse(firstDoc.DateCreated);
        }),
        sortedByDate: action.payload,
      };
    case 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_TYPE':
      return {
        ...state,
        Results: state.Results.sort((firstDoc, secondDoc) => {
          return action.payload
            ? firstDoc.DocumentTypeID - secondDoc.DocumentTypeID
            : secondDoc.DocumentTypeID - firstDoc.DocumentTypeID;
        }),
        sortedByType: action.payload,
      };
    case 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_FARM_NAME':
      return {
        ...state,
        Results: state.Results.sort((fD, sD) => {
          return action.payload
            ? fD.FarmerInfo.FarmName.localeCompare(sD.FarmerInfo.FarmName)
            : sD.FarmerInfo.FarmName.localeCompare(fD.FarmerInfo.FarmName);
        }),
        sortedByFarmName: action.payload,
      };
    case 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_FARMER_NAME':
      return {
        ...state,
        Results: state.Results.sort((fD, sD) => {
          return action.payload
            ? fD.FarmerInfo.FarmerName.localeCompare(sD.FarmerInfo.FarmerName)
            : sD.FarmerInfo.FarmerName.localeCompare(fD.FarmerInfo.FarmerName);
        }),
        sortedByFarmerName: action.payload,
      };
    case 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_MEMBER_NUMBER':
      return {
        ...state,
        Results: state.Results.sort((fD, sD) => {
          return action.payload
            ? fD.FarmerInfo.MemberNumber.localeCompare(
                sD.FarmerInfo.MemberNumber,
              )
            : sD.FarmerInfo.MemberNumber.localeCompare(
                fD.FarmerInfo.MemberNumber,
              );
        }),
        sortedByMemberNumber: action.payload,
      };
    default:
      return { ...state };
  }
};
