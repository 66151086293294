import React from 'react';
import { Modal } from 'react-bootstrap';

interface IProps {
  onSave: () => void;
  onExit: () => void;
  isOpen: boolean;
  text: string;
  primaryButtonText: string;
  cancelButtonText: string;
}

const SaveAssessmentModal = ({
  onSave,
  onExit,
  isOpen,
  text,
  cancelButtonText,
  primaryButtonText,
}: IProps) => {
  return (
    <Modal centered show={isOpen} className="modal-small save-changes-modal">
      <div className="modal-content">
        <Modal.Header className="modal-header" translate="">
          <h5 className="modal-title">{text}</h5>
        </Modal.Header>
        <Modal.Footer className="modal-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn action-secondary"
                  data-dismiss="modal"
                  onClick={onExit}
                >
                  {cancelButtonText}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn action-primary"
                  onClick={onSave}
                >
                  {primaryButtonText}
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export { SaveAssessmentModal };
