import React, { useEffect, useState } from 'react';
import { getImage } from 'store/assessmentSingle/actions';
import './StaticMedia.scss';

interface IProps {
  isGrid?: boolean;
  template?: any;
}
export const StaticMediaFields = ({ isGrid, template }: IProps) => {
  const [imageSrc, setImageSrc] = useState('');
  const [croppedWidth, setCrooppedWidth] = useState();
  const [mediaPositionMargin, setMediaPositionMargin] = useState(0);
  const getImageSrc = async () => {
    try {
      const imageBase64 = await getImage(template.ID);
      setImageSrc(imageBase64);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (template.Parameters.MediaType === 'IMG') {
      getImageSrc();
      setCrooppedWidth(
        template.OriginalWidth > 1120 ? 1120 : template.OriginalWidth,
      );
    }
    let ImageWidth;
    const iframeWidthRegex = /width="(\d+)px"/;
    const match = template?.Parameters?.MediaInformation?.match(
      iframeWidthRegex,
    );
    if (match && match[1]) {
      const widthValue = match[1];
      ImageWidth = +widthValue;
    } else {
      ImageWidth =
        template.OriginalWidth > 1120 ? 1120 : template.OriginalWidth;
    }
    ImageWidth = ImageWidth === 0 ? 400 : ImageWidth;
    if (ImageWidth !== 0) {
      if (template.Parameters.MediaPosition === 'Right') {
        setMediaPositionMargin(1120 - ImageWidth);
      } else if (template.Parameters.MediaPosition === 'Center') {
        setMediaPositionMargin((1120 - ImageWidth) / 2);
      } else {
        setMediaPositionMargin(0);
      }
    }
  }, []);

  return (
    <div>
      {template.Parameters.MediaType === 'IMG' && template.ImageOnly ? (
        <>
         
          <img
            style={{
              marginLeft: `${mediaPositionMargin}px`,
              marginRight: `${isGrid ? '10px' : '0px'}`
            }}
            src={imageSrc}
            width={isGrid ? 300 : croppedWidth}
          />
        </>
      ) : (
        <>
          {template?.Parameters?.MediaInformation ? (
            <>
              <div
                className=" embeeded-media-text"
                style={{
                  marginLeft: `${mediaPositionMargin}px`,
                }}
                dangerouslySetInnerHTML={{
                  __html: template.Parameters.MediaInformation,
                }}
              />
            </>
          ) : (
            <></>
          )}
          {/* <div className=" embeeded-media-text" style={{
              marginLeft: `${mediaPositionMargin}px`
            }} dangerouslySetInnerHTML={{ __html: template.Parameters.MediaInformation }} /> */}
        </>
      )}
    </div>
  );
};
