import { Reducer } from 'redux';
import { IDocumentsState, DocsActions } from './types';
import { DocumentInterface } from './types';

const initialDocumentsState: IDocumentsState = {
  isLoading: false,
  TotalNumberPages: 1,
  PageNum: 1,
  ResultsPerPage: 10,
  Results: [],
  TotalResults: 1,
  docType: 1,
  Error: null,
  sortedByAlphabet: false,
  sortedByDate: true,
  sortedByDocName: false,
  SortKey: [],
  SortDescending: false
};

const sortByDate = (
  firstDoc: DocumentInterface,
  secondDoc: DocumentInterface,
): number => {
  const firstDate = Date.parse(firstDoc.DateCreated);
  const secondDate = Date.parse(secondDoc.DateCreated);

  return secondDate - firstDate;
};

export const documentsReducer: Reducer<IDocumentsState, DocsActions> = (
  state = initialDocumentsState,
  action,
) => {
  switch (action.type) {
    case 'SET_DOCUMENTS':
      console.log(action.documentsData)
      return {
        ...state,
        ...action.documentsData,
        // Results: action.documentsData.Results,
        // // Results: !action.sortBy ?
        // //   (!action.sortedByDate ? action.documentsData.Results.sort(sortByDate) : action.documentsData.Results.sort(sortByDate).reverse())
        // //   : action.documentsData.Results.sort((firstEl, secondEl) => {
        // //     return action.sortBy
        // //       ? firstEl.DocumentName.localeCompare(secondEl.DocumentName)
        // //       : secondEl.DocumentName.localeCompare(firstEl.DocumentName);
        // //   }),
        // TotalNumberPages: action.documentsData.TotalNumberPages,
      };
    case 'DOCUMENTS_PENDING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'DOCUMENTS_SORT_BY_ALPHABET':
      return {
        ...state,
        sortedByAlphabet: action.payload,
        Results: state.Results.sort((firstEl, secondEl) => {
          return action.payload
            ? firstEl.DocumentName.localeCompare(secondEl.DocumentName)
            : secondEl.DocumentName.localeCompare(firstEl.DocumentName);
        }),
      };
    case 'DOCUMENTS_SORT_BY_DATE':
      return {
        ...state,
        sortedByDate: action.payload,
        Results: action.payload
          ? state.Results.sort(sortByDate)
          : state.Results.sort(sortByDate).reverse(),
      };
    case 'DOCUMENTS_SET_DEFAULT_TAB':
      return {
        ...state,
        docType: initialDocumentsState.docType,
      };

    default:
      return {
        ...state,
      };
  }
};
