import { SimpleOption } from 'components/Form/Select/Option/SimpleOption';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { translateTabContentText } from 'utils/helpers/useTranslation';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface IProps {
  isGrid?: boolean;
  data?: any;
  translations?: any;
  name: string;
  labelText?: string;
  options: Array<any>;
  disabled?: boolean;
  required?: boolean;
  conditionToShow?: boolean;
  imageFile?: any;
  OriginalHeight?: number | null;
  ImageHeight?: number | null;
  OriginalWidth?: number | null;
  ImageWidth?: number | null;
  OriginalSize?: boolean;
  MaintainAspectRatio?: boolean;
  placeholder?: string;
  newData?: any;
  oneField?: any;
  setNewData?: React.Dispatch<React.SetStateAction<any>>;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  setOpenMenu?: any;
  selectMenuOpen?: any;
}
const MultiSelect = ({
  isGrid,
  data,
  setChangedFieldsId,
  oneField,
  newData,
  translations,
  name,
  labelText,
  options,
  conditionToShow,
  imageFile,
  disabled = false,
  required = false,
  placeholder,
  OriginalSize,
  OriginalWidth,
  OriginalHeight,
  ImageHeight,
  ImageWidth,
  MaintainAspectRatio,
  setOpenMenu,
}: IProps) => {
  const [field, meta, helpers] = useField(name);
  const [newOptions, setNewOptions] = useState([]);
  
  useEffect(() => {
    const modifiedOptions = conditionToShow
      ? [
          {
            value: 'select from list',
            label: 'Select a value from list',
          },
          ...options?.map(o => ({
            value: o.value,
            label: translateTabContentText(o.label, translations),
          })),
        ]
      : [{ value: 'select from list', label: 'Select a value from list' }];
    setNewOptions(modifiedOptions);
  }, [conditionToShow, options, translations]);

  const changeFieldsValue = checkedValues => {
    newData?.Pages?.forEach(tab =>
      tab?.Fields?.forEach(f => {
        if (f.Template.ID === oneField.Template.ID) {
          f.Value = checkedValues.length > 0 ? checkedValues : null;
        }
      })
    );
  };

  const handleSetValue = pickedValues => {
    if (pickedValues.length > 0) {
      changeFieldsValue(pickedValues.map(pv => pv.value));
    }
    return pickedValues;
  };

  return (
    <>
      <Form.Group controlId={field.name} className="form-group-select">
        {labelText && (
          <Form.Label>
            {required && <span className="required-indicator">*</span>}
            {labelText}
          </Form.Label>
        )}
        {imageFile && OriginalSize ? (
          <img
            height={OriginalHeight}
            width={OriginalWidth}
            src={`data:image/png;base64, "`}
            alt="img"
          />
        ) : null}
        {imageFile && !OriginalSize && !MaintainAspectRatio ? (
          <img
            height={ImageHeight}
            width={ImageWidth}
            src={`data:image/png;base64, `}
            alt="img"
          />
        ) : null}
        {imageFile && !OriginalSize && MaintainAspectRatio ? (
          <img width={ImageWidth} src={``} alt="img" />
        ) : null}
        <Select
          selectProps={translations}
          className="simple-select"
          options={newOptions}
          placeholder={placeholder}
          isMulti
          {...field}
          value={
            newOptions && conditionToShow
              ? handleSetValue(
                  newOptions.filter(o =>
                    field.value.includes(o.value)
                  )
                )
              : handleSetValue([])
          }
          onMenuOpen={() => setOpenMenu?.(true)}
          onMenuClose={() => setOpenMenu?.(false)}
          onChange={options => {
            const selectedValues = options ? options.map(o => o.value) : [];
            setChangedFieldsId(prev => {
              if (!prev.includes(isGrid ? data.Guid : field.name)) {
                return isGrid ? [...prev, data.Guid] : [...prev, field.name];
              }
              return prev;
            });
            helpers.setValue(selectedValues);
            changeFieldsValue(selectedValues);
          }}
          isDisabled={disabled}
          components={{
            Option: SimpleOption,
            IndicatorSeparator: null,
          }}
        />
        {meta.error && (
          <p className="error-wrapper">
            {translateTabContentText(meta.error, translations)}
          </p>
        )}
      </Form.Group>
    </>
  );
};

export default MultiSelect;
