import { ISuppliersState, SuppliersActions } from './types';
import { Reducer } from 'redux';

const initialSuppliersState: ISuppliersState = {
  Error: null,
  Results: [],
  TotalNumberPages: 1,
  TotalResults: 1,
  FilterValues: {
    Species: [],
    Processor: [],
    SystemType: [],
    Location: [],
    Project: [],
  },
  isLoading: false,
  SearchTerms: '',
  SortDescending: false,
  ResultsPerPage: 10,
  PageNum: 1,
  Processor: '',
  Project: '',
  Species: '',
  SystemType: '',
  Location: '',
  sortedByAssessment: false,
  sortedByCo2: false,
  sortedByFarmer: false,
  sortedByFarmName: false,
  SortKeys: [],
};

const suppliersReducer: Reducer<ISuppliersState, SuppliersActions> = (
  state = initialSuppliersState,
  action,
) => {
  switch (action.type) {
    case 'SUPPLIERS_PENDING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_SUPPLIERS':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
export { suppliersReducer };
