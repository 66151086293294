import IconDownload from 'assets/icons/IconDownload';
import { ErrorAssessmentModal } from 'components/ErrorAssessmentModal';
import React, { useEffect, useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  downloadReport,
  getAssesmentReportsList,
} from 'store/assessmentSingle/actions';
import './styles.scss';
import Loader from './Loader';

interface IProps {
  assesmentID: number;
}

const DownloadReportButton = ({ assesmentID }: IProps) => {
  const { t, i18n } = useTranslation();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAssessmentReports = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAssesmentReportsList(assesmentID);
      setReportsList(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [assesmentID]);

  useEffect(() => {
    getAssessmentReports();
  }, [getAssessmentReports]);

  const handleDownload = async (reportId: number, reportName: string) => {
    try {
      setLoading(true);
      const response = await downloadReport(assesmentID, reportId);
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Report-${reportName}_Assesment-${assesmentID}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setLoading(false);

    } catch (error) {
      console.log(error);
      setShowErrorPopup(true);
      setLoading(false);

    }
  };

  //@ts-ignore
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    //@ts-ignore
    <a
      href=""
      //@ts-ignore
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <button className="btn action-secondary">
        <div>{loading ? <Loader /> : 'Generate Report'}</div>
      </button>
    </a>
  ));

  return (
    reportsList?.length > 0 && (
      <>
        <div className="download-report-button">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu title="">
              {loading ? (
                <Loader />
              ) : (
                reportsList.map((report, index) => (
                  <React.Fragment key={report.ID}>
                    <Dropdown.Item as="div" className="report-dropdown-item">
                      <IconDownload color={'black'} />
                      <a
                        className="download-report-link"
                        onClick={() => handleDownload(report.ID, report.Name)}
                      >
                        Download {report.Name}
                      </a>
                    </Dropdown.Item>
                    {index !== reportsList.length - 1 ? (
                      <Dropdown.Divider />
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ErrorAssessmentModal
          isOpen={showErrorPopup}
          onExit={() => setShowErrorPopup(false)}
          text={`Error downloading document`}
        />
      </>
    )
  );
};

export default DownloadReportButton;
