import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select, { Theme, components } from 'react-select';
// import './SelectComponent.scss';
import '../Form/Select/Select.scss';
import { SimpleOption } from '../Form/Select/Option/SimpleOption';
import { useTranslation } from 'react-i18next';

interface IOptionsInterface {
  value: string;
  label: string;
  icon?: any;
}

interface IProps {
  options: IOptionsInterface[];
  label: string;
  value: any;
  isMulti?: boolean;
  onChange: (value: any) => void;
}

export const SelectComponent: FC<IProps> = props => {
  const customStyles = {
    multiValue: (base, state) => ({
      ...base,
      maxWidth: '170px',
    }),
  };
  const { isMulti = false } = props;
  const { t, i18n } = useTranslation();
  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">{t('no options')}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Form.Group className="form-group-select">
      <Form.Label>{props.label}</Form.Label>

      <Select
        isMulti={isMulti}
        components={{
          Option: SimpleOption,
          IndicatorSeparator: null,
          NoOptionsMessage,
        }}
        options={props.options}
        placeholder={t('RM_select')}
        value={
          isMulti
            ? props.value
            : props.options?.filter(o => o.value === props.value)
        }
        onChange={option => props.onChange(option)}
        styles={customStyles}
        // @ts-ignore
        getOptionLabel={e => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: '18px',
            }}
          >
            {e.icon}
            <span style={{ marginLeft: 10 }}>{e.label}</span>
          </div>
        )}
      />
    </Form.Group>
  );
};
