import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TablePagination } from 'components/TablePagination';
import { TableFilter } from 'components/TableFilter';
import {
  getProcessorDocsAction,
} from 'store/processorRetailerDocuments/actions';
import { IAppState } from 'store';
import { formatDate } from 'services';
import TableLoader from 'components/TableLoader';
import Api from 'api';
import CrossIcon from 'assets/icons/ic-choice-decline.svg';
import { TableStub } from '../../../components/TableStub';
import { useNotifications } from '../../../hooks/useNotification';
import { NotificationsTypes } from '../../../store/notifications/types';
import { useTranslation } from "react-i18next";



const ProcessorRetailerDocuments = () => {
  useNotifications(NotificationsTypes.documents);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const docsConfig = {
    1: t('DOCUMENTS_Reports'),
    2: t('DOCUMENTS_Certificates'),
    3: t('DOCUMENTS_Information'),
  };

  const {
    isLoading,
    sortedByMemberNumber,
    Results,
    sortedByFarmerName,
    sortedByFarmName,
    sortedByType,
    sortedByDate,
    PageNum,
    TotalResults,
    ResultsPerPage,
    TotalNumberPages,
    SearchTerms,
    SortKey,
    SortDescending
  } = useSelector((state: IAppState) => state.processorRetailerDocs);

  const [searchTerm, setSearchTerm] = useState(SearchTerms);

  const dispatchWithFilters = (filters = {}, filterForState = {}) => {
    dispatch(
      getProcessorDocsAction({
        ResultsPerPage,
        PageNum,
        SearchTerms,
        ...filters,
      },
        filterForState,
      ),

    );
  };

  useEffect(() => {
    dispatchWithFilters({SortDescending});
  }, []);

  const onSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const onSearch = () => {
    if (searchTerm.length) {
      dispatchWithFilters({ SearchTerms: searchTerm, PageNum: 1 });
    }
  };

  // const onSortByFarmName = () => {
  //   dispatch(sortProcessorDocsByFarmNameAction(!sortedByFarmName));
  // };

  const onSortByFarmName = () => {
    dispatchWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByFarmName,
        SortKey: ['FarmName'],
      },
      { sortedByFarmName: !sortedByFarmName },
    );
  };

  // const onSortByFarmerName = () => {
  //   dispatch(sortProcessorDocsByFarmerNameAction(!sortedByFarmerName));
  // };

  const onSortByFarmerName = () => {
    dispatchWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByFarmerName,
        SortKey: ['FarmerName'],
      },
      { sortedByFarmerName: !sortedByFarmerName },
    );
  };

  const onSortByType = () => {
    dispatchWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByType,
        SortKey: ['DocumentType'],
      },
      { sortedByType: !sortedByType },
    );
  };

  // const onSortByType = () => {
  //   dispatch(sortProcessorDocsByTypeAction(!sortedByType));
  // };

  const onSortByMember = () => {
    dispatchWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByMemberNumber,
        SortKey: ['SupplierNumber'],
      },
      { sortedByMemberNumber: !sortedByMemberNumber },
    );
  };

  // const onSortByMember = () => {
  //   dispatch(sortProcessorByMemberNumberAction(!sortedByMemberNumber));
  // };

  const onSortByDate = () => {
    dispatchWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByDate,
        SortKey: ['DateCreated'],
      },
      { sortedByDate: !sortedByDate },
    );
  };

  // const onSortByDate = () => {
  //   dispatch(sortProcessorDocsByDateAction(!sortedByDate));
  // };

  const onChangePage = pageNumber => {
    // dispatchWithFilters({ PageNum: pageNum });
    dispatchWithFilters({
      ResultsPerPage: ResultsPerPage,
      PageNum: pageNumber,
      SortKey,
      SortDescending,
    });
  };

  const onChangeResultsPerPage = resultsPerPage => {
    dispatchWithFilters({ ResultsPerPage: resultsPerPage, SortDescending, SortKey });
  };

  const resetSearch = () => {
    setSearchTerm('');
    dispatchWithFilters({ SearchTerms: '' });
  };

  const downloadPdf = useCallback((fileName, fileType, farmerId) => {
    Api(false)
      .get(
        `/api/Documents/GetDocumentForFarmer?documentName=${fileName}&type=${fileType}&farmerID=${farmerId}`,
        { responseType: 'blob' },
      )
      .then(res => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl);
      });
  }, []);

  return (
    <div className="container-custom container">
      <div className="row">
        <div className="col-12">
          <h2 className="table-heading">{t('DOCUMENTS_Documents')}</h2>
          {isLoading ? (
            <TableLoader />
          ) : (
            <>
              <div className="filter-utilities">
                <div className="form-group search-group utilities-item">
                  <div className="input-inner">
                    <input
                      type="text"
                      className="form-control"
                      id="search-bar"
                      placeholder={t('DOCUMENTS_Search by document, farmer name…')}
                      onChange={onSearchChange}
                      value={searchTerm}
                    />
                    {searchTerm.length > 0 && (
                      <span className="cross-icon">
                        <img
                          src={CrossIcon}
                          alt="cross icon"
                          onClick={resetSearch}
                        />
                      </span>
                    )}
                  </div>
                </div>
                <div className="btn-wrapper utilities-item">
                  <button
                    className="btn action-primary search-btn"
                    onClick={onSearch}
                  >
                    {t('SUPLIERS_Search')}
                  </button>
                </div>
              </div>
              <div className="custom-table retailer-documents-table">
                <div className="table-header">
                  <div className="name-wrapper">
                    <span>{t('DOCUMENTS_Document name')}</span>
                  </div>
                  <div className="member-number-wrapper">
                    <TableFilter
                      active={sortedByMemberNumber}
                      onSort={onSortByMember}
                      text={t('DOCUMENTS_Supplier number')}
                    />
                  </div>
                  <div className="farmer-name-wrapper">
                    <TableFilter
                      active={sortedByFarmerName}
                      onSort={onSortByFarmerName}
                      text={t('DOCUMENTS_Farmer name')}
                    />
                  </div>
                  <div className="farm-name-wrapper">
                    <TableFilter
                      active={sortedByFarmName}
                      onSort={onSortByFarmName}
                      text={t('DOCUMENTS_Farm name')}
                    />
                  </div>
                  <div className="date-created-wrapper">
                    <TableFilter
                      active={sortedByDate}
                      onSort={onSortByDate}
                      text={t('DOCUMENTS_Date')}
                    />
                  </div>
                  <div className="type-wrapper">
                    <TableFilter
                      active={sortedByType}
                      onSort={onSortByType}
                      text={t('DOCUMENTS_Type')}
                    />
                  </div>
                  <div className="action-wrapper">
                    <span>{t('DOCUMENTS_Action')}</span>
                  </div>
                </div>
                {Results.length ? (
                  <div className="table-body">
                    {Results.map((doc, i) => (
                      <div
                        className="table-item"
                        key={`${doc.DocumentName}${i}`}
                      >
                        <div className="name-wrapper">
                          <span>{doc.DocumentName}</span>
                        </div>
                        <div className="member-number-wrapper">
                          <span>{doc.FarmerInfo.MemberNumber}</span>
                        </div>
                        <div className="farmer-name-wrapper">
                          <span>{doc.FarmerInfo.FarmerName}</span>
                        </div>
                        <div className="farm-name-wrapper">
                          <span>{doc.FarmerInfo.FarmName}</span>
                        </div>
                        <div className="date-created-wrapper">
                          <span>
                            {formatDate(doc.DateCreated, 'MMMM D, YYYY')}
                          </span>
                        </div>
                        <div className="type-wrapper">
                          <span>{docsConfig[doc.DocumentTypeID]}</span>
                        </div>
                        <div className="action-wrapper">
                          <span
                            className="action-primary btn table-action-btn"
                            onClick={() => {
                              downloadPdf(
                                doc.DocumentName,
                                doc.DocumentTypeID,
                                doc.FarmerInfo.AlltechID,
                              );
                            }}
                          >
                            {t('AD_View')}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <TableStub description={t('DOCUMENTS_There are currently no documents to view')} />
                )}
              </div>
            </>
          )}
          <TablePagination
            pagesCount={TotalNumberPages}
            changePage={onChangePage}
            changeItemsPerPageCount={onChangeResultsPerPage}
            activePageNumber={PageNum}
            itemsPerPage={ResultsPerPage}
            totalItems={TotalResults}
          />
        </div>
      </div>
    </div>
  );
};
export { ProcessorRetailerDocuments };
