export const showField = (archived, visibleExternally) => {
    let showField;
    if (archived === true && visibleExternally === false) {
      showField = false;
    } else if (archived === false && visibleExternally === true) {
       showField = true;
    } else {              
      showField = false;
    }
    return showField;
}