import React from 'react';
import './App.scss';
import { GlobalRouter } from './router/GlobalRouter';
import { AuthProvider } from './providers/AuthProvider';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

export const App = () => {
  const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.initialize('G-DWPSRTZZRH');
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
  return (
    <Router history={history}>
      <AuthProvider>
        <GlobalRouter/>
      </AuthProvider>
    </Router>
  );
};
