import React from 'react';
import { useField } from 'formik';
import './TextArea.scss'

interface IProps {
  name: string;
  labelText: string;
  disabled?: boolean;
  required?: boolean;
}

export const Textarea = ({
  name,
  labelText,
  disabled = false,
  required = false,
}: IProps) => {
  const [field, meta] = useField(name);

  return (
    <div className="form-group from-group-textarea">
      <div className="label-wrapper">
        {required && <span className="required-indicator">*</span>}
        {labelText && <label htmlFor={field.name}>{labelText}</label>}
      </div>
      <textarea
        className={`form-control ${meta.error && 'is-invalid'}`}
        id={field.name}
        {...field}
        value={field?.value || ''}
        disabled={disabled}
      />
      {meta.error && <p className="error-wrapper">{meta.error}</p>}
    </div>
  );
};
