import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './Grid.scss';
import { DataCollectionFieldModel } from '../../pages/Assessment/types';
import { fieldsConfig } from '../common/TabContent';
import { SelectInput } from '../Form/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  addGridRowAction,
  removeGridRowAction,
} from '../../store/assessmentSingle/actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '../../../src/assets/icons/ic-info.svg';
import { translateTabContentText } from 'utils/helpers/useTranslation';
import { CONDITIONS } from 'utils/helpers/enum';
import { checkAllConditionsFollow } from 'utils/helpers/useCondition';
import { TableHeader } from './TableHeaderWithImg';
import { IAppState } from 'store';
import { IAssessmentSingleState } from 'store/assessmentSingle/types';
import { ImageInHeader } from './Image';
import { showField } from 'utils/helpers/showField';
import { getMirroredFieldValue } from 'utils/helpers/mirrored.functions';
import MultiSelect from 'components/MultiSelect';
import { getDisplayRowsNumber } from 'utils/helpers/grid.helpers';

interface IProps {
  allFields: any;
  newData?: any;
  data: DataCollectionFieldModel;
  pageNum: number;
  assId: number;
  translations: any;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
}

export const Grid = ({
  newData,
  allFields,
  data,
  pageNum,
  assId,
  translations,
  setChangedFieldsId,
}: IProps) => {
  const dispatch = useDispatch();
  const [lengthFields, setLengthFields] = useState(true);
  const [minLengthFields, setMinLengthFields] = useState(false);
  const [showRowsAsColumns, setShowRowsAsColumns] = useState(false);
  const [selectMenuOpen, setSelectMenuOpen] = useState(false);
  const [fixedTextItemsArray, setFixedTextItemsArray] = useState([]);
  const ScrollRef = useRef<any>();
  const [rowsCounter, setRowsCounter] = useState(
    data.Template.Validator.AllRowsVisible
      ? data.Template.Validator.MaxRows
      : getDisplayRowsNumber(
          data.GridChildren,
          data.Template.Validator.MinRows,
        ),
  );
  const initialMaxAmount = useMemo(() => data.GridChildren.length, [data]);
  const onAddRowClick = () => {
    setRowsCounter(prevRowsCounter => prevRowsCounter + 1);
    console.log(data.Template.Name);
    dispatch(addGridRowAction({ assId, pageNum, gridId: data.Template.ID }));
  };

  const onRemoveRowClick = () => {
    setRowsCounter(prevRowsCounter => Math.max(prevRowsCounter - 1, 1));
    dispatch(
      removeGridRowAction({
        assId,
        pageNum,
        gridId: data.Template.ID,
        removeCount: rowsCounter,
      }),
    );
  };

  useEffect(() => {
    if (initialMaxAmount > rowsCounter && rowsCounter === 1) {
      const removeCount = initialMaxAmount - rowsCounter;
      for (let i = 0; i < removeCount; i++) {
        onRemoveRowClick();
      }
    }
  }, [initialMaxAmount, rowsCounter]);

  useMemo(() => {
    data.Template?.Parameters?.RowTemplate?.FixedTextItems
      ? setFixedTextItemsArray(
          data.Template?.Parameters?.RowTemplate?.FixedTextItems,
        )
      : setFixedTextItemsArray([]);
    data.GridChildren.length < 1
      ? setLengthFields(false)
      : setLengthFields(true);
    data.GridChildren[0]?.length > 4
      ? setMinLengthFields(true)
      : setMinLengthFields(false);
    data.Template.Parameters.ShowRowsAsColumns
      ? setShowRowsAsColumns(true)
      : setShowRowsAsColumns(false);
  }, []);

  const handleChange = useCallback(() => {
    setChangedFieldsId(prev => {
      if (!prev.includes(data.Guid)) {
        return [...prev, data.Guid];
      }
      return prev;
    });
  }, [setChangedFieldsId, data.Guid]);

  return (
    <div className="grid-wrapper">
      <div className="grid-heading">
        <p className="grid-title">
          {translateTabContentText(
            data?.Template?.ImageOnly ? '' : data?.Template?.DisplayName,
            translations,
          )}
        </p>
        {data.Template.Validator.AllRowsVisible ||
        !data.GridChildren[0]?.length ||
        !data.GridChildren.length ? (
          <></>
        ) : (
          <>
            <button
              type="button"
              onClick={onAddRowClick}
              disabled={rowsCounter >= data.Template.Validator.MaxRows}
            >
              +
            </button>
            <button
              type="button"
              onClick={onRemoveRowClick}
              disabled={rowsCounter === data.Template.Validator.MinRows}
            >
              -
            </button>
          </>
        )}
        {data.Template.HelpText && (
          <div className="heading-tooltip">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${data.Template.DisplayName}`}>
                  {translateTabContentText(
                    data.Template.HelpText,
                    translations,
                  )}
                </Tooltip>
              }
            >
              <img
                src={InfoIcon}
                alt="info img"
                className="tooltip-trigger"
                data-toggle="tooltip"
              />
            </OverlayTrigger>
          </div>
        )}
      </div>
      <hr />
      <div
        className="grid-fields scroll"
        style={{
          overflowX: minLengthFields ? 'scroll' : 'unset',
          minHeight:
            selectMenuOpen && minLengthFields && !showRowsAsColumns
              ? '300px'
              : 'auto',
          width: 1121,
          padding: '10px 0',
          whiteSpace: minLengthFields ? 'initial' : 'unset',
          verticalAlign: 'top',
          position: 'relative',
          visibility: lengthFields ? 'unset' : 'hidden',
          marginBottom: ' 10px',
        }}
      >
        <>
          <table className={showRowsAsColumns ? 'table-col' : ''}>
            <div className="fields-row">
              <tr className={showRowsAsColumns ? 'table-column' : ''}>
                {data.GridChildren[0]?.map(f => {
                  const visible = f.Template.InitiallyVisibleOnLoad;
                  const noDisplayConditions =
                    f.Template.DisplayConditions === undefined ||
                    f.Template.DisplayConditions === null ||
                    f.Template.DisplayConditions.length < 1;

                  const conditionToShow =
                    visible ||
                    (!visible && noDisplayConditions) ||
                    (!visible &&
                      !noDisplayConditions &&
                      checkAllConditionsFollow(f, newData));
                  const isShowField = showField(
                    f.Template.Archived,
                    f.Template.VisibleExternally,
                  );
                  return conditionToShow && isShowField ? (
                    <TableHeader
                      headerText={translateTabContentText(
                        f.Template.DisplayName,
                        translations,
                      )}
                      template={f.Template}
                      translations={translations}
                      showRowsAsColumns={showRowsAsColumns}
                    />
                  ) : (
                    <></>
                  );
                })}
              </tr>
            </div>

            <>
              {data.GridChildren &&
                data.GridChildren.slice(0, rowsCounter).map(
                  (fieldsArray, index) => {
                    return (
                      <div className="fields-row " key={`${data.Guid}${index}`}>
                        <div>
                          <tr
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            className={showRowsAsColumns ? 'table-column' : ''}
                          >
                            {fieldsArray.map((f, fieldIndex) => {
                              const ifCheckbox =
                                f.Template.FieldType.Name === 'Checkbox' &&
                                !f.Template.HasImage
                                  ? 'checkbox-fit'
                                  : '';
                              const visible = f.Template.InitiallyVisibleOnLoad;
                              const noDisplayConditions =
                                f.Template.DisplayConditions === undefined ||
                                f.Template.DisplayConditions === null ||
                                f.Template.DisplayConditions.length < 1;

                              const conditionToShow =
                                visible ||
                                (!visible && noDisplayConditions) ||
                                (!visible &&
                                  !noDisplayConditions &&
                                  checkAllConditionsFollow(f, newData));
                              const isShowField = showField(
                                f.Template.Archived,
                                f.Template.VisibleExternally,
                              );
                              let parentMirrorData = { name: '', options: [] };
                              if (
                                f.Template.FieldType.Name === 'Mirror' &&
                                f.Template.Parameters
                                  .FieldTemplateToMirror_ID !== 0
                              ) {
                                parentMirrorData = getMirroredFieldValue(
                                  f.Template.Parameters
                                    .FieldTemplateToMirror_ID,
                                  newData,
                                  index,
                                );
                              }

                              if (fieldsConfig[f.Template.FieldType.Name]) {
                                let Component;
                                if (
                                  f.Template.FieldType.Name === 'Text' &&
                                  f.Template.Parameters.MultiLine
                                ) {
                                  Component = fieldsConfig['Textarea'];
                                } else if (
                                  f.Template.FieldType.Name &&
                                  f.Template.FieldType.Discriminator ===
                                    'ImageFieldType'
                                ) {
                                  Component =
                                    fieldsConfig['ImageUploadComponent'];
                                } else {
                                  Component =
                                    fieldsConfig[f.Template.FieldType.Name];
                                }
                                if (f.Template.FieldType.Name === 'Selection') {
                                  return (
                                    <td
                                      className={
                                        !conditionToShow || !isShowField
                                          ? 'hide'
                                          : ''
                                      }
                                    >
                                      <div
                                        className={
                                          !conditionToShow || !isShowField
                                            ? 'hide'
                                            : ''
                                        }
                                      >
                                        {index === 0 && (
                                          <ImageInHeader
                                            template={f.Template}
                                            HasImage={f.Template.HasImage}
                                            imageID={f.Template.ID}
                                            showRowsAsColumns={
                                              showRowsAsColumns
                                            }
                                          />
                                        )}
                                        {fixedTextItemsArray.length > 0 &&
                                        fieldIndex === 0 ? (
                                          <div className="fit-fixed-text">
                                            <p className="fixed-text-item">
                                              {fixedTextItemsArray.length > 0 &&
                                              fieldIndex === 0
                                                ? fixedTextItemsArray[index]
                                                    ?.DisplayText
                                                : ''}
                                            </p>

                                            <SelectInput
                                              isGrid={true}
                                              data={data}
                                              setOpenMenu={setSelectMenuOpen}
                                              selectMenuOpen={selectMenuOpen}
                                              setChangedFieldsId={
                                                setChangedFieldsId
                                              }
                                              translations={translations}
                                              key={f.Guid}
                                              name={`${data.Guid}[${index}][${f.Template.Name}]`}
                                              imageFile={f.Template?.ImageFile}
                                              OriginalHeight={
                                                f.Template?.OriginalHeight
                                              }
                                              OriginalSize={
                                                f.Template?.OriginalSize
                                              }
                                              OriginalWidth={
                                                f.Template?.OriginalWidth
                                              }
                                              ImageHeight={
                                                f.Template?.ImageHeight
                                              }
                                              MaintainAspectRatio={
                                                f.Template?.MaintainAspectRatio
                                              }
                                              conditionToShow={conditionToShow}
                                              ImageWidth={
                                                f.Template?.ImageWidth
                                              }
                                              options={f.Template.Parameters.ValuesList?.Values.map(
                                                o => ({
                                                  value: o.ID,
                                                  label: o.Name,
                                                }),
                                              )}
                                              disabled={false}
                                              required={f.Template.Required}
                                            />
                                          </div>
                                        ) : (
                                          <SelectInput
                                            isGrid={true}
                                            data={data}
                                            setOpenMenu={setSelectMenuOpen}
                                            selectMenuOpen={selectMenuOpen}
                                            setChangedFieldsId={
                                              setChangedFieldsId
                                            }
                                            translations={translations}
                                            key={f.Guid}
                                            name={`${data.Guid}[${index}][${f.Template.Name}]`}
                                            imageFile={f.Template?.ImageFile}
                                            OriginalHeight={
                                              f.Template?.OriginalHeight
                                            }
                                            OriginalSize={
                                              f.Template?.OriginalSize
                                            }
                                            OriginalWidth={
                                              f.Template?.OriginalWidth
                                            }
                                            ImageHeight={
                                              f.Template?.ImageHeight
                                            }
                                            MaintainAspectRatio={
                                              f.Template?.MaintainAspectRatio
                                            }
                                            conditionToShow={conditionToShow}
                                            ImageWidth={f.Template?.ImageWidth}
                                            options={f.Template.Parameters.ValuesList?.Values.map(
                                              o => ({
                                                value: o.ID,
                                                label: o.Name,
                                              }),
                                            )}
                                            disabled={false}
                                            required={f.Template.Required}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  );
                                }
                                if (
                                  f.Template.FieldType.Name === 'MultiSelection'
                                ) {
                                  return (
                                    <td
                                      className={
                                        !conditionToShow || !isShowField
                                          ? 'hide'
                                          : ''
                                      }
                                    >
                                      <div
                                        className={
                                          !conditionToShow || !isShowField
                                            ? 'hide'
                                            : ''
                                        }
                                      >
                                        {index === 0 && (
                                          <ImageInHeader
                                            template={f.Template}
                                            HasImage={f.Template.HasImage}
                                            imageID={f.Template.ID}
                                            showRowsAsColumns={
                                              showRowsAsColumns
                                            }
                                          />
                                        )}
                                        {fixedTextItemsArray.length > 0 &&
                                        fieldIndex === 0 ? (
                                          <div className="fit-fixed-text">
                                            <p className="fixed-text-item">
                                              {fixedTextItemsArray.length > 0 &&
                                              fieldIndex === 0
                                                ? fixedTextItemsArray[index]
                                                    ?.DisplayText
                                                : ''}
                                            </p>
                                            <MultiSelect
                                              isGrid={true}
                                              data={data}
                                              setOpenMenu={setSelectMenuOpen}
                                              selectMenuOpen={selectMenuOpen}
                                              setChangedFieldsId={
                                                setChangedFieldsId
                                              }
                                              translations={translations}
                                              key={f.Guid}
                                              name={`${data.Guid}[${index}][${f.Template.Name}]`}
                                              imageFile={f.Template?.ImageFile}
                                              OriginalHeight={
                                                f.Template?.OriginalHeight
                                              }
                                              OriginalSize={
                                                f.Template?.OriginalSize
                                              }
                                              OriginalWidth={
                                                f.Template?.OriginalWidth
                                              }
                                              ImageHeight={
                                                f.Template?.ImageHeight
                                              }
                                              MaintainAspectRatio={
                                                f.Template?.MaintainAspectRatio
                                              }
                                              conditionToShow={conditionToShow}
                                              ImageWidth={
                                                f.Template?.ImageWidth
                                              }
                                              options={f.Template.Parameters.ValuesList?.Values.map(
                                                o => ({
                                                  value: o.ID,
                                                  label: o.Name,
                                                }),
                                              )}
                                              disabled={false}
                                              required={f.Template.Required}
                                            />
                                          </div>
                                        ) : (
                                          <MultiSelect
                                            isGrid={true}
                                            data={data}
                                            setOpenMenu={setSelectMenuOpen}
                                            selectMenuOpen={selectMenuOpen}
                                            setChangedFieldsId={
                                              setChangedFieldsId
                                            }
                                            translations={translations}
                                            key={f.Guid}
                                            name={`${data.Guid}[${index}][${f.Template.Name}]`}
                                            imageFile={f.Template?.ImageFile}
                                            OriginalHeight={
                                              f.Template?.OriginalHeight
                                            }
                                            OriginalSize={
                                              f.Template?.OriginalSize
                                            }
                                            OriginalWidth={
                                              f.Template?.OriginalWidth
                                            }
                                            ImageHeight={
                                              f.Template?.ImageHeight
                                            }
                                            MaintainAspectRatio={
                                              f.Template?.MaintainAspectRatio
                                            }
                                            conditionToShow={conditionToShow}
                                            ImageWidth={f.Template?.ImageWidth}
                                            options={f.Template.Parameters.ValuesList?.Values.map(
                                              o => ({
                                                value: o.ID,
                                                label: o.Name,
                                              }),
                                            )}
                                            disabled={false}
                                            required={f.Template.Required}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  );
                                }
                                return (
                                  <>
                                    <div className={ifCheckbox}>
                                      <td>
                                        <div
                                          className={
                                            !conditionToShow || !isShowField
                                              ? 'hide'
                                              : ''
                                          }
                                          onChange={handleChange}
                                        >
                                          {index === 0 && (
                                            <ImageInHeader
                                              template={f.Template}
                                              HasImage={f.Template.HasImage}
                                              imageID={f.Template.ID}
                                              showRowsAsColumns={
                                                showRowsAsColumns
                                              }
                                            />
                                          )}
                                          <div className={ifCheckbox}>
                                            {fixedTextItemsArray.length > 0 &&
                                            fieldIndex === 0 ? (
                                              <div className="fit-fixed-text">
                                                <p className="fixed-text-item">
                                                  {fixedTextItemsArray.length >
                                                    0 && fieldIndex === 0
                                                    ? fixedTextItemsArray[index]
                                                        ?.DisplayText
                                                    : ''}
                                                </p>
                                                <Component
                                                  key={f.Guid}
                                                  name={`${data.Guid}[${index}][${f.Template.Name}]`}
                                                  imageFile={
                                                    f.Template?.ImageFile
                                                  }
                                                  OriginalHeight={
                                                    f.Template?.OriginalHeight
                                                  }
                                                  gridColumnIndex={index}
                                                  data={data}
                                                  fieldID={f.ID}
                                                  OriginalSize={
                                                    f.Template?.OriginalSize
                                                  }
                                                  OriginalWidth={
                                                    f.Template?.OriginalWidth
                                                  }
                                                  parentMirrorData={
                                                    parentMirrorData
                                                  }
                                                  ImageHeight={
                                                    f.Template?.ImageHeight
                                                  }
                                                  ImageWidth={
                                                    f.Template?.ImageWidth
                                                  }
                                                  setChangedFieldsId={
                                                    setChangedFieldsId
                                                  }
                                                  fieldData={f}
                                                  isGrid={true}
                                                  newData={newData}
                                                  MaintainAspectRatio={
                                                    f.Template
                                                      ?.MaintainAspectRatio
                                                  }
                                                  template={f.Template}
                                                  toolTipText={translateTabContentText(
                                                    f.Template.HelpText,
                                                    translations,
                                                  )}
                                                  postfixText={
                                                    f.Template?.Parameters
                                                      ?.MeasureDetail
                                                      ?.PostfixText
                                                  }
                                                  oneField={f}
                                                  disabled={false}
                                                  required={f.Template.Required}
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                <Component
                                                  key={f.Guid}
                                                  name={`${data.Guid}[${index}][${f.Template.Name}]`}
                                                  imageFile={
                                                    f.Template?.ImageFile
                                                  }
                                                  OriginalHeight={
                                                    f.Template?.OriginalHeight
                                                  }
                                                  gridColumnIndex={index}
                                                  data={data}
                                                  fieldID={f.ID}
                                                  OriginalSize={
                                                    f.Template?.OriginalSize
                                                  }
                                                  OriginalWidth={
                                                    f.Template?.OriginalWidth
                                                  }
                                                  parentMirrorData={
                                                    parentMirrorData
                                                  }
                                                  ImageHeight={
                                                    f.Template?.ImageHeight
                                                  }
                                                  ImageWidth={
                                                    f.Template?.ImageWidth
                                                  }
                                                  setChangedFieldsId={
                                                    setChangedFieldsId
                                                  }
                                                  fieldData={f}
                                                  isGrid={true}
                                                  newData={newData}
                                                  MaintainAspectRatio={
                                                    f.Template
                                                      ?.MaintainAspectRatio
                                                  }
                                                  template={f.Template}
                                                  toolTipText={translateTabContentText(
                                                    f.Template.HelpText,
                                                    translations,
                                                  )}
                                                  postfixText={
                                                    f.Template?.Parameters
                                                      ?.MeasureDetail
                                                      ?.PostfixText
                                                  }
                                                  oneField={f}
                                                  disabled={false}
                                                  required={f.Template.Required}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </tr>
                        </div>
                      </div>
                    );
                  },
                )}
            </>
          </table>
        </>
      </div>
    </div>
  );
};
