import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from '../config/routes';
import { IAppState } from '../store';
import { AuthContext } from '../providers/AuthProvider';
import {readAuthDataFromSessionStorage} from "../utils/sessionstorage";

export const UserTypeRoute = routeProps => {
  const userRole = useSelector(
    (state: IAppState) => state.auth.profile?.UserRoleName,
  ) || readAuthDataFromSessionStorage()?.profile?.UserRoleName;
  const { isAuthenticated } = useContext(AuthContext);
  const { components, ...rest } = routeProps;
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated()) {
          return <Redirect to={ROUTES.home} />;
        }
        const ComponentToRender = components[userRole];
        if (userRole && ComponentToRender) {
          return <ComponentToRender {...props} />;
        }
        return <Redirect to={ROUTES.notFound} />;
      }}
    />
  );
};
