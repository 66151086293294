export const getMirroredFieldValue = (
  FieldTemplateToMirrorID,
  newData,
  gridColumnIndex,
) => {
  const result = findMirrorField(
    FieldTemplateToMirrorID,
    newData,
    gridColumnIndex,
  );
  return result;
};

const findMirrorField = (MirrorFieldTemplateID, newData, gridColumnIndex) => {
  let value = { name: '', options: [] };
  newData?.Pages?.forEach(page => {
    page?.Fields?.forEach(field => {
      if (field.Template.ID === MirrorFieldTemplateID) {
        value.name = field.Guid;
        if (
          field.Template.FieldType.Name === 'Selection' ||
          field.Template.FieldType.Name === 'MultiSelection'
        ) {
          value.options = field.Template?.Parameters?.ValuesList?.Values
            ? field.Template?.Parameters?.ValuesList?.Values
            : [];
        }
      }
      if (field.GridChildren.length) {
        field?.GridChildren?.forEach((gridChildFields, index) => {
          gridChildFields.forEach(gridChildField => {
            if (gridChildField.Template.ID === MirrorFieldTemplateID) {
              value.name = `${field.Guid}[${gridColumnIndex}][${gridChildField.Template.Name}]`;

              if (
                gridChildField.Template.FieldType.Name === 'Selection' ||
                field.Template.FieldType.Name === 'MultiSelection'
              ) {
                value.options = gridChildField.Template?.Parameters?.ValuesList
                  ?.Values
                  ? gridChildField.Template?.Parameters?.ValuesList?.Values
                  : [];
              }
            }
          });
        });
      }
    });
  });

  return value;
};
