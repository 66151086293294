import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Textarea } from '../../components/Form/Textarea';
import { SelectInput } from '../../components/Form/Select';
import './ContactPage.scss';
import ContactImg from '../../assets/images/contact-us-image.jpg';
import { SuccessModal } from 'components/SuccessModal';
import mailSent from '../../assets/images/ic-mail-sent.svg';
import API from 'api';
import {
  CONTACT_INITIAL_VALUES, CONTACT_INITIAL_VALUES_SEND_REQUEST,
  CONTACT_VALIDATION_SCHEME,
} from './config';
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { fillContactFormAction } from '../../store/assessment/actions';

export const ContactPage = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [changedFieldsIDForContactUs, setChangedFieldsIDForContactUs] = React.useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    contactFormData,
  } = useSelector((state: IAppState) => state.assessment);

  const handleFormSubmit = async (values, { resetForm }) => {
    if(values.Reason === "select from list"){ 
      values.Reason = "Other";
    }
    const res = await API(true).post('/api/User/Contact', values);
    if (res.statusText === 'OK') {
      setShowModal(true);
      resetForm();
    }
    setTimeout(()=>{
      history.goBack();
    }, 2000)
  };
  useEffect(()=>{
    return () =>{
      dispatch(fillContactFormAction(false))
    }
  },[])
  const options = [
    { value: 'Other', label: t('CONTACTS_Other') },
    { value: 'Technical Problem', label: t('CONTACTS_Technical Problem') },
    { value: 'Report an Issue', label: t('CONTACTS_Report an Issue') },
    { value: 'Question about reports or KPIs', label: t('CONTACTS_Question about reports or KPIs') },
    { value: 'How to Use Alltech Connect', label: t('CONTACTS_How to use Alltech Connect') },
  ];

  return (
    <div className="page-content contact-content">
      <div
        className="contact-us-header"
        style={{ backgroundImage: `url(${ContactImg})` }}
      >
        <div className="container-custom container">
          <h1 className="contact-title">{t('CONTACTS_Contact us')}</h1>
          <h2 className="contact-subtitle">
            {t('CONTACTS_Our team are waiting to hear from you')}
          </h2>
        </div>
      </div>
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <div className="contact-us-form">
              <div className="form-col">
                <h2 className="contact-us-heading">{t('CONTACTS_How can we help you?')}</h2>
                <Formik
                  initialValues={contactFormData ? CONTACT_INITIAL_VALUES_SEND_REQUEST : CONTACT_INITIAL_VALUES}
                  validationSchema={CONTACT_VALIDATION_SCHEME}
                  onSubmit={handleFormSubmit}
                  enableReinitialize
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit}>
                        <SelectInput
                          name="Reason"
                          options={options}
                          required={true}
                          conditionToShow={true}
                          placeholder={t('RM_select')}
                          labelText={t('CONTACTS_Select topic')}
                          setChangedFieldsId={setChangedFieldsIDForContactUs}
                        />
                        <Textarea
                          labelText={t('CONTACTS_Questions or comments')}
                          name="Message"
                          required
                        />
                        <button
                          type="submit"
                          className="btn action-primary contact-us-btn"
                          disabled={
                            !formik.values.Reason.toString().length ||  !formik.values.Message.toString().length
                          }
                        >
                          {t('CONTACTS_Submit')}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        imgUrl={mailSent}
        show={showModal}
        handleShowModal={() => setShowModal(false)}
        modalText={t('CONTACTS_Thank you for contacting us. An Alltech representative will be in touch shortly.')}
      />
    </div>
  );
};
