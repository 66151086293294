import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  AssessmentDataCollectionModel,
  DataCollectionFieldModel,
} from '../../pages/Assessment/types';
import { IAssessmentSingleState } from './types';
import Api from '../../api';
import { createAction } from '../types';
import { IAddGridRow, IRemoveGridRow } from './types';

export const addGridRowAction = (gridInfo): IAddGridRow => ({
  type: 'ADD_GRID_ROW',
  payload: gridInfo,
});

export const removeGridRowAction = (gridInfo): IRemoveGridRow => ({
  type: 'REMOVE_GRID_ROW',
  payload: gridInfo,
});

export const AssessmentActionTypes = {
  ASSESSMENT_SET_ASSESSMENT: 'ASSESSMENT_SET_ASSESSMENT',
  ASSESSMENT_GET_ERROR: 'ASSESSMENT_GET_ERROR',
  ASSESSMENT_LOADING: 'ASSESSMENT_LOADING',
  ASSESSMENT_UPDATE_ASSESSMENT: 'ASSESSMENT_UPDATE_ASSESSMENT',
};
export const assessmentActions = {
  setAssessment: (payload: AssessmentDataCollectionModel[]) =>
    createAction(AssessmentActionTypes.ASSESSMENT_SET_ASSESSMENT, payload),
  getAssessmentError: (payload: string) =>
    createAction(AssessmentActionTypes.ASSESSMENT_GET_ERROR, payload),
  fetchingAssessment: (payload: boolean) =>
    createAction(AssessmentActionTypes.ASSESSMENT_LOADING, payload),
  updateAssessment: (payload: any) =>
    createAction(AssessmentActionTypes.ASSESSMENT_UPDATE_ASSESSMENT, payload),
};

export type DispatchAction<T = void> = ThunkAction<
  Promise<T>,
  IAssessmentSingleState,
  void,
  Action
>;

export const getAssessment = (id: string): DispatchAction => {
  return async dispatch => {
    dispatch(assessmentActions.fetchingAssessment(true));
    try {
      let response = [];
      const responseOfMainAssessment: {
        data: AssessmentDataCollectionModel;
      } = await Api(false).get(`/api/Assessments/${id}`);
      response = [responseOfMainAssessment.data];
      if (responseOfMainAssessment.data.Package) {
        const assessmentsToFetch = responseOfMainAssessment.data.Package?.Package_Assessments.filter(
          a => a.AssessmentID.toString() !== id,
        );
        const anotherAssessments: Array<{
          data: AssessmentDataCollectionModel;
        }> = await Promise.all(
          assessmentsToFetch.map(ass =>
            Api(false).get(`/api/Assessments/${ass.AssessmentID}`),
          ),
        );
        response = [...response, ...anotherAssessments.map(r => r.data)];
      }

      response.forEach(r => {
        r.Pages.forEach(p => {
          if (p.Template.TemplatePageType === 0) {
            p.Fields.forEach(field => {
              if (field.Template.FieldType.Name === 'Grid') {
                field.GridChildren.sort(
                  (fa, nfa) => fa[0].GridRow - nfa[0].GridRow,
                );
                if (!field.Template.Validator.AllRowsVisible) {
                  if (
                    !field.GridChildren.some(fieldsArray =>
                      fieldsArray.some(f => f.Value !== null),
                    )
                  ) {
                    field.GridChildren.splice(
                      field.Template.Validator.MinRows,
                      field.GridChildren.length,
                    );
                  } else {
                    const fields = field.GridChildren.filter(fieldsArray => {
                      return fieldsArray.some(f => f.Value !== null);
                    });
                    field.GridChildren = fields;
                  }
                }
              }
            });
          }
        });
      });
      dispatch(assessmentActions.setAssessment(response));
    } catch (e) {
      dispatch(assessmentActions.getAssessmentError(e));
    } finally {
      dispatch(assessmentActions.fetchingAssessment(false));
    }
  };
};

export const postAssessment = (
  assessmentDetails: Array<DataCollectionFieldModel>,
  isAutosave: boolean
): DispatchAction => {
  return async dispatch => {
    if(!isAutosave) {
      dispatch(assessmentActions.fetchingAssessment(true));
    }
    try {
      const { statusText } = await Api(false).post(
        `/api/Assessments`,
        assessmentDetails,
      );
      if (statusText === 'OK') {
        dispatch(assessmentActions.updateAssessment(assessmentDetails));
      }
    } catch (e) {
      dispatch(assessmentActions.getAssessmentError(e));
    } finally {
      if(!isAutosave) {
        dispatch(assessmentActions.fetchingAssessment(false));
      }   
    }
  };
};

export const putAssessment = (assessmentsId: number): DispatchAction => {
  return async dispatch => {
    dispatch(assessmentActions.fetchingAssessment(true));
    try {
      const response = await Api(false).put(
        `/api/Assessments/Complete`,
        assessmentsId,
      );
      // if (statusText === 'OK') {
      //   console.log('assessment has completed');
      // }
    } catch (e) {
      dispatch(assessmentActions.getAssessmentError(e));
    } finally {
      dispatch(assessmentActions.fetchingAssessment(false));
    }
  };
};

export const getImage = async (imageID: number) => {
    try {
      const response = await Api(false).get(`/Image?id=${imageID}`);
      return response.data
      
    } catch (error) {
        console.log(error);
    }
  }
  export const getImageFieldID = async (imageID: number) => {
    try {
      const response = await Api(false).get(`/Image/FieldImage?imageFieldID=${imageID}`);
      return response?.data?.ImageData
      
    } catch (error) {
        console.log(error);
    }
  }


export const postImage = async (ImageFieldID, ImageData) => {
  const data = {
    ImageFieldID: ImageFieldID,
    ImageData: `${ImageData}`
  }

  try {
    const response = await Api(false).post(`/Image/Upload`, JSON.stringify(data));
    return response.data
    
  } catch (error) {
      console.log(error);
  }
}

export const getAssesmentReportsList = async (assessmentID: number) => {
  try {
    const response = await Api(false).get(`/api/Reports/GetReportsForAssessment?assessmentID=${assessmentID}`);
    return response.data
    
  } catch (error) {
      console.log(error);
  }
}

export const downloadReport = async (assessmentID: number, reportID: number) => {
  try {
    const response = await Api(false).get(
      `/api/Reports/RunReport?assessmentID=${assessmentID}&reportID=${reportID}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
