import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './DashboardScreen.scss';
import { Header } from '../../components/Layout/Header';
import { Footer } from '../../components/Layout/Footer';
import {NotFoundPage} from "../NotFoundPage";
import { ROUTES } from '../../config/routes';
import { dashboardRoutes, generateRoutes } from './config';

export const DashboardScreen = () => {
  return (
    <>
      <Header />
      <div className="page-wrapper">
        <Switch>
          {generateRoutes(dashboardRoutes)}
          <Route path={ROUTES.notFound} component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};
