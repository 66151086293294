import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  ISuppliersPendingAction,
  ISetSuppliersAction,
  SuppliersModel,
} from './types';
import { createGetParams } from '../../utils/getParams';
import { AxiosResponse } from 'axios';
import Api from '../../api';


export const getApiSuppliers: ActionCreator<
  ThunkAction<
    Promise<ISuppliersPendingAction | ISetSuppliersAction>,
    any,
    null,
    ISetSuppliersAction
  >
> = (filters = {}, filterObjectForReducer: any = {}) => {
  return async (dispatch: Dispatch) => {
    const suppliersPendingAction = {
      type: 'SUPPLIERS_PENDING',
      payload: true,
    };
    dispatch(suppliersPendingAction);

    let getParamsString: string = '';
    if (Object.entries(filters).length) {
      getParamsString = createGetParams(filters);
    }

    try {
      const response: AxiosResponse<SuppliersModel> = await Api(false).get(
        `/api/Supplier/Search${getParamsString}`,
      );
      if (response.status === 200) {
        const suppliersData: SuppliersModel = response.data;
        const setSuppliers: ISetSuppliersAction = {
          type: 'SET_SUPPLIERS',
          payload: { ...suppliersData, ...filters, ...filterObjectForReducer },
        };
        return dispatch(setSuppliers);
      }
    } catch (e) {
      console.log(e);
    } finally {
      const suppliersFinishAction: ISuppliersPendingAction = {
        type: 'SUPPLIERS_PENDING',
        payload: false,
      };
      dispatch(suppliersFinishAction);
    }
  };
};
