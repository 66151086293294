import React, { useEffect, useState, useRef } from 'react';
import './ImageUpload.scss';
import { getImageFieldID } from 'store/assessmentSingle/actions';
import uploadIcon from '../../assets/icons/icon-attachment.png';
import deleteIcon from '../../assets/icons/icon-trash.png';
import { postImage } from 'store/assessmentSingle/actions';
interface IProps {
  template?: any;
  fieldID?: number;
}

export const ImageUpload = ({ template, fieldID }: IProps) => {
  const [imageSrc, setImageSrc] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.toString();
        setImageSrc(base64String);
        await postImage(fieldID, base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = async () => {
    setImageSrc('');
    await postImage(fieldID, '');
  };

  const getImageSrc = async () => {
    try {
      const imageBase64 = await getImageFieldID(fieldID);
      setImageSrc(imageBase64);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImageSrc();
  }, []);

  return (
    <div className="image-upload-container">
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      {imageSrc && imageSrc.length > 0 ? (
        <div className="image-container">
          <img
            src={imageSrc}
            style={{ width: imageSrc ? '231px' : 'auto' }}
            className="imageUploaded"
            alt="Uploaded"
          />
          <button
            className="upload-button btn action-primary "
            onClick={handleDelete}
          >
            <img
              src={deleteIcon}
              alt="Delete"
              className="icon-attachment"
              width={40}
            />
          </button>
        </div>
      ) : (
        <button
          className="upload-button btn action-primary"
          onClick={handleClick}
        >
          <img
            src={uploadIcon}
            alt="Upload"
            className="icon-attachment"
            width={100}
          />
        </button>
      )}
    </div>
  );
};
