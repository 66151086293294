import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
// @ts-ignore
import ArrowLeft from 'assets/icons/ic-arrow-left.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { SelectComponent } from '../../components/SelectComponent';

import { CheckBox } from 'components/CheckBox';
import { createTemplate, getCategories } from './utils';
import { SuccessModal } from 'components/SuccessModal';
// @ts-ignore
import settingsSuccess from 'assets/images/ic-state-success.svg';
import TableLoader from 'components/TableLoader';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '../../assets/icons/ic-info.svg';
import { useTranslation } from 'react-i18next';
import attention from '../../assets/images/00-icons-toast-ic-attantion.svg';
import { lang } from 'moment';

export const CreateAssessmentPage = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setErrorShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function callCategories() {
      const response = await getCategories();
      console.log();

      setCategories(response);
    }

    callCategories();
  }, []);
  console.log('categories', categories);

  const onSelectCategory = category => {
    setSelectedCategory(category.value);
    const templates = categories.find(item => item.ID === category.value);
    setSelectedTemplates(templates.Templates);
    setSelectedTemplate('');
    setDescription('');
  };

  const onSelectedTemplate = (id, desc, name) => {
    setTemplateName(name);
    setDescription(desc);
    if (selectedTemplate == id) {
      setSelectedTemplate('');
    } else setSelectedTemplate(id);
  };
  const callCreateTemplate = async id => {
    setIsLoading(true);
    const data = await createTemplate(id);
    if (data?.Errors?.AssessmentCreationError) {
      setErrorMsg(data?.Errors?.AssessmentCreationError);
      setErrorShowModal(true);
    } else setShowModal(true);
    setIsLoading(false);
  };

  const language = localStorage.getItem('lang');

  const transformTextToActualLang = category => {
    const pt = language === 'po' ? 'pt' : language;
    const translate = category?.Translations?.find(
      lang => lang.Language === pt,
    );
    return t(category?.Name);
  };

  return (
    <div className="container-custom container create-assessment-wrapper">
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <div className="assessment-header">
            <h2 className="assessment-name">
              <NavLink
                className="assessment-back"
                to={`/dashboard/assessments`}
              >
                <img src={ArrowLeft} alt="arrow image" />
              </NavLink>
              {t('New Assessment')}
            </h2>
            <div className="assessment-buttons">
              <button
                className="action-primary btn table-action-btn"
                onClick={() => callCreateTemplate(selectedTemplate)}
                disabled={!selectedTemplate}
              >
                {t('Create')}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-item">
              <div className="select-group-wrapper">
                <SelectComponent
                  isMulti={false}
                  options={categories?.map(category => ({
                    value: category?.ID,
                    label: transformTextToActualLang(category),
                  }))}
                  label={t('RM_spec')}
                  onChange={onSelectCategory}
                  value={selectedCategory}
                />
              </div>
            </div>
            <div className="col-4 col-item">
              {selectedTemplates.length > 0 && (
                <div
                  className={
                    'custom-table processor-suppliers-table is-processor'
                  }
                >
                  <div className="table-header">
                    <div className="checkboxes-wrapper"></div>
                    <div className="assessment-name-wrapper">
                      <span>{t('Name of assessment')}</span>
                      <div className="tooltip-wrapper">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-`}>
                              {t('Please select assessment to see description')}
                            </Tooltip>
                          }
                        >
                          <img
                            src={InfoIcon}
                            alt="info img"
                            className="tooltip-trigger"
                            data-toggle="tooltip"
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="table-body">
                    {selectedTemplates.map(template => (
                      <div className="table-item" key={template.ID}>
                        <div className="checkboxes-wrapper row-checkbox">
                          <CheckBox
                            key={template.ID}
                            isSelected={selectedTemplate === template?.ID}
                            label={''}
                            onChange={() =>
                              onSelectedTemplate(
                                template?.ID,
                                template?.Description,
                                template?.Name,
                              )
                            }
                            name={template?.ID}
                          />
                        </div>
                        <div className="template-name-wrapper">
                          <span style={{ paddingLeft: '10px' }}>
                            {template?.Name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {selectedTemplate && (
              <div className="col-4 info-col">
                <div className="description-title">{templateName}</div>
                <div className="description-text">
                  <p>{description}</p>
                </div>
              </div>
            )}
          </div>
          <SuccessModal
            imgUrl={settingsSuccess}
            show={showModal}
            handleShowModal={setShowModal}
            modalText={t('Assessment has been created successfully')}
            onClose={() => history.goBack()}
          />
          <SuccessModal
            imgUrl={attention}
            show={showErrorModal}
            handleShowModal={setErrorShowModal}
            modalText={t(errorMsg)}
            onClose={() => history.goBack()}
          />
        </>
      )}
    </div>
  );
};
