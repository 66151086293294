import React from 'react';
import './Notification.scss';
import CrossSvg from 'assets/icons/ic-choice-decline.svg';
import DocumentIcon from 'assets/icons/ic-document.svg';
import { Link } from 'react-router-dom';

interface IProps {
  message: string;
  onClose: () => void;
  to: string;
}

export const Notification = ({ message, onClose, to }: IProps) => {
  return (
    <div className="homepage-toast">
      <div
        className="toast show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="2000"
      >
        <div className="toast-body">
          <div className="toast-inner">
            <div className="toast-icon-wrapper">
              <div className="icon-circle green-circle">
                <img src={DocumentIcon} alt="document icon" />
              </div>
            </div>
            <div className="toast-content">
              <Link to={to}>
                <p className="content-text">{message}</p>
              </Link>
            </div>
            <div className="toast-btn-wrapper">
              <button
                type="button"
                className="toast-btn"
                data-dismiss="toast"
                aria-label="Close"
                onClick={onClose}
              >
                <img src={CrossSvg} alt="cross icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
