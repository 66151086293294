import Api from 'api';
import { ActionCreator, Dispatch } from 'redux';
import {
  ISetAssessmentAction,
  IAssessmentPendingAction,
  ISetAssessmentCurrentPage,
  ISetAssessmentItemsPerPage,
  ISortAssessmentsByStartDate,
  ISortAssessmentsByAlphabet,
  ISortAssessmentsByEndDate, IFillContactForm,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse } from 'axios';
import { IDefaultServerResponse } from '../types';

export const setAssessmentCurrentPageAction = (
  pageNum: number,
): ISetAssessmentCurrentPage => ({
  type: 'SET_ASSESSMENT_CURRENT_PAGE',
  payload: pageNum,
});

export const setAssessmentItemsPerPageAction = (
  items: number,
): ISetAssessmentItemsPerPage => ({
  type: 'SET_ASSESSMENT_ITEMS_PER_PAGE',
  payload: items,
});

export const sortAssessmentByAlphabetAction = (
  sortAsc: boolean,
): ISortAssessmentsByAlphabet => ({
  type: 'SORT_ASSESSMENTS_BY_ALPHABET',
  payload: sortAsc,
});

export const sortAssessmentByEndaDateAction = (
    sortAsc: boolean,
): ISortAssessmentsByEndDate => ({
  type: 'SORT_ASSESSMENTS_BY_END_DATE',
  payload: sortAsc,
});

export const fillContactFormAction = (
  flag: boolean,
): IFillContactForm => ({
  type: 'FILL_CONTACT_FORM',
  payload: flag,
});

export const sortAssessmentByStartDateAction = (
    sortAsc: boolean,
): ISortAssessmentsByStartDate => ({
  type: 'SORT_ASSESSMENTS_BY_START_DATE',
  payload: sortAsc,
});

export const getApiAssessment: ActionCreator<
  ThunkAction<
    Promise<ISetAssessmentAction | IAssessmentPendingAction>, // The type of the last action to be dispatched - will always be promise<T> for async actions
    any, // The type for the data within the last action
    null, // The type of the parameter for the nested function
    ISetAssessmentAction // The type of the last action to be dispatched
  >
> = () => {
  return async (dispatch: Dispatch) => {
    const AssessmentPendingAction: IAssessmentPendingAction = {
      type: 'ASSESSMENT_PENDING',
      payload: true,
    };
    dispatch(AssessmentPendingAction);

    try {
      const response: AxiosResponse<IDefaultServerResponse> = await Api(
        false,
      ).get('/api/Assessments');

      if (response.status === 200) {
        const assessmentItems = response.data || [];
        const setAssessments: ISetAssessmentAction = {
          type: 'SET_ASSESSMENT',
          assessmentItems,
        };
        return dispatch(setAssessments);
      }
    } catch (e) {
      console.log(e);
    } finally {
      const AssessmentFinishAction: IAssessmentPendingAction = {
        type: 'ASSESSMENT_PENDING',
        payload: false,
      };
      dispatch(AssessmentFinishAction);
    }
  };
};
