import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import './RetailerFarmerKpi.scss';
import { ROUTES } from '../../config/routes';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  getPerformanceIndicatorByValue,
  PERFORMANCE_INDICATORS,
} from '../../components/common/Kpi/FarmerKpiGeneralTab/config';
import { GraphDataModel } from '../../components/common/Kpi/FarmerKpiGeneralTab/types';
import { IRetailerFarmerData } from '../../store/retailerKpi/types';
import { labelsForRetailerGraph } from '../../components/common/Kpi/RetailerKpiGeneralTab';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import InfoSvg from '../../assets/icons/ic-info.svg';
import ArrowLeft from '../../assets/icons/ic-arrow-left.svg';

export const RetailerFarmerKpi = () => {
  const { state } = useLocation();
  const history = useHistory();
  if (!state) {
    history.push(ROUTES.kpi);
  }

  const onBack = farmerInfo => {
    history.push(ROUTES.kpi, {drillDown: true});
  };

  const dataToDraw = useMemo(() => {
    let dataToShow: Array<GraphDataModel> = [];
    for (const [key, value] of Object.entries(
      (state as IRetailerFarmerData).AssessmentParts[0],
    )) {
      if (labelsForRetailerGraph[key]) {
        dataToShow = [
          ...dataToShow,
          {
            name: `${labelsForRetailerGraph[key]}`,
            value: value,
          },
        ];
      }
    }
    dataToShow = [
      ...dataToShow,
      {
        name: (state as IRetailerFarmerData)?.AssessmentParts?.[0]?.Name,
        value: (state as IRetailerFarmerData)?.AssessmentParts?.[0]?.Value,
      },
    ];

    return dataToShow;
  }, [state]);

  return (
    <div className="container-custom container retailer-farmer-container">
      <div className="action-row-btn retailer-farmer-actions-row">
        <h2 className="back-btn-wrapper">
          <button
            className="back-btn"
            onClick={onBack}
          >
            <img src={ArrowLeft} alt="arrow image" />
          </button>
          Back to results page
        </h2>
      </div>
      <div className="kpi-row">
        <div className="kpi-item">
          <div className="comparison-item performance-item">
            <span
              className="comparison-data"
              // style={{
              //   color:
              //     state &&
              //     getPerformanceIndicatorByValue(
              //       (state as IRetailerFarmerData).AssessmentParts[0].Value,
              //     ).color,
              // }}
            >
              —
            </span>
            <span className="comparison-subtitle">Your performance</span>
          </div>
        </div>
        <div className="kpi-item">
          <div className="comparison-item">
            <span className="comparison-title">Current Assessment</span>
            <span className="comparison-data">
              {state && (state as IRetailerFarmerData).AssessmentParts[0].Value ?
                `${(state as IRetailerFarmerData).AssessmentParts[0].Value?.toFixed(
                  2,
                )}`: '—'}
            </span>
            {/*<span className="comparison-subtitle">*/}
            {/*  CO2/kg FPCM*/}
            {/*  <OverlayTrigger*/}
            {/*    placement="right"*/}
            {/*    overlay={*/}
            {/*      <Popover id="Current Assessment">*/}
            {/*        <Popover.Title as="span" className="tooltip-heading">*/}
            {/*          What does g CO2/kg FPCM mean?*/}
            {/*        </Popover.Title>*/}
            {/*        <Popover.Content className="tooltip-text">*/}
            {/*          Grams of carbon dioxide equivalent per kg of fat and*/}
            {/*          protein corrected milk produced. The sum of all emissions*/}
            {/*          generated on farm in 365 days, divided by the total volume*/}
            {/*          of fat and protein corrected milk produced.*/}
            {/*        </Popover.Content>*/}
            {/*      </Popover>*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <img src={InfoSvg} alt="info img" className="tooltip-trigger" />*/}
            {/*  </OverlayTrigger>*/}
            {/*</span>*/}
          </div>
        </div>
        <div className="kpi-item">
          <div className="comparison-item">
            <span className="comparison-title">Alltech Average</span>
            <span className="comparison-data">
              {state && (state as IRetailerFarmerData).AssessmentParts[0].AlltechAverage ?
                `${(state as IRetailerFarmerData).AssessmentParts[0].AlltechAverage?.toFixed(
                  2,
                )}`: '—'}
              {/*{state && (*/}
              {/*  <span*/}
              {/*    className="inner-data"*/}
              {/*    style={{*/}
              {/*      color:*/}
              {/*        (state as IRetailerFarmerData).AssessmentParts[0]?.Value >*/}
              {/*        (state as IRetailerFarmerData).AssessmentParts[0]*/}
              {/*          ?.AlltechAverage*/}
              {/*          ? '#ff333b'*/}
              {/*          : '#37a041',*/}
              {/*      borderColor:*/}
              {/*        (state as IRetailerFarmerData).AssessmentParts[0]?.Value >*/}
              {/*        (state as IRetailerFarmerData).AssessmentParts[0]*/}
              {/*          ?.AlltechAverage*/}
              {/*          ? '#ff333b'*/}
              {/*          : '#37a041',*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {state &&*/}
              {/*    (state as IRetailerFarmerData).AssessmentParts[0]?.Value >*/}
              {/*      (state as IRetailerFarmerData).AssessmentParts[0]*/}
              {/*        ?.AlltechAverage*/}
              {/*      ? `+${*/}
              {/*          (state as IRetailerFarmerData).AssessmentParts[0]*/}
              {/*            ?.Value -*/}
              {/*          (state as IRetailerFarmerData).AssessmentParts[0]*/}
              {/*            ?.AlltechAverage*/}
              {/*        }g`*/}
              {/*      : `-${*/}
              {/*          (state as IRetailerFarmerData).AssessmentParts[0]*/}
              {/*            ?.AlltechAverage -*/}
              {/*          (state as IRetailerFarmerData).AssessmentParts[0]?.Value*/}
              {/*        }g`}*/}
              {/*  </span>*/}
              {/*)}*/}
            </span>
            {/*<span className="comparison-subtitle">*/}
            {/*  CO2/kg FPCM*/}
            {/*  <OverlayTrigger*/}
            {/*    placement="right"*/}
            {/*    overlay={*/}
            {/*      <Popover id="Alltech Average">*/}
            {/*        <Popover.Title as="span" className="tooltip-heading">*/}
            {/*          What does g CO2/kg FPCM mean?*/}
            {/*        </Popover.Title>*/}
            {/*        <Popover.Content className="tooltip-text">*/}
            {/*          Grams of carbon dioxide equivalent per kg of fat and*/}
            {/*          protein corrected milk produced. The sum of all emissions*/}
            {/*          generated on farm in 365 days, divided by the total volume*/}
            {/*          of fat and protein corrected milk produced.*/}
            {/*        </Popover.Content>*/}
            {/*      </Popover>*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <img src={InfoSvg} alt="info img" className="tooltip-trigger" />*/}
            {/*  </OverlayTrigger>*/}
            {/*</span>*/}
          </div>
        </div>
        <div className="kpi-item">
          <div className="comparison-item">
            <span className="comparison-title">Alltech Top 10%</span>
            <span className="comparison-data">
              {state && (state as IRetailerFarmerData)?.AssessmentParts[0]?.TopTenPercent ?
                `${(state as IRetailerFarmerData)?.AssessmentParts[0]?.TopTenPercent?.toFixed(
                  2,
                )}`: '—'}
            </span>
            {/*<span className="comparison-subtitle">*/}
            {/*  CO2/kg FPCM*/}
            {/*  <OverlayTrigger*/}
            {/*    placement="right"*/}
            {/*    overlay={*/}
            {/*      <Popover id="Alltech Top 10%">*/}
            {/*        <Popover.Title as="span" className="tooltip-heading">*/}
            {/*          What does g CO2/kg FPCM mean?*/}
            {/*        </Popover.Title>*/}
            {/*        <Popover.Content className="tooltip-text">*/}
            {/*          Grams of carbon dioxide equivalent per kg of fat and*/}
            {/*          protein corrected milk produced. The sum of all emissions*/}
            {/*          generated on farm in 365 days, divided by the total volume*/}
            {/*          of fat and protein corrected milk produced.*/}
            {/*        </Popover.Content>*/}
            {/*      </Popover>*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <img src={InfoSvg} alt="info img" className="tooltip-trigger" />*/}
            {/*  </OverlayTrigger>*/}
            {/*</span>*/}
          </div>
        </div>
      </div>
      <div className="chart-wrapper">
        <ResponsiveContainer width="100%" height={dataToDraw.length * 57}>
          <BarChart
            layout="vertical"
            data={dataToDraw}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="500 1" horizontal={false} />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" scale="band" width={90} />
            <Tooltip />
            <Bar dataKey="value" barSize={30} radius={[0, 4, 4, 0]}>
              {dataToDraw.map(d => (
                <Cell
                  fill={'#37a041'}
                  key={d.name}
                />
              ))}
              <LabelList
                dataKey="value"
                position="right"
                width={100}
                style={{ fill: '#000' }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {/*<div className="chart-legend traffic-light-legend">*/}
        {/*  <ul className="legend-list">*/}
        {/*    <li className="list-item">*/}
        {/*      <span*/}
        {/*        style={{*/}
        {/*          backgroundColor: PERFORMANCE_INDICATORS.good.color,*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <span className="list-item-text">{'<900'}</span>*/}
        {/*      gCO2e*/}
        {/*    </li>*/}
        {/*    <li className="list-item">*/}
        {/*      <span*/}
        {/*        style={{*/}
        {/*          backgroundColor: PERFORMANCE_INDICATORS.satisfactory.color,*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <span className="list-item-text">{'901-1200'}</span>*/}
        {/*      gCO2e*/}
        {/*    </li>*/}
        {/*    <li className="list-item">*/}
        {/*      <span*/}
        {/*        style={{*/}
        {/*          backgroundColor: PERFORMANCE_INDICATORS.unsatisfactory.color,*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <span className="list-item-text">{'> 1201'}</span>*/}
        {/*      gCO2e*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*  <OverlayTrigger*/}
        {/*    placement="top"*/}
        {/*    overlay={*/}
        {/*      <Popover id="Performance indicator">*/}
        {/*        <Popover.Content className="tooltip-text">*/}
        {/*          Grams of carbon dioxide equivalent per kg of fat and protein*/}
        {/*          corrected milk produced. The sum of all emissions generated on*/}
        {/*          farm in 365 days, divided by the total volume of fat and*/}
        {/*          protein corrected milk produced.*/}
        {/*        </Popover.Content>*/}
        {/*      </Popover>*/}
        {/*    }*/}
        {/*  >*/}
        {/*    <img src={InfoSvg} alt="info img" className="tooltip-trigger" />*/}
        {/*  </OverlayTrigger>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
