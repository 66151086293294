import React from 'react';

interface IProps {
  isSelected: boolean;
  label: string;
  onChange: () => void;
  name: string;
}

const CheckBox = ({ isSelected, onChange, label, name }: IProps) => {
  return (
    <div className="form-check">
      <input
        name={name}
        type="checkbox"
        className="form-check-input"
        checked={isSelected}
        onChange={onChange}
        id={name}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export { CheckBox };
