import Api from '../../api';

export const downloadUrl = (fileURL: string, fileName?: string) => {
  const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName || filename;
  link.rel = 'noopener noreferrer';
  link.target = '_blank';
  link.click();
  URL.revokeObjectURL(fileURL);
};

export const getPermissions = async (id) => {
  try {
    const response = await Api(false).get(
      `/api/Upload/RequestPermission?assessmentID=${id}`,
    );
    if (response.status === 200) {
      const categories = response.data;

      return categories;
    }
  } catch (e) {
    console.log(e);
  }
}
