import React from 'react';
import "./NotFoundPage.scss";
import img404 from "../../assets/images/404-img.png";
import text404 from "../../assets/images/404-text.png";
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
  return (
    <div className="page-content">
        <div className="container-custom container">
            <div className="row">
                <div className="col-12">
                    <div className="page-404">
                        <img src={img404} alt="error img" className="main-img" />
                        <img src={text404} alt="text error img" className="text-img" />
                        <Link to="/dashboard/home" className="btn action-primary return-btn">Go to Home Page</Link>
                    </div>    
                </div>
            </div>
        </div>
    </div>
  );
};