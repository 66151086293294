import { DiscriminatorEnum } from './types';
import * as yup from 'yup';

const validations = {
  [DiscriminatorEnum.TextValidator]: yup.string(),
  [DiscriminatorEnum.BooleanValidator]: yup.boolean(),
  [DiscriminatorEnum.CalculatedFieldValidator]: yup.string(),
  [DiscriminatorEnum.DateTimeValidator]: yup.string(),
  [DiscriminatorEnum.NumericValidator]: yup.number(),
  [DiscriminatorEnum.SelectionFieldValidator]: yup.string(),
  [DiscriminatorEnum.GridFieldValidator]: yup.string(),
  [DiscriminatorEnum.MirrorValidator] : yup.string(),
  [DiscriminatorEnum.StaticMediaValidator] : yup.string(),
  [DiscriminatorEnum.MultiSelectionFieldValidator] : yup.array()
};

const configureValidation = (
  validationType: keyof typeof DiscriminatorEnum,
  isRequired: boolean = false,
) => {
  return isRequired ? validations[validationType].required('this field is required') : validations[validationType];
};

const DISABLED_ASSESSMENT_STATUS_FLAG = 2;

export { configureValidation, DISABLED_ASSESSMENT_STATUS_FLAG };
