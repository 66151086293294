import { Action } from 'redux';

export interface INotification {
  Type: { ID: number; Name: string };
  Priority: { ID: number; Name: string; Level: number };
  Created: string;
  DateRead: string | null;
  ID: number;
  Message: string;
}

export interface INotificationsState {
  notifications: Array<INotification>;
  isLoading: boolean;
  General: { closed: boolean };
  Assessment: { closed: boolean };
  KPI: { closed: boolean };
  Document: { closed: boolean };
  error: string;
}

export const NotificationsTypes = {
  general: 'General',
  assessments: 'Assessment',
  kpi: 'KPI',
  documents: 'Document',
};

export interface INotificationsPending extends Action<'NOTIFICATIONS_PENDING'> {
  type: 'NOTIFICATIONS_PENDING';
  payload: boolean;
}

export interface INotificationsSet extends Action<'NOTIFICATIONS_SET'> {
  type: 'NOTIFICATIONS_SET';
  payload: Array<INotification>;
}

export interface INotificationClose extends Action<'NOTIFICATION_CLOSE'> {
  type: 'NOTIFICATION_CLOSE';
  payload: string;
}

export interface INotificationsRead extends Action<'NOTIFICATIONS_READ'> {
  type: 'NOTIFICATIONS_READ';
  payload: number[];
}

export interface INotificationsSetError
  extends Action<'NOTIFICATIONS_SET_ERROR'> {
  type: 'NOTIFICATIONS_SET_ERROR';
  payload: string;
}

export type INotificationsActions =
  | INotificationsPending
  | INotificationsSet
  | INotificationClose
  | INotificationsRead
  | INotificationsSetError;
