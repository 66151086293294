import React from 'react';
const IconMark = ({color}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.824 3.52a.577.577 0 0 0-.848 0l-7.189 7.613-2.763-2.927a.577.577 0 0 0-.848 0 .662.662 0 0 0 0 .899l3.187 3.376a.577.577 0 0 0 .849 0l7.612-8.063a.662.662 0 0 0 0-.899z"
            fill={color} fill-rule="evenodd"/>
    </svg>

  )
}
export default IconMark;
