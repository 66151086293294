import React, { FC, useEffect, useState } from 'react';
import '../SettingsPage.scss';
import { Formik } from 'formik';
import { SuccessModal } from 'components/SuccessModal';
import settingsSuccess from 'assets/images/ic-state-success.svg';
import Api from 'api';
import { SettingsModel } from '../types';
import {
  SETTINGS_INITIAL_VALUES,
  SETTINGS_VALIDATION_SCHEME,
  transfromDataWithoutNull,
  getClearObjectForBe,
} from '../config';
import { TextInput } from 'components/Form/TextInput';
import countriesList from 'globalConstants/countries.json';
import { AxiosResponse } from 'axios';
import { useTranslation } from "react-i18next";

export const ProcessorSettings: FC = () => {
  const [isEdit, setisEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState<SettingsModel>(
    SETTINGS_INITIAL_VALUES,
  );

  const editHandler = resetFormCallback => {
    resetFormCallback();
    setisEdit(!isEdit);
  };

  useEffect(() => {
    Api(true)
      .get('/api/User')
      .then((response: AxiosResponse<SettingsModel>) => {
        const values = transfromDataWithoutNull(response.data);
        setInitialValues(values);
      });
  }, []);

  const handleSubmit = async values => {
    const clearValues = getClearObjectForBe(values)
    const res = await Api(true).post('/api/User/UserInfo', clearValues);
    if (res.statusText === 'OK') {
      setShowModal(true);
      setisEdit(false);
    }
  };

  return (
    <div className="page-content">
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <div className="settings-wrapper">
              <h2 className="settings-title">
                {t('SETTINGS_Welcome X')} {initialValues.firstName} {initialValues.lastName}
              </h2>
              <h3 className="settings-subtitle">
                {t('SETTINGS_Your Alltech ID:')} {initialValues.alltechID}
              </h3>
              <div className="settings-fields">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={SETTINGS_VALIDATION_SCHEME}
                  enableReinitialize
                >
                  {formik => (
                    <form onSubmit={formik.handleSubmit}>
                      <div className="fields-row">
                        <div className="form-section">
                          <p className="form-section__title">
                            User Information
                          </p>
                          <TextInput
                            name="farmName"
                            labelText={t('SETTINGS_Company name')}
                            disabled={true}
                          />
                          <TextInput
                            name="email"
                            labelText={t('SETTINGS_Email')}
                            disabled={!isEdit}
                          />
                          <TextInput
                            name="telephoneOne"
                            labelText={t('SETTINGS_Telephone 1')}
                            disabled={!isEdit}
                          />
                          <TextInput
                            name="telephoneTwo"
                            labelText={t('SETTINGS_Telephone 2')}
                            disabled={!isEdit}
                          />
                        </div>
                        <div className="form-section">
                          <p className="form-section__title">{t('SETTINGS_Address')}</p>
                          <div className="col-6">
                            <div className="row">
                              <TextInput
                                name="farmAddress1"
                                labelText={t('SETTINGS_Address')}
                                fullWidth
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="farmAddress2"
                                fullWidth
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="farmTown"
                                labelText={t('SETTINGS_Town/City')}
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="farmDistrict"
                                labelText={t('SETTINGS_Region')}
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="farmPostalCode"
                                labelText={t('SETTINGS_Postcode/Zip code')}
                                disabled={!isEdit}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <TextInput
                                name="mailAddress1"
                                labelText={t('SETTINGS_Mailing address')}
                                fullWidth
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="mailAddress2"
                                fullWidth
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="mailTown"
                                labelText={t('SETTINGS_Town')}
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="mailDistrict"
                                labelText={t('SETTINGS_Region')}
                                disabled={!isEdit}
                              />
                              <TextInput
                                name="mailPostalCode"
                                labelText={t('SETTINGS_Postcode/Zip code')}
                                disabled={!isEdit}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-section">
                          <p className="form-section__title">
                            {t('SETTINGS_Additional information')}
                          </p>
                          <TextInput
                            name="registrationDate"
                            labelText={t('SETTINGS_Registered')}
                            disabled={true}
                            isDate
                          />
                          <div className="form-group">
                            <p className="info-label">{t('SETTINGS_Country')}</p>
                            <p className="info-text">
                              {
                                countriesList.find(
                                  item =>
                                    item.code === formik.values.countryCode,
                                )?.name
                              }
                            </p>
                          </div>
                        </div>
                        <div className="buttons-wrapper">
                          {!isEdit && (
                            <button
                              className="btn action-primary"
                              onClick={() => setisEdit(true)}
                            >
                              {t('SETTINGS_Edit information')}
                            </button>
                          )}
                          {isEdit && (
                            <>
                              <button
                                className="btn action-primary"
                                type="submit"
                              >
                                {t('SETTINGS_Send change to admin')}
                              </button>
                              <button
                                className="btn action-secondary cancel-btn"
                                onClick={editHandler.bind(
                                  null,
                                  formik.resetForm,
                                )}
                              >
                                {t('SETTINGS_Cancel')}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        imgUrl={settingsSuccess}
        show={showModal}
        handleShowModal={setShowModal}
        modalText={t('SETTINGS_We have sent your request to change information to administration')}
      />
    </div>
  );
};
