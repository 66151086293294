import Api from '../../../api';

export const getReportsInfo = async () => {
  try {
    const response = await Api(false).get(
      `https://eco2userinfo.azurewebsites.net/api/User/UserReports`,
    );
    if (response.status === 200) {
      const reports = response.data;

      return reports;
    }
  } catch (e) {
    console.log(e);
  }
}

export const getCertsInfo = async () => {
  try {
    const response = await Api(false).get(
      `https://eco2userinfo.azurewebsites.net/api/Reports/Certificates`,
    );
    if (response.status === 200) {
      const reports = response.data;

      return reports;
    }
  } catch (e) {
    console.log(e);
  }
}
