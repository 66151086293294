import { IAllTechProfile } from './types';
import { createAction } from '../types';

export const AUTH_TYPES = {
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
};

export const authActions = {
  authorizeUserAction: (user: IAllTechProfile) =>
    createAction(AUTH_TYPES.LOGIN_USER, user),
  authLanguageAction: (language: Boolean) =>
    createAction(AUTH_TYPES.SWITCH_LANGUAGE, language),
};
