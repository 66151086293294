import { createStore, combineReducers, applyMiddleware, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { assessmentReducer } from './assessment/reducer';
import { assessmentSingleReducer } from './assessmentSingle/reducer';
import { documentsReducer } from './documents/reducer';
import { authReducer } from './auth/reducer';
import { suppliersReducer } from './suppliers/reducer';
import { processorRetailerReducer } from './processorRetailerDocuments/reducer';
import { notificationsReducer } from './notifications/reducer';
import { farmerKpiReducer } from './farmerKpi/reducer';
import { retailerKpiReducer } from './retailerKpi/reducer';
import { IAssessmentState } from './assessment/types';
import { IAssessmentSingleState } from './assessmentSingle/types';
import { IDocumentsState } from './documents/types';
import { IAuthState } from './auth/types';
import { ISuppliersState } from './suppliers/types';
import { IProcessorRetailerDocumentsState } from './processorRetailerDocuments/types';
import { INotificationsState } from './notifications/types';
import { FarmerKpiState } from './farmerKpi/types';
import { RetailerKpiState } from './retailerKpi/types';
import { isProdEnv } from '../utils/env';

const rootReducer = combineReducers({
  singleAssessment: assessmentSingleReducer,
  assessment: assessmentReducer,
  documents: documentsReducer,
  auth: authReducer,
  suppliers: suppliersReducer,
  processorRetailerDocs: processorRetailerReducer,
  notifications: notificationsReducer,
  farmerKpi: farmerKpiReducer,
  retailerKpi: retailerKpiReducer,
});

export interface IAppState {
  readonly singleAssessment: IAssessmentSingleState;
  readonly assessment: IAssessmentState;
  readonly documents: IDocumentsState;
  readonly auth: IAuthState;
  readonly suppliers: ISuppliersState;
  readonly processorRetailerDocs: IProcessorRetailerDocumentsState;
  readonly notifications: INotificationsState;
  readonly farmerKpi: FarmerKpiState;
  readonly retailerKpi: RetailerKpiState;
}

export default function configureStore(): Store<IAppState> {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  const middleWare = isProdEnv()
    ? middleWareEnhancer
    : composeWithDevTools(middleWareEnhancer);
  const store = createStore(rootReducer, middleWare);

  return store;
}
