import React from 'react';
import { HomePage } from '../HomePage';
import { Assessment } from '../Assessment';
import {
  RetailerSettings,
  FarmerSettings,
  ProcessorSettings,
} from 'pages/SettingsPage';
import { FarmerDocumentPage } from 'pages/DocumentPage/FarmerDocuments';
import { ProcessorRetailerDocuments } from 'pages/DocumentPage/ProcessorRetailerDocuments';
import { AssessmentPage } from 'pages/AssesmentPage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { ContactPage } from 'pages/ContactPage';
import { SuppliersPage } from 'pages/SuppliersPage';
import { KPI } from 'pages/KPI';
import { USER_TYPES } from 'config/users';
import { ROUTES } from 'config/routes';
import { ROUTES_TYPES } from 'config/routes';
import { UserTypeRoute } from 'router/UserTypeRoute';
import { PrivateRoute } from 'router/PrivateRoute';
import { RetailerKpi } from '../RetailerKpi';
import { RetailerFarmerKpi } from '../RetailerFarmerKpi';
import { CreateAssessmentPage } from '../CreateAssesmentPage';

const ROUTES_MAP = {
  [ROUTES_TYPES.userType]: UserTypeRoute,
  [ROUTES_TYPES.private]: PrivateRoute,
};

export const dashboardRoutes = [
  {
    component: HomePage,
    exact: true,
    routeType: ROUTES_TYPES.private,
    path: ROUTES.main,
  },
  {
    components: {
      [USER_TYPES.Farmer]: AssessmentPage,
    },
    exact: true,
    routeType: ROUTES_TYPES.userType,
    path: ROUTES.assessments,
  },
  {
    components: {
      [USER_TYPES.Farmer]: CreateAssessmentPage,
    },
    exact: true,
    routeType: ROUTES_TYPES.userType,
    path: ROUTES.createAssessment,
  },
  {
    components: {
      [USER_TYPES.Farmer]: Assessment,
    },
    routeType: ROUTES_TYPES.userType,
    path: ROUTES.singleAssessment,
  },
  {
    path: ROUTES.settings,
    routeType: ROUTES_TYPES.userType,
    components: {
      [USER_TYPES.Farmer]: FarmerSettings,
      [USER_TYPES.Retailer]: RetailerSettings,
      [USER_TYPES.Processor]: ProcessorSettings,
    },
  },
  {
    path: ROUTES.documents,
    routeType: ROUTES_TYPES.userType,
    components: {
      [USER_TYPES.Farmer]: FarmerDocumentPage,
      [USER_TYPES.Processor]: ProcessorRetailerDocuments,
      [USER_TYPES.Retailer]: ProcessorRetailerDocuments,
    },
    exact: true,
  },
  {
    path: ROUTES.privacy,
    component: PrivacyPage,
    routeType: ROUTES_TYPES.private,
  },
  {
    path: ROUTES.contact,
    component: ContactPage,
    routeType: ROUTES_TYPES.private,
  },
  {
    path: ROUTES.suppliers,
    exact: true,
    routeType: ROUTES_TYPES.userType,
    components: {
      [USER_TYPES.Retailer]: SuppliersPage,
      [USER_TYPES.Processor]: SuppliersPage,
    },
  },
  {
    path: ROUTES.kpi,
    exact: true,
    routeType: ROUTES_TYPES.userType,
    components: {
      [USER_TYPES.Farmer]: KPI,
      [USER_TYPES.Retailer]: RetailerKpi,
      [USER_TYPES.Processor]: RetailerKpi,
    },
  },
  {
    path: ROUTES.retailerFarmerKpi,
    exact: true,
    routeType: ROUTES_TYPES.userType,
    components: {
      [USER_TYPES.Retailer]: RetailerFarmerKpi,
      [USER_TYPES.Processor]: RetailerFarmerKpi,
    },
  },
];

export const generateRoutes = routesConfig => {
  return routesConfig.map(({ routeType, ...routeProps }) => {
    const Route = ROUTES_MAP[routeType];
    return <Route key={routeProps.path} {...routeProps} />;
  });
};
