export enum DiscriminatorEnum {
  ImageValidator = 'ImageValidator',
  NumericValidator = 'NumericValidator',
  BooleanValidator = 'BooleanValidator',
  TextValidator = 'TextValidator',
  SelectionFieldValidator = 'SelectionFieldValidator',
  CalculatedFieldValidator = 'CalculatedFieldValidator',
  GridFieldValidator = 'GridFieldValidator',
  DateTimeValidator = 'DateTimeValidator',
  MirrorValidator = 'MirrorValidator',
  MultiSelectionFieldValidator = 'MultiSelectionFieldValidator',
  StaticMediaValidator= 'StaticMediaValidator',
}

type TDisplayConditions = {
  ConditionType_ID: number;
  DataCollectionFieldTemplate_ID: number;
  FieldConditionType: {
    ID: number;
    TypeName: string;
  };
  ID: number;
  LinkedField_ID: number;
  Value: string;
};

interface DataCollectionFieldValidatorModel {
  ID: number;
  MinimumDate: string | null;
  MaximumDate: string | null;
  MinRows: number;
  MaxRows: number;
  AllRowsVisible: boolean;
  Minimum: number;
  Maximum: number;
  IntegerOnly: boolean;
  MinimumLength: number;
  MaximumLength: number;
  Discriminator: keyof typeof DiscriminatorEnum;
}

interface DataCollectionFieldTypeModel {
  ID: number;
  Name: string | null;
  Discriminator: string | null;
}

export interface SelectionFieldValueModel {
  ID: number;
  Name: string | null;
  Value: string | null;
  DisplayOrder: number;
  ParentList_ID: number;
}

interface SelectionFieldValueListModel {
  ID: number;
  Name: string | null;
  Description: string | null;
  Values: Array<SelectionFieldValueModel>;
}

interface GridFieldItemModel {
  ID: number;
  Column: number;
  ParentRow_ID: number;
  GridField_ID: number;
  GridField: any;
}
interface FixedTextItemsModel {
  ID: number;
  DisplayText: string;
  RowNumber: number;
  ParentRow_ID: number;
}
interface GridRowItemModel {
  ID: number;
  ParentParameters_ID: number;
  FixedTextItems: Array<FixedTextItemsModel>;
  GridFields: Array<GridFieldItemModel>;
}

interface MeasureDetailModel {
  ID: number;
  PrefixText: string | null;
  PostfixText: string | null;
  Description: string | null;
}

interface DataCollectionFieldParameterModel {
  ID: number;
  Expression: string | null;
  ValuesListID: number;
  MultiLine: boolean;
  Discriminator: string | null;
  MeasureDetail_ID: number;
  ValuesList: SelectionFieldValueListModel;
  RowTemplate: GridRowItemModel;
  MeasureDetail: MeasureDetailModel;
  ShowRowsAsColumns: boolean;
  FieldTemplateToMirror_ID: number;
}

interface DataCollectionTemplateFieldModel {
  ImageHeight: number | null;
  ImageWidth: number | null;
  OriginalHeight: number | null;
  OriginalSize: boolean;
  OriginalWidth: number | null;
  MaintainAspectRatio: boolean;
  ImageFile: any;
  ID: number;
  Name: string | null;
  DisplayName: string | null;
  Required: boolean;
  Order: number;
  HelpText: string | null;
  GridChild: boolean;
  FieldAlias: string | null;
  FieldType_ID: number;
  FieldType: DataCollectionFieldTypeModel;
  Parameters_ID: number;
  Parameters: DataCollectionFieldParameterModel;
  Validator_ID: number;
  Validator: DataCollectionFieldValidatorModel;
  // DisplayConditions?: any;
  // InitiallyVisibleOnLoad?: any;
  // DisplayConditions?: Array<TDisplayConditions> | undefined;
  InitiallyVisibleOnLoad?: boolean;
  DisplayConditions?: any | null;
  VisibleExternally?: boolean;
  Archived?: boolean;
  HasImage: boolean;
  ImageOnly?: boolean;
}

export interface DataCollectionFieldModel {
  ID: number;
  Guid: string;
  GridRow: number | null;
  GridColumn: number | null;
  Value: string | null;
  Template: DataCollectionTemplateFieldModel;
  Created: string;
  Invalid: boolean;
  GridChildren: Array<Array<DataCollectionFieldModel>>;
}

interface DataCollectionTemplatePageModel {
  ID: number;
  Name: string | null;
  Description: string | null;
  TabOrder: number;
  TemplatePageType: number;
  BackgroundImage: string | null;
  ParentTemplate_ID: number;
  DisplayConditions?: any | null;
}

export interface DataCollectionPageModel {
  ID: number;
  Guid: string;
  Template: DataCollectionTemplatePageModel;
  Fields: Array<DataCollectionFieldModel>;
}

interface LinkedItemModel {
  ID: number;
  Source: string;
  ProducedCollection: string;
  Created: string;
}

interface DataCollectionModel {
  ID: number;
  Guid: string;
  Created: string;
  StatusFlag: number;
  Template_ID: number;
  Owner_ID: number;
  LastModified: string;
  Template: DataCollectionTemplateModel;
  Pages: Array<DataCollectionPageModel>;
  ExternalUserProviderIdentifier: string | null;
  LinkedItems: Array<LinkedItemModel>;
  StartDate: string | null;
  EndDate: string | null;
}

interface DataCollectionTemplateModel {
  ID: number;
  Name: string | null;
  Description: string | null;
  IsActive: boolean;
  IsDPF?: boolean;
  IsFixedTemplate: boolean;
  CanBeUsedExternally: boolean;
  TemplatePageType: number;
  Collections: Array<DataCollectionModel>;
  Translations: any;
  HasImage: boolean;
}

export interface AssessmentDataCollectionModel {
  ID: number;
  Guid: string;
  Template: DataCollectionTemplateModel;
  Pages: Array<DataCollectionPageModel>;

  StatusFlag: number;
  Package: {
    ID: number;
    PackageTemplate: {
      ID: number;
      Name: string;
      PrimaryTemplateID: number;
      PackageTemplate_ID: number;
    };
    Package_Assessments: Array<{ AssessmentID: number; Name: string }>;
  };
  Package_ID: number;
}
