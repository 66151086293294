import React, { useEffect, useState } from 'react';
import './HomePage.scss';
import ArrowRight from '../../assets/icons/ic-arrow-right.svg';
import { HomePageDataModel, HomePageStateInterface } from './types';
// import { HOME_PAGE_INITIAL_STATE } from './config';
import AboutImage from '../../assets/images/about-img.png';
import { useTranslation } from "react-i18next";
import homePageMainJPG from '../../assets/images/HomePage/homePageMain.jpg'
import heroImage from '../../assets/images/HomePage/Hero-image.jpg'
import facebookLogo from '../../assets/images/HomePage/facebook.png'
import instagramLogo from '../../assets/images/HomePage/instagram.png'
import twitterLogo from '../../assets/images/HomePage/x-twitter.png'
import linkedInLogo from '../../assets/images/HomePage/linkedin.png'
import youtubeLogo from '../../assets/images/HomePage/youtube.png'
import assessmentsImage from '../../assets/images/HomePage/AssesmentImage.jpeg'
import resultsImage from '../../assets/images/HomePage/resultsImage.jpg'
import documentImage from '../../assets/images/HomePage/documentsImage.jpg'
const HomePage = () => {
  const { t, i18n } = useTranslation();
  const HOME_PAGE_INITIAL_STATE: HomePageDataModel = {
    aboutData: {
      image: { source: heroImage, alt: 'alltech main page photo' },
      text: {
        title: t('About_Alltech'),
        texts: [
          {
            __html: t('HOME_PAGE_ABOUT1')
          },
          {
            __html: t('HOME_PAGE_ABOUT2')
          },
          {
            __html: t('HOME_PAGE_ABOUT3')
          },
          {
            __html: t('HOME_PAGE_ABOUT4')
          },
        ],
      },
    },
    blogData: {
      title: t('new_blog'),
      posts: [
        {
          image: {
            source:
              'https://www.alltech.com/sites/default/files/styles/16_9_large/public/2021-02/Untitled%20design%20%284%29%20%281%29.png?itok=xLbKqdNI',
            alt: 'blog image',
          },
          title: 'Twig Marston – Seizing Opportunities in the Beef Industry',
          text:
            'What some may view as obstacles, Twig Marston sees as opportunities. The beef nutritionist from Hubbard Feeds joins to share his thoughts on the current state of the beef industry and why he is optimistic about the industry becoming more efficient and sustainable.',
          link:
            'https://www.alltech.com/podcast-blog/twig-marston-seizing-opportunities-beef-industry',
        },
        {
          image: {
            source:
              'https://www.alltech.com/sites/default/files/styles/16_9_large/public/2021-02/Whats%20in%20the%20feed%20bag_web.png?itok=G1Si8QO8',
            alt: 'blog image',
          },
          title: 'How to read a horse feed tag',
          text:
            'As an involved horse owner, no doubt you have spent time carefully selecting a feed to match your horse’s needs. We are sure that you have read the purpose statement on the feed tag identifying the grain that will best suit your horse’s stage of life. You have likely even studied the guaranteed analysis to determine the feed’s crude protein, crude fat and crude fiber levels.',
          link: 'https://www.alltech.com/blog/how-read-horse-feed-tag',
        },
        {
          image: {
            source:
              'https://www.alltech.com/sites/default/files/styles/16_9_large/public/2021-02/2020%20harvest%20blog_web.png?itok=QFzegCe1',
            alt: 'blog image',
          },
          title: 'Diverse Weather Setting the Stage for Mycotoxin Risk',
          text:
            'If we learned anything from 2020, it is that we cannot control everything. For instance, we can’t control the weather, but we can work to control the mycotoxin risk it presents. Weather is the main influencing factor when it comes to mycotoxin risk, leading to a variation in risk levels across the U.S. This year is no exception to that trend, with mycotoxin levels having a wide distribution in the U.S. corn harvest. Mycotoxins can be responsible for the loss of production and efficiency in our animals — a duo we are not interested in.',
          link:
            'https://www.alltech.com/blog/diverse-weather-setting-stage-mycotoxin-risk',
        },
      ],
      linkToNews: 'https://www.alltech.com/blog',
    },
    alltechOneData: {
      video: { source: 'https://www.youtube.com/embed/O6zabmsY4jE' },
      text: {
        title: 'Alltech ONE Virtual Experience Highlights',
        texts: [
          t('HOME_PAGE_TALK_TO_US_TODAY')
        ],
      },
      linkToSite: '/dashboard/contact',
    },
    monthlySessionsData: {
      title: t('upcoming'),
      sessions: [
        {
          image: {
            source:
              'https://one.alltech.com/wp-content/uploads/2020/10/header_africa.png',
            alt: 'session image',
          },
          title: 'Africa: The Power of Partnership',
          date: 'November 19, 8 a.m. EST',
          link: 'https://one.alltech.com/africa-the-power-of-partnerships/',
        },
        {
          image: {
            source:
              'https://one.alltech.com/wp-content/uploads/2020/10/header_agribusiness.png',
            alt: 'session image',
          },
          title: 'Agri-Business',
          date: '',
          link: 'https://one.alltech.com/agri-business/',
        },
        {
          image: {
            source:
              'https://one.alltech.com/wp-content/uploads/2020/10/header_agtech.png',
            alt: 'session image',
          },
          title: 'Ag-Tech',
          date: '',
          link: 'https://one.alltech.com/ag-tech/',
        },
      ],
    },
  };
  const {
    aboutData,
    blogData,
    alltechOneData,
    monthlySessionsData,
  } = HOME_PAGE_INITIAL_STATE;

  return (
    <div className="page-content padding-header-main-page">
      <section className="container-home-page-wrapper">
        <div className="container-home-page">
          <p className='welcome-text'>{t('HOME_PAGE_WELCOME_TEXT')}</p>
          <img
            src={aboutData.image.source}
            alt={aboutData.image.alt}
            className="about-img"
          />


        </div>
      </section>
      <section className="blog-section">
        <div className="main-info">
          <div className="main-text">
            <div className="container-home-page-text-about">
              {aboutData.text.texts.map(t => (
                <p
                  className="main-paragraph"
                  key={t.__html}
                  dangerouslySetInnerHTML={t}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="container-home-page-wrapper">
        <div className="container-home-page talk-to-us">
          <div>
            <img src="https://creative.alltech.com/m/e0dc01cabcb1ed0b/Digital_PNG-icon-question-P167-ai.png" className="question-mark-icon" alt="question mark" />
          </div>
          <div className='margin-talk-to-us'>

            {alltechOneData.text.texts.map(t => (
              <p className="main-paragraph" key={t}>
                {t}
              </p>
            ))}
          </div>
          <div className='margin-talk-to-us'>
            <a
              href={alltechOneData.linkToSite}
              className="highlights-link btn action-primary"
            >
              {t('HOME_PAGE_TALK_TO_US_TODAY_BUTTON')}
            </a>
          </div>
        </div>
      </section>
      <section className='container-home-page-wrapper'>
        <div className="container-home-page features-wrapper">
          <div className='features-block'>
            <a href="/dashboard/assessments">
              <img src={assessmentsImage} alt='assessments' className='feature-image' />
              <h1 className='features-header'>{t('HOME_PAGE_FEATURES_ASSESSMENTS_HEADER')}</h1>
              <p className="features-p">{t('HOME_PAGE_FEATURES_ASSESSMENTS_DESC')}</p>
            </a>
          </div>
          <div className='features-block'>
            <a href="/dashboard/kpi">

              <img src={resultsImage} alt='assessments' className='feature-image' />
              <h1 className='features-header'>{t('HOME_PAGE_FEATURES_RESULTS_HEADER')}</h1>
              <p className="features-p">{t('HOME_PAGE_FEATURES_RESULTS_DESC')}</p>
            </a>
          </div>
          <div className='features-block'>
            <a href="/dashboard/documents">

              <img src={documentImage} alt='assessments' className='feature-image' />
              <h1 className='features-header'>{t('HOME_PAGE_FEATURES_DOCUMENTS_HEADER')}</h1>
              <p className="features-p">{t('HOME_PAGE_FEATURES_DOCUMENTS_DESC')}</p>
            </a>
          </div>

        </div>

      </section>
      <section className="container-home-page-wrapper social-icons ">
        <div className="container-home-page">
          <div className="sessions-posts">
            <div className="social-icons-box">
              <div className='social-icons-single'>
                <a href="https://www.facebook.com/AlltechNaturally/" target='_blank'>
                  <img src={facebookLogo} width="50px" alt='facebook' />
                </a>
              </div>
              <div className='social-icons-single'>
                <a href="https://www.instagram.com/alltechinc/" target='_blank'>
                  <img src={instagramLogo} width="50px" alt='instagram' />
                </a>
              </div>
              <div className='social-icons-single'>
                <a href="https://twitter.com/Alltech" target='_blank'>
                  <img src={twitterLogo} width="50px" alt='twitter' />
                </a>
              </div>
              <div className='social-icons-single'>
                <a href="https://www.linkedin.com/company/alltech" target='_blank'>
                  <img src={linkedInLogo} width="50px" alt='linkedIn' />
                </a>
              </div>
              <div className='social-icons-single'>
                <a href="https://www.youtube.com/user/alltechexperience" target='_blank'>
                  <img src={youtubeLogo} width="50px" alt='youtube' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export { HomePage };
