import React from 'react';
import './KPI.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { FarmerKpiGeneralTab } from 'components/common/Kpi/FarmerKpiGeneralTab';
import { FarmerKpiDrillDown } from 'components/common/Kpi/FarmerKpiDrillDown';
import { useNotifications } from '../../hooks/useNotification';
import { NotificationsTypes } from '../../store/notifications/types';
import { useTranslation } from "react-i18next";
import { DashBoard } from '../../components/common/Kpi/DashBoard';

const KPI = () => {
  useNotifications(NotificationsTypes.kpi);
  const { t, i18n } = useTranslation();

  return (
    <div className="container-custom container">
      <div className="row">
        <div className="col-12">
          {/* <Tabs
            className="nav nav-tabs no-scroll-tabs kpi-tabs"
            defaultActiveKey="MAIN OVERVIEW"
          >
            <Tab
              title={t('RM_main')}
              eventKey="MAIN OVERVIEW"
              className="nav-link nav-item"
            >
              <FarmerKpiGeneralTab />
            </Tab>
            <Tab
              title={t('RM_all')}
              eventKey="ALL DATA"
              className="nav-link nav-item"
            >
              <FarmerKpiDrillDown />
            </Tab>
            <Tab
              title={t('Dashboard')}
              eventKey="DASHBOARD"
              className="nav-link nav-item"
            >
            </Tab>
          </Tabs> */}
              <DashBoard dashboard={true} />
          <div className="tab-content"></div>
        </div>
      </div>
    </div>
  );
};

export { KPI };
