import React, { FC, useMemo } from "react";
import "./TablePagination.scss"
import ReactPaginate from 'react-paginate';
import { useTranslation } from "react-i18next";

interface IProps {
  pagesCount: number;
  changePage: (pageNumber: number) => void;
  changeItemsPerPageCount: (count: number) => void;
  activePageNumber: number;
  itemsPerPage: number;
  totalItems?: number | null;
}

export const TablePagination: FC<IProps> = ({ changeItemsPerPageCount, pagesCount, changePage, activePageNumber, itemsPerPage, totalItems = null }) => {

  const options = [10, 25, 50]
  const { t } = useTranslation();

  const changeOptionHandler = ({ target: { value } }) => {
    const countPerPage = Number(value)
    changeItemsPerPageCount(countPerPage)
  }

  const changeTablePage = (pageNumber) => {
    let number = pageNumber.selected + 1
    changePage(number)
  }
  const firstDisplayingItemIndex = useMemo(() => {
    return activePageNumber * itemsPerPage - itemsPerPage + 1
  }, [activePageNumber, itemsPerPage])

  const lastDisplayingItemIndex = useMemo(() => {
    const lastItemIndex = firstDisplayingItemIndex + itemsPerPage - 1
    return lastItemIndex > totalItems ? totalItems : lastItemIndex;
  }, [firstDisplayingItemIndex, totalItems, itemsPerPage])

  const getTablePagesCount = () => {
    return Array.from({ length: pagesCount }, (_, i) => i).map((i) => (
      <li
        className={`page-item ${i + 1 === activePageNumber ? 'active' : ''}`}
        key={i + 1}
        onClick={() => changeTablePage(i + 1)}
      >
        <a className="page-link">{i + 1}</a>
      </li>
    ))
  }


  return (
    <>
      {pagesCount > 0 ?
        <div className="table-utilities">
          <div className="pagination-wrapper">
            {/* <ul className="pagination">
              {getTablePagesCount()}
            </ul> */}
            <ReactPaginate
              previousLabel={null}
              nextLabel={null}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pagesCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={changeTablePage}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
            <p className="pagination-info">{t('AP_display')} {firstDisplayingItemIndex} {t('AP_display2')} {lastDisplayingItemIndex} {t('AP_display3')} {totalItems}.</p>
          </div>
          <div className="pages-show">
            <span className="show-text">{t('AP_show_per_page')}</span>
            <select name="show-select" id="show-per-page" className="show-select" value={itemsPerPage} onChange={changeOptionHandler}>
              {options.map(item =>
                <option value={item} key={item}>{item}</option>
              )}
            </select>
          </div>
        </div> :
        null
      }
    </>
  )
}
