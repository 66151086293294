import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import Select from 'react-select';
import { Form, FormGroup } from 'react-bootstrap';
import { SimpleOption } from './Option/SimpleOption';
import './Select.scss';
import placeholder from 'lodash/fp/placeholder';
import { translateTabContentText } from 'utils/helpers/useTranslation';
import { useTranslation } from 'react-i18next';

interface IProps {
  isGrid?: boolean;
  data?: any;
  translations?: any;
  name: string;
  labelText?: string;
  options: Array<any>;
  disabled?: boolean;
  required?: boolean;
  conditionToShow?: boolean;
  imageFile?: any;
  OriginalHeight?: number | null;
  ImageHeight?: number | null;
  OriginalWidth?: number | null;
  ImageWidth?: number | null;
  OriginalSize?: boolean;
  MaintainAspectRatio?: boolean;
  placeholder?: string;
  newData?: any;
  oneField?: any;
  setNewData?: React.Dispatch<React.SetStateAction<any>>;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  setOpenMenu?: any
  selectMenuOpen?: any
}

const SelectInput = ({
  isGrid,
  data,
  setChangedFieldsId,
  setNewData,
  oneField,
  newData,
  translations,
  name,
  labelText,
  options,
  conditionToShow,
  imageFile,
  disabled = false,
  required = false,
  placeholder,
  OriginalSize,
  OriginalWidth,
  OriginalHeight,
  ImageHeight,
  ImageWidth,
  MaintainAspectRatio,
  setOpenMenu,
  selectMenuOpen
}: IProps) => {
  const [field, meta, helpers] = useField(name);
  const [newOptions, setNewOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const modifiedOptions = conditionToShow
      ? [
          {
            value: 'select from list',
            label: `${t('Select_a_value_from_list')}`,
          },
          ...options?.map(o => ({
            value: o.value,
            label: translateTabContentText(o.label, translations),
          })),
        ]
      : [{ value: 'select from list',  label: `${t('Select_a_value_from_list')}` }];
    setNewOptions(modifiedOptions);
  }, [conditionToShow]);

  const changeFieldsValue = checkedValue => {
    const findField = newData?.Pages?.map(tab =>
      tab?.Fields?.forEach(f => {
        if (f.Template.ID === oneField.Template.ID) {
          f.Value = checkedValue || null;
        }
      }),
    );
  };

  const handleSetValue = pickedValue => {
    if (pickedValue[0]) {
      changeFieldsValue(pickedValue[0].value);
    }
    return pickedValue;
  };
  return (
    <>
      <Form.Group controlId={field.name} className="form-group-select">
        {labelText && (
          <Form.Label>
            {required && <span className="required-indicator">*</span>}
            {labelText}
          </Form.Label>
        )}
        {imageFile && OriginalSize ? (
          <img
            height={OriginalHeight}
            width={OriginalWidth}
            src={`data:image/png;base64, "`}
            alt="img"
          />
        ) : null}
        {imageFile && !OriginalSize && !MaintainAspectRatio ? (
          <img
            height={ImageHeight}
            width={ImageWidth}
            src={`data:image/png;base64, `}
            alt="img"
          />
        ) : null}
        {imageFile && !OriginalSize && MaintainAspectRatio ? (
          <img width={ImageWidth} src={``} alt="img" />
        ) : null}
        <Select
          selectProps={translations}
          className="simple-select"
          options={newOptions}
          placeholder={placeholder}
          {...field}
          value={
            newOptions && conditionToShow
              ? handleSetValue(newOptions?.filter(o => o.value === field.value))
              : handleSetValue([
                  { value: null, label: 'Select a value from list' },
                ])
          }
          onMenuOpen={() => setOpenMenu?.(true)}
          onMenuClose={() => setOpenMenu?.(false)}
          onChange={option => {
            setChangedFieldsId(prev => {
              if (!prev.includes(isGrid ? data.Guid : field.name)) {
                if (isGrid) {
                  return [...prev, data.Guid];
                } else {
                  return [...prev, field.name];
                }
              }
              return prev;
            });
            helpers.setValue(option.value);
            changeFieldsValue(option.value);
          }}
          isDisabled={disabled}
          components={{
            Option: SimpleOption,
            IndicatorSeparator: null,
          }}
        />
        {meta.error && (
          <p className="error-wrapper">
            {translateTabContentText(meta.error, translations)}
          </p>
        )}
      </Form.Group>
    </>
  );
};

export { SelectInput };
