import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger, FormGroup, FormLabel } from 'react-bootstrap';
import InfoIcon from '../../../src/assets/icons/ic-info.svg';

import './MirroredField.scss';

interface IProps {
  newData?: any;
  fieldData: any;
  setNewData?: any;
  name: string;
  labelText?: string;
  toolTipText?: string;
  fullWidth?: boolean;
  required?: boolean;
  isDate?: boolean;
  oneField?: any;
  isGrid?: boolean;
  data: any;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  gridColumnIndex?: number;
  parentMirrorData?: { name: string; options: any[] };
}

const MirroredField = ({
  newData,
  fieldData,
  setNewData,
  name,
  labelText = '',
  toolTipText = '',
  fullWidth = false,
  required = false,
  isDate = false,
  setChangedFieldsId,
  oneField,
  isGrid,
  data,
  gridColumnIndex,
  parentMirrorData,
}: IProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  // console.log(field.name, parentMirrorDataGuid)
  const value = isDate
    ? field.value
      ? new Date(field.value).toString().substring(4, 15)
      : ''
    : field.value || '';

  const [mirrorFieldParent, mirrorFieldParentMeta] = useField(
    parentMirrorData.name || name,
  );

  const pattern = /percentage/;
  const match = pattern.exec(field.name);
  const percentageWord = match ? match[0] : '';

  const changeFieldsValue = checkedValue => {
    const findField = newData?.Pages?.map(tab =>
      tab?.Fields?.forEach(f => {
        if (f.Template.ID === oneField.Template.ID) {
          f.Value = checkedValue || null;
        }
      }),
    );
    let fieldValue;
    if (checkedValue?.length > 0 && Array.isArray(checkedValue)) {
      fieldValue = checkedValue.map(v =>
        parentMirrorData.options.length
         ? parentMirrorData.options.find(o => o.ID === v)?.Name?? ''
          : v,
      );
      fieldValue = fieldValue.join(', ');
    } else {
      fieldValue = parentMirrorData.options.length
        ? parentMirrorData.options.find(o => o.ID === checkedValue)?.Name ?? ''
        : checkedValue;
    }

    setFieldValue(field.name, fieldValue ? fieldValue : '');
  };
  useEffect(() => {
    changeFieldsValue(mirrorFieldParent.value);
  }, [mirrorFieldParent.value]);
  return (
    <FormGroup className={`form-group ${fullWidth ? 'full-width' : ''}`}>
      <FormLabel className={`label-wrapper ${toolTipText && 'with-tooltip'}`}>
        {required && <span className="required-indicator">*</span>}
        {labelText}
        {/* // to delete after testing */}
        {/* {labelText} */}
        {toolTipText && !isGrid && (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${field.name}`}>{toolTipText}</Tooltip>
              }
            >
              <img
                src={InfoIcon}
                alt="info img"
                className="tooltip-trigger"
                data-toggle="tooltip"
              />
            </OverlayTrigger>
          </>
        )}
      </FormLabel>
      <div className={`${percentageWord ? 'input-with-postfix' : ''}`}>
        <input
          type="text"
          id={field.name}
          className={`form-control ${meta.error && 'is-invalid'}`}
          {...field}
          value={parentMirrorData.name ? value : 'Mirror Input not found'}
          disabled={true}
          // onChange={() => {
          //   setChangedFieldsId(prev => {
          //     if (!prev.includes(field.name)) {
          //       return [...prev, field.name];
          //     }
          //     return prev;
          //   });
          // }}\
          onChange={e => {
            setChangedFieldsId(prev => {
              if (!prev.includes(field.name)) {
                return [...prev, field.name];
              }
              return prev;
            });
            changeFieldsValue(e.target.value);
          }}
        />
        {meta.error && <p className="error-wrapper">{meta.error}</p>}
        {percentageWord ? <span className="postfix">{`%`}</span> : <></>}
      </div>
    </FormGroup>
  );
};

export default MirroredField;
