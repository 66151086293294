import * as yup from 'yup';

export const options = [
  { value: 'Other', label: 'Other' },
  { value: 'Technical Problem', label: 'Technical Problem' },
  { value: 'Report an Issue', label: 'Report an Issue' },
  { value: 'Question about reports or KPIs', label: 'Question about reports or KPIs' },
  { value: 'How to Use Alltech Connect', label: 'How to Use Alltech Connect' },
];

export const CONTACT_INITIAL_VALUES = {
  Reason: '',
  Message: '',
};

export const CONTACT_INITIAL_VALUES_SEND_REQUEST = {
  Reason: 'Other',
  Message: 'Lowering a farm’s carbon footprint goes hand-in-hand with increasing farm efficiency and, therefore, profitability.',
};

export const CONTACT_VALIDATION_SCHEME = yup
  .object()
  .shape({ Reason: yup.string(), Message: yup.string() });
