import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'assets/css/Table.scss';
import './DocumentPage.scss';
import { TablePagination } from 'components/TablePagination';
import {
  getApiDocuments,
  setDefaultTabAction,
} from 'store/documents/actions';
import { formatDate } from 'services';
import { TableStub } from 'components/TableStub';
import { IDocumentsState } from 'store/documents/types';
import Api from 'api';
import TableLoader from 'components/TableLoader';
import { Tab, Tabs } from 'react-bootstrap';
import { TableFilter } from 'components/TableFilter';
import { docsConfig } from './config';
import { useNotifications } from '../../../hooks/useNotification';
import { NotificationsTypes } from '../../../store/notifications/types';
import { useTranslation } from "react-i18next";
import { DashBoard } from 'components/common/Kpi/DashBoard';

interface IState {
  documents: IDocumentsState;
}

export const FarmerDocumentPage = () => {
  useNotifications(NotificationsTypes.documents);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    Results,
    isLoading,
    ResultsPerPage,
    TotalNumberPages,
    PageNum,
    TotalResults,
    docType,
    // sortedByAlphabet,
    sortedByDate,
    sortedByDocName,
    SortKey,
    SortDescending
  } = useSelector((state: IState) => state.documents);

  const dispatchGetDocsWithFilters = (filter?: any, filterForState = {}) => {
    if (!isLoading) dispatch(getApiDocuments({ docType, ...filter }, { ...filterForState }));
  };

  useEffect(() => {
    dispatchGetDocsWithFilters({
      ResultsPerPage: 10,
      PageNum: 1,
      SortDescending: true,
      SortKey: ['DateCreated'],
    }); //
    return () => {
      dispatch(setDefaultTabAction());
    };
  }, []);

  const downloadPdf = useCallback((fileName, fileType) => {
    Api(false)
      .get(
        `/api/Documents/GetDocument?documentName=${fileName}&type=${fileType}`,
        { responseType: 'blob' },
      )
      .then(res => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl);
      });
  }, []);

  const changeDocumentItemsPerPage = (count: number) => {
    dispatchGetDocsWithFilters({
      ResultsPerPage: count, PageNum: 1, SortKey,
      SortDescending
    });
  };

  const changeDocumentsCurrentPage = (pageNumber: number) => {
    dispatchGetDocsWithFilters({
      ResultsPerPage: ResultsPerPage,
      PageNum: pageNumber,
      SortKey,
      SortDescending,
    });
  };

  const handleTabChange = k => {
    dispatchGetDocsWithFilters({
      docType: k,
      ResultsPerPage: 10,
      PageNum: 1,
      SortDescending: true,
      SortKey: ['DateCreated'],
    });
  };

  // const handleSortByAlphabet = () => {
  //   dispatch(sortDocsByAlphabetAction(!sortedByAlphabet));
  // };
  const onSortFarmNAme = () => {
    dispatchGetDocsWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByDocName,
        SortKey: ['DocumentName'],
      },
      { sortedByDocName: !sortedByDocName },
    );
  };

  const onDateSort = () => {
    dispatchGetDocsWithFilters(
      {
        ResultsPerPage: ResultsPerPage,
        PageNum: PageNum,
        SortDescending: !sortedByDate,
        SortKey: ['DateCreated'],
      },
      { sortedByDate: !sortedByDate },
    );
  };

  // const onDateSort = () => {
  //   dispatch(sortDocsByDateAction(!sortedByDate));
  // };

  return (
    <>
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <h2 className="table-heading">Documents</h2>
            <Tabs
              className="nav nav-tabs"
              onSelect={handleTabChange}
              activeKey={docType}
            >
              {Object.entries(docsConfig).map(([key, value]) => {
                return (
                  <Tab
                    className="nav-item nav-link"
                    title={t(`DOCUMENTS_${key}`)}
                    eventKey={value.toString()}
                    key={key}
                  >
                    {key !== "Certificates" ?
                      <div className="custom-table documents-table">
                        <div className="table-header">
                          <div className="name-wrapper">
                            <TableFilter
                              active={sortedByDocName}
                              onSort={onSortFarmNAme}
                              text={t('DOCUMENTS_Document name')}
                            />
                          </div>

                          <div className="date-created-wrapper">
                            <TableFilter
                              active={sortedByDate}
                              text={t('DOCUMENTS_Date created')}
                              onSort={onDateSort}
                            />
                          </div>
                          <div className="action-wrapper">
                            <span>{t('DOCUMENTS_Action')}</span>
                          </div>
                        </div>
                        {isLoading ? (
                          <TableLoader />
                        ) : Results.length ? (
                          <>
                            <div className="table-body">
                              {Results.map(item => (
                                <div
                                  className="table-item"
                                  key={item.DocumentName}
                                >
                                  <div className="name-wrapper">
                                    <span>{item.DocumentName}</span>
                                  </div>
                                  <div className="date-created-wrapper">
                                    <span>
                                      {formatDate(
                                        item.DateCreated,
                                        'MMMM D, YYYY',
                                      )}
                                    </span>
                                  </div>
                                  <div className="action-wrapper">
                                    <a
                                      className="action-primary btn table-action-btn"
                                      onClick={() =>
                                        downloadPdf(
                                          item.DocumentName,
                                          item.DocumentTypeID,
                                        )
                                      }
                                    >
                                      {t('AD_View')}
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <TableStub
                            description={t('DOCUMENTS_There are currently no documents to view')}
                          />
                        )}
                        <TablePagination
                          pagesCount={TotalNumberPages}
                          changeItemsPerPageCount={changeDocumentItemsPerPage}
                          changePage={changeDocumentsCurrentPage}
                          activePageNumber={PageNum}
                          itemsPerPage={ResultsPerPage}
                          totalItems={TotalResults}
                        />
                      </div>
                      : <DashBoard dashboard={false} />}
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
