import React, { useEffect, useState } from 'react'
import { getImage } from 'store/assessmentSingle/actions';

interface IProps {
  template?: any
}

export const Image = ({template} : IProps) => {
  // const [imageSrc, setImageSrc] = useState('');
  // const [croppedWidth, setCrooppedWidth] = useState()

  // const getImageSrc = async () => {
  //   try {
  //     const imageBase64 = await getImage(template.ID);
  //     setImageSrc(imageBase64)
    
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   if (template.HasImage) {
  //     getImageSrc()
  //     setCrooppedWidth(template.OriginalWidth > 235 ? 235 : template.OriginalWidth)
  //   }
  // }, [])

  return (
   <></>
  )
}
