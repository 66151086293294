import React from 'react';
import { useField } from 'formik';

interface IProps {
  name: string;
  labelText: string;
  disabled?: boolean;
  newData?: any;
  oneField?: any;
}

const Checkbox = ({ name, labelText, disabled = false, newData, oneField }: IProps) => {
  const [field, meta] = useField(name);

  const changeFieldsValue = checkedValue => {
    const findField = newData?.Pages.map(tab =>
      tab?.Fields?.forEach(f => {
        if (f?.Template?.ID === oneField?.Template?.ID) {
          f.Value = !checkedValue;
        }
      }),
    );
  };

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id={field.name}
        {...field}
        checked={field.value}
        disabled={disabled}
        onClick = {e => {
          changeFieldsValue(field.value)}
        }
      />
      <label className="form-check-label" htmlFor={field.name}>
        {labelText}
      </label>
        {meta.error && <p className="error-wrapper">{meta.error}</p>}
    </div>
  );
};
export { Checkbox };
