import {ISearchParams, IDecodedToken, SS_AUTH_TOKEN, SS_ROLES} from "store/types";
import moment from 'moment';
import 'moment/min/locales.min'

export const checkAccess = (authoritiesFullList: string[], currentUserAuthorities: string[], logging?: string) => {
    // console.log(authoritiesFullList, currentUserAuthorities, logging)
    let access: boolean = false;
    for (let i = 0; i < currentUserAuthorities.length; i++) {
        if (authoritiesFullList.indexOf(currentUserAuthorities[i]) !== -1) {
            access = true;
            break;
        }
    }
    return access;
}

interface IReturnParam {
    [key: string]: any
}

export const passwordStrengthCheck = (password: string): number => {
    let no: number = 0;
    if (password !== "") {

        let minLength = 9;
        // If the password length is less than or equal to 9
        if (password.length < minLength) no = 1;

        // If the password length is greater than 9 and contain any lowercase alphabet or any number or any special character
        if (password.length >= minLength && (password.match(/[a-z]/) || password.match(/\d+/) || password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))) no = 2;

        // If the password length is greater than 9 and contain alphabet, number, special character respectively
        if (password.length >= minLength && ((password.match(/[a-z]/) && password.match(/\d+/)) || (password.match(/\d+/) && password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) || (password.match(/[a-z]/) && password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)))) no = 3;

        // If the password length is greater than 9 and must contain alphabets, numbers and special characters
        if (password.length >= minLength && password.match(/[a-z]/) && password.match(/\d+/) && password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) no = 4;

        // If the password length is greater than 9 and must contain alphabets, numbers and special characters
        if (password.length >= minLength && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/\d+/) && password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) no = 5;
    }
    return no;
}

export const emailIsValid = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const phoneIsValid = (phone: number): boolean => {
    return phone.toString().length === 12;
}

export const formatDate = (date: number | string | null, dateFormat: string = "DD.MM.YYYY"): string => {
    let locale = localStorage.getItem('lang');
    if(locale == 'po') locale = 'pt';
    if(locale == 'ch') locale = 'zh-cn';
    if (date) {
        // console.log('formatDate',date, moment(date).format("DD.MM.YYYY"))
        return moment(date).locale(locale).format(dateFormat);
    }
    return '';//'Invalid Input Data'

}


export const formatTime = (time: number | string | null): string => {
    if (time) {
        // console.log('formatDate',date, moment(date).format("DD.MM.YYYY"))
        return moment(time).format("HH:mm");
    }
    return '';//'Invalid Input Data'

}

export const validateRequiredFields = (required: string[], fields: { [key: string]: any }): boolean => {
    let valid = true;
    // Object.keys(fields).map((key, num) => {
    //     fields[key]
    // })
    // console.log('func(validateRequiredFields)',required,fields)
    required.forEach((key, num) => {
        // console.log('keys,', fields[key], typeof fields[key])
        switch (typeof fields[key]) {
            case 'number':
                if (fields[key] === null) {
                    valid = false;
                }
                break;
            case 'string':
                if (fields[key] === '' || fields[key] === null) {
                    valid = false;
                }
                break;
            case 'object':
                if (fields[key] !== null && Object.keys(fields[key]).length) {
                    let isValid = true;
                    Object.keys(fields[key]).forEach((levelKey, levelKeyNum) => {
                        switch (typeof levelKey) {
                            case 'number':
                                if (fields[key][levelKey] === null) {
                                    isValid = false;
                                }
                                break;
                            case 'string':
                                if (fields[key][levelKey] === '' || fields[key][levelKey] === null) {
                                    isValid = false;
                                }
                                break;
                            default:
                                break;
                        }

                    })
                    // console.log('isValid level',isValid)
                    if (!isValid)
                        valid = false;
                } else {
                    valid = false;
                }
                break;
            default:
                // valid = true;
                break;
        }
    })
    // console.log(valid)
    return valid;
}


export const formatSearchParams = (params: ISearchParams, onlyQ?: boolean) => {

    // console.log(params)
    let queryParams = '';
    if (!onlyQ) {
        if (params.page != undefined) {
            queryParams += `page=${params.page}&`;
        } else {
            queryParams += `page=${1}&`;
        }

        if (params.sort && params.sort !== '') {
            queryParams += `sort=${params.sort}&`;
        }

        if (params.size) {
            queryParams += `size=${params.size}&`;
        } else {
            queryParams += `size=${10}&`;
        }
    }


    if (params.q) {
        const qKeys = Object.keys(params.q);
        // console.log('keys', qKeys)
        if (qKeys.length > 0) {
            let searchObject: IReturnParam = {}; //{"$and": IReturnParam[]} = {"$and": []}
            qKeys.forEach((key, keyNumber) => {

                const [field, type] = key.split("|");
                const value = params.q[key]
                // console.log('field, type', field, type, value)
                switch (type) {
                    case 'full':
                        if (value && value !== null && value !== "") {
                            // const newString = {[`${field}`]: value}
                            // searchObject["$and"] = [...searchObject["$and"], newString];
                            if (value === 'true' || value === 'false') {
                                if (value === 'true') searchObject[`${field}`] = true;

                                if (value === 'false') searchObject[`${field}`] = false;
                            } else {
                                searchObject[`${field}`] = value;
                            }

                        }
                        break;
                    case 'or':
                        if (value && value !== null && value.length) {
                            // const newString = {[`${field}`]: value}
                            // searchObject["$and"] = [...searchObject["$and"], newString];
                            // console.log('or', value)
                            searchObject['$or'] = value.map((val: string, num: number) => ({[`${field}`]: val}));
                        }
                        break;
                    case 'regex':
                        if (value && value !== null && value !== "") {
                            searchObject[`${field}`] = {
                                "$regex": `${value}.*`,
                                "$options": "i"
                            };
                        }
                        break;
                    case 'from':
                        if (value && value !== null && value !== "") {
                            // console.log("zoshol",searchObject[`${field}`])
                            if (searchObject[`${field}`] === undefined) searchObject[`${field}`] = {};

                            searchObject[`${field}`]["$gt"] = value;
                            // console.log(searchObject)
                        }
                        break;
                    case 'to':
                        if (value && value !== null && value !== "") {
                            if (searchObject[`${field}`] === undefined) searchObject[`${field}`] = {};

                            searchObject[`${field}`]["$lt"] = value;
                        }
                        break;
                    default:
                        break;
                }

            })
            // console.log('searchObject',JSON.stringify(searchObject));
            if (Object.keys(searchObject).length > 0)
                queryParams += `q=${JSON.stringify(searchObject).replace(/{/g, "%7B").replace(/}/g, "%7D").replace(/:/g, "%3A").replace(/\[/g, "%5B").replace(/\]/g, "%5D")}`;
            else
                queryParams += `q=${`%7B%7D`}`;
        } else {
            queryParams += `q=${`%7B%7D`}`;
        }
    } else {
        queryParams += `q=${`%7B%7D`}`;
    }

    return queryParams;
}


export const isSavedInSystem = (): boolean => {
    if (localStorage.getItem(SS_AUTH_TOKEN) && localStorage.getItem(SS_ROLES)) {
        return true;
    } else {
        return false;
    }
}

export const getUserStoredData = (): { token: string | null, roles: string | null } => {
    if (sessionStorage.getItem(SS_AUTH_TOKEN) && sessionStorage.getItem(SS_ROLES)) {
        return ({token: sessionStorage.getItem(SS_AUTH_TOKEN), roles: sessionStorage.getItem(SS_ROLES)});
    } else {
        if (localStorage.getItem(SS_AUTH_TOKEN) && localStorage.getItem(SS_ROLES)) {
            return ({token: localStorage.getItem(SS_AUTH_TOKEN), roles: localStorage.getItem(SS_ROLES)});
        } else {
            return ({token: null, roles: null});
        }
    }

}

export const loginUserToSystem = (token: string, save?: boolean) => {
    if (save) {
        localStorage.setItem(SS_AUTH_TOKEN, token);
        localStorage.setItem(SS_ROLES, JSON.stringify([parseJwt(token).auth]));
        sessionStorage.setItem(SS_AUTH_TOKEN, token);
        sessionStorage.setItem(SS_ROLES, JSON.stringify([parseJwt(token).auth]));
    } else {
        sessionStorage.setItem(SS_AUTH_TOKEN, token);
        sessionStorage.setItem(SS_ROLES, JSON.stringify([parseJwt(token).auth]));
    }
}
// todo uncomment logout
export const logoutUserFromSystem = () => {
    // if (sessionStorage.getItem(SS_AUTH_TOKEN) === localStorage.getItem(SS_AUTH_TOKEN)) {
    //     localStorage.clear();
    //     sessionStorage.clear();
    // } else {
    //     sessionStorage.clear();
    // }
}


export const parseJwt = (token: string): IDecodedToken => {
    if ((/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/).test(token)) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    return ({auth: '', sub: '', exp: 0});
};


export const getNewSort = (sortString: string, state: { [key: string]: any }): string => {
    let newSort: string = "";
    // console.log('new sort',sortString)
    if (state.sort !== '' && state.sort.indexOf(',')) {
        const [currentSortParam, currentSortDirrection] = state.sort.split(',');
        if (sortString === currentSortParam) {
            switch (currentSortDirrection) {
                case 'asc':
                    newSort = `${sortString},desc`;
                    break;
                case 'desc':
                    newSort = `${sortString},asc`;
                    break;
                default:
                    break;
            }
        } else {
            newSort = `${sortString},asc`;
        }

    } else {
        newSort = `${sortString},asc`;
    }

    return newSort;
}

interface inputObject {
    positive: {
        [key: string]: string[]
    },
    negative: {
        [key: string]: string[]
    },
}


export const checkVariable = (variable: string[]) => {
    return variable ? variable : [];
}

export const returnSetForActiveTab = (activeTab: string, termCase: boolean, headerSets: inputObject): string[] => {
    return termCase ? checkVariable(headerSets['positive'][activeTab]) : checkVariable(headerSets['negative'][activeTab]);
}


export const getErrorsFromDictionary = (locale: { [key: string]: string }, errors: string[]) => {
    return errors.map((errorEl, errorNum) => locale[`ERROR_${errorEl}`] ? locale[`ERROR_${errorEl}`] : locale[`ERROR_UnknownError`])
}

// Class for creating multi inheritance.
class multi {
    // Inherit method to create base classes.
    static extends(..._bases: any[]) {
        class classes {

            // The base classes
            get base() {
                return _bases;
            }

            constructor(..._args: any[]) {
                var index = 0;

                for (let b of this.base) {
                    let obj = new b(_args[index++]);
                    multi.copy(this, obj);
                }
            }

        }

        // Copy over properties and methods
        for (let base of _bases) {
            multi.copy(classes, base);
            multi.copy(classes.prototype, base.prototype);
        }

        return classes;
    }

    // Copies the properties from one class to another
    static copy(_target: any, _source: any) {
        for (let key of Reflect.ownKeys(_source)) {
            if (key !== "constructor" && key !== "prototype" && key !== "name") {
                let desc = Object.getOwnPropertyDescriptor(_source, key);
                if (desc)
                    Object.defineProperty(_target, key, desc);
            }
        }
    }
}

export const validatePhone = (rule, value) => {
    if (value && value.match(/_/) == null) {
        return Promise.resolve();
    } else if (!value) {
        return Promise.resolve();
    } else {
        return Promise.reject('Неверный номер');
    }
};

export interface Params {
    [key: string]: any;
}

export const toQueryString = (params: Params) => {
    let queryString = Object.keys(params).filter(key => params[key] !== null).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    return queryString ? `?${queryString}` : '';
}

export const genId = (length: number = 8) => `_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length)}`;
