import React from 'react';
import './PrivacyPage.scss';
import { useTranslation } from 'react-i18next';

export const PrivacyPage = () => {
  const { t } = useTranslation();
  return (
    <div className="page-content">
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <div className="privacy-wrapper">
              <div className="privacy-block">
                <h2 className="privacy-heading">
                  {t('PRIVACY_PAGE_PPaT_HEADER')}
                </h2>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_INTRO')}</p>
                  <p>{t('PRIVACY_PAGE_POLICY_INTRO')}</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_ALLTECH_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_ALLTECH_INFO')}</p>
                  <p>{t('PRIVACY_PAGE_ALLTECH_GROUP')}</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_ADDITIONAL_INFO')}
                </h3>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_ADDITIONAL_INFO_DETAILS')}</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_LEGAL_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_LEGAL_INFO')}</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_GDPR_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_GDPR_INFO')}</p>
                </div>
              </div>
              {t('')}
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {' '}
                  {t('PRIVACY_PAGE_POLICY_CHANGE_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p> {t('PRIVACY_PAGE_POLICY_CHANGE_INFO')}</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_RIGHTS_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_RIGHTS_DETAILS')}</p>
                  <p className="list-title">
                    {t('PRIVACY_PAGE_RIGHTS_LIST_TITLE')}
                  </p>
                  <ul className="privacy-list">
                    <li>
                      <strong>
                        {' '}
                        {t('PRIVACY_PACE_REQUEST_ACCESS_STRONG')}{' '}
                      </strong>{' '}
                      {t('PRIVACY_PAGE_RIGHTS_LIST_REQUEST_ACCESS')}
                    </li>

                    <li>
                      <strong>
                        {t('PRIVACY_PACE_Request_correction_STRONG')}
                      </strong>{' '}
                      {t('PRIVACY_PAGE_RIGHTS_LIST_REQUEST_CORRECTION')}
                    </li>

                    <li>
                      <strong>
                        {' '}
                        {t('PRIVACY_PACE_Request_erasure_STRONG')}
                      </strong>{' '}
                      {t('PRIVACY_PAGE_RIGHTS_LIST_REQUEST_ERASURE')}
                    </li>

                    <li>
                      <strong>
                        {' '}
                        {t('PRIVACY_PACE_Object_to_processing_STRONG')}
                      </strong>{' '}
                      {t('PRIVACY_PAGE_RIGHTS_LIST_OBJECT_PROCESSING')}
                    </li>

                    <li>
                      <strong>{t('Request_A_transfer')}</strong>{' '}
                      {t('PRIVACY_PAGE_RIGHTS_LIST_REQUEST_TRANSFER')}
                    </li>

                    <li>
                      <strong>{t('Withdraw_consent_at_any_time')}</strong>{' '}
                      {t('PRIVACY_PAGE_RIGHTS_LIST_WITHDRAW_CONSENT')}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_COLLECTION_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p className="list-title">{t('COLLECTION_EXPLANATION')}</p>

                  <ul className="wrapper-list">
                    <li>
                      <p className="list-title">
                        <strong> {t('Direct_interactions')}</strong>.{' '}
                        {t('PRIVACY_PAGE_COLLECTION_DIRECT')}
                      </p>
                      <ul className="bullet-list">
                        <li>{t('PRIVACY_PAGE_CD_1')}</li>
                        <li>{t('PRIVACY_PAGE_CD_2')}</li>
                        <li>s{t('PRIVACY_PAGE_CD_3')}</li>
                        <li>{t('PRIVACY_PAGE_CD_4')}</li>
                        <li>{t('PRIVACY_PAGE_CD_5')}</li>
                        <li>{t('PRIVACY_PAGE_CD_6')}</li>
                      </ul>
                    </li>
                    <li>
                      <strong>{t('PRIVACY_PAGE_AUTOMATED')}</strong>
                      {t('PRIVACY_PAGE_COLLECTION_AUTOMATED')}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">
                  {t('PRIVACY_PAGE_PURPOSE_QUESTION')}
                </h3>
                <div className="privacy-text">
                  <p>{t('PRIVACY_PAGE_PURPOSE_INFO')}</p>

                  <p>{t('PRIVACY_PAGE_PURPOSE_INFO_2')}</p>

                  <p>{t('PRIVACY_PAGE_PURPOSE_INFO_3')}</p>

                  <p>{t('PRIVACY_PAGE_PURPOSE_INFO_4')}</p>
                  <p className="list-title">{t('PRIVVACY_PAGE_FOLOWS')}</p>
                  <ul className="bullet-list">
                    <li>
                      <strong>{t('Identity_Data_includes')}</strong>{' '}
                      {t('PRIVACY_PAGE_Identity_Data_includes_EXAMPLES')}
                    </li>
                    <li>
                      <strong>{t('Contact_Data_includes')}</strong>{' '}
                      {t('PRIVACY_PAGE_Contact_Data_includes_EXAMPLES')}
                    </li>
                    <li>
                      <strong>{t('Transaction_Data_includes')}</strong>{' '}
                      {t('PRIVACY_PAGE_Transaction_Data_includes_EXAMPLES')}
                    </li>
                    <li>
                      <strong>{t('Technical_Data_includes')}</strong>{' '}
                      {t('PRIVACY_PAGE_Technical_Data_includes_EXAMPLES')}
                    </li>
                    <li>
                      <strong>{t('Profile_Data_includes')}</strong>{' '}
                      {t('PRIVACY_PAGE_Profile_Data_includes_EXAMPLES')}
                    </li>
                    <li>
                      <strong>{t('Usage_Data_includes')}</strong>{' '}
                      {t('PRIVACY_PAGE_Usage_Data_includes_EXAMPLES')}
                    </li>
                    <li>
                      <strong>
                        {t('Marketing_and_Communications_Data_includes')}
                      </strong>{' '}
                      {t(
                        'PRIVACY_PAGE_Marketing_and_Communications_Data_includes_EXAMPLES',
                      )}
                    </li>
                  </ul>
                  <p>
                    {t('We_do_not_collect_any')}{' '}
                    <strong>{t('Special_Categories_of_Personal_Data')}</strong>
                    {t('PRIVACY_PAGE_NO_SPECIAL_CATEGORIES')}
                  </p>

                  <p>
                    {t('PRIVACY_PAGE_MEDIA_CONTACTS')}{' '}
                    <a
                      href="https://www.cision.com/us/legal/privacy-policy/"
                      target="_blank"
                    >
                      https://www.cision.com/us/legal/privacy-policy/
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
