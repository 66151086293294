import React from 'react';
import "./styles.scss"

const Loader = () => {
  return (
  <div className="preloader-reports">
    <div className="preloader-reports__circle"/>
  </div>
  );
};

export default Loader;