import React, { useEffect, useState } from 'react';
import CrossIcon from 'assets/icons/ic-choice-decline.svg';
import './SuppliersPage.scss';
import { TableSelect } from '../../components/TableSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getApiSuppliers } from '../../store/suppliers/actions';
import { IAppState } from '../../store';
import TableLoader from '../../components/TableLoader';
import { TableFilter } from '../../components/TableFilter';
import { TablePagination } from '../../components/TablePagination';
import { formatDate } from '../../services';
import { SelectComponent } from '../../components/SelectComponent';
import { TableStub } from '../../components/TableStub';
import { collapseDuration } from 'react-select/src/animated/transitions';
import IconAll from '../../assets/icons/IconAll';
import IconBeef from '../../assets/icons/IconBeef';
import IconCrop from '../../assets/icons/IconCrop';
import IconPig from '../../assets/icons/IconPig';
import IconFeedMills from '../../assets/icons/IconFeedMills';
import IconDiary from '../../assets/icons/IconDiary';
import IconAgua from '../../assets/icons/IconAgua';
import IconGoat from '../../assets/icons/IconGoat';
import { useTranslation } from 'react-i18next';

function SuppliersPage() {
  const dispatch = useDispatch();
  const {
    isLoading,
    SystemType,
    Processor,
    Species,
    Project,
    PageNum,
    ResultsPerPage,
    SortDescending,
    SearchTerms,
    FilterValues,
    Location,
    Results,
    TotalNumberPages,
    TotalResults,
    sortedByFarmer,
    sortedByCo2,
    sortedByAssessment,
    SortKeys,
    sortedByFarmName,
  } = useSelector((state: IAppState) => state.suppliers);

  const userRole = useSelector(
    (state: IAppState) => state.auth.profile?.UserRoleName,
  );

  const [searchValue, setSearchValue] = useState(SearchTerms);
  const [selectOptions, setSelectOptions] = useState([]);
  const language = useSelector((state: IAppState) => state.auth?.language);
  const { t } = useTranslation();

  useEffect(()=>{
    let optionss = [];
    console.log('FilterValues.Species', FilterValues.Species);
    if(FilterValues.Species.length) {
      optionss = FilterValues.Species.map(v => {
        if( v === 'Beef') return { value: v, label: t('SUPLIERS_Beef'), icon: <IconBeef /> }
        if( v === 'Crop') return { value: v, label: t('SUPLIERS_Crop'), icon: <IconCrop /> }
        if( v === 'Pork') return { value: v, label: t('SUPLIERS_Pig'), icon: <IconPig /> }
        if( v === 'Feeds') return { value: v, label: t('SUPLIERS_Feeds'), icon: <IconFeedMills /> }
        if( v === 'Diary') return { value: v, label: t('SUPLIERS_Dairy'), icon: <IconDiary /> }
        if( v === 'Aqua') return { value: v, label: t('SUPLIERS_Aqua'), icon: <IconAgua /> }
        if( v === 'Goat') return { value: v, label: t('SUPLIERS_Goat'), icon: <IconGoat /> }
        return { value: v, label: v }
      });
      setSelectOptions([{value: '', label:t('SUPLIERS_All'), icon: <IconAll />}, ...optionss])
    }
  },[FilterValues, language])


  const dispatchWithFilter = (filter = {}, filterForState = {}) => {
    dispatch(
      getApiSuppliers(
        {
          ResultsPerPage,
          SystemType,
          Processor,
          Location,
          Project,
          ...filter
        },
        filterForState,
      ),
    );
  };

  useEffect(() => {
    dispatchWithFilter({SortKeys: ['LastAssessment'],
      SortDescending: true});
    return () => {
      dispatchWithFilter({Species: ''});
    }
  }, []);

  const onSystemTypeChange = (value: string) => {
    dispatchWithFilter({ SystemType: value, PageNum, Species: Species });
  };

  const onSystemTypeDiscard = () => {
    dispatchWithFilter({ SystemType: '' });
  };

  const onProcessorChange = (value: string) => {
    dispatchWithFilter({ Processor: value, PageNum, Species: Species });
  };

  const onProcessorDiscard = () => {
    dispatchWithFilter({ Processor: '' });
  };


  const onProjectChange = (value: string) => {
    dispatchWithFilter({ Project: value, PageNum, Species: Species });
  };

  const onProjectDiscard = () => {
    dispatchWithFilter({ Project: '' });
  };

  const onLocationChange = (value: string) => {
    dispatchWithFilter({ Location: value, PageNum, Species: Species });
  };

  const onLocationDiscard = () => {
    dispatchWithFilter({ Location: ''});
  };

  const onInputChange = e => {
    setSearchValue(e.target.value);
  };

  const onSearch = () => {
    if (searchValue.length) {
      dispatchWithFilter({ SearchTerms: searchValue, Species: Species });
    }
  };
  const onReset = () => {
    dispatchWithFilter({
      Species: '',
      Location: '',
      SystemType: '',
      Project: '',
      Processor: '',
    });
  };

  const onSortFarmNAme = () => {
    dispatchWithFilter(
      {
        SortDescending: !sortedByFarmName,
        SortKeys: ['FarmName'],
        Species: Species
      },
      { sortedByFarmName: !sortedByFarmName },
    );
  };

  const changeItemsPerPage = items => {
    dispatchWithFilter({
      ResultsPerPage: items,
      pageNum: 1,
      SearchTerms,
      SortKeys,
      SortDescending,
      Species: Species
    });
  };

  const changeCurrentPage = page => {
    dispatchWithFilter({
      PageNum: page,
      SearchTerms,
      SortKeys,
      SortDescending,
      Species: Species
    });
  };

  const onSortByFarmer = () => {
    dispatchWithFilter(
      {
        SortDescending: !sortedByFarmer,
        SortKeys: ['Farmer'],
        Species: Species
      },
      { sortedByFarmer: !sortedByFarmer },
    );
  };

  const onSortByAssessment = () => {
    dispatchWithFilter(
      {
        SortKeys: ['LastAssessment'],
        SortDescending: !sortedByAssessment,
        Species: Species
      },
      { sortedByAssessment: !sortedByAssessment },
    );
  };

  const onSortByCo2 = () => {
    dispatchWithFilter(
      {
        SortKeys: ['C02Index'],
        SortDescending: !sortedByCo2,
        Species: Species
      },
      { sortedByCo2: !sortedByCo2 },
    );
  };

  const onSelectSpecies = (selectedSpecies: any) => {
    dispatchWithFilter({ Species: selectedSpecies.value });
  };

  const onResetSearch = () => {
    setSearchValue('');
    dispatchWithFilter({ SearchTerms: '' });
  };
  return (
    <div className="container-custom container">
      <h2 className="table-heading">{t('SUPLIERS_Suppliers')}</h2>
      {/*<iframe width="1140" height="541.25"*/}
      {/*        src="https://app.powerbi.com/reportEmbed?reportId=4bdf599b-6508-496e-9636-90fc5bcca7df&autoAuth=true&ctid=a4b89f32-a57c-41e7-a690-85a9b7ea178b&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZi1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9&filter=dcp_v_DYEA_Reporting_Data_Metric/AlltechID eq 'PMSA'"*/}
      {/*        frameBorder="0" allowFullScreen={true}></iframe>*/}
      <div className="filter-utilities">
        <div className="form-group select-group utilities-item">
          <SelectComponent
            value={Species}
            label={t('RM_spec')}
            options={selectOptions}
            onChange={onSelectSpecies}
          />
        </div>
        <div className="form-group search-group utilities-item">
          <div className="input-inner">
            <input
              type="text"
              className="form-control"
              id="search-bar"
              placeholder={t('SUPLIERS_Search')}
              onChange={onInputChange}
              value={searchValue}
            />
            {searchValue.length > 0 && (
              <span className="cross-icon" onClick={onResetSearch}>
                <img src={CrossIcon} alt="cross icon" />
              </span>
            )}
          </div>
        </div>
        <div className="button-group-supplier">
          <button className="btn action-primary search-btn btn-search" onClick={onSearch}>
            {t('SUPLIERS_Search')}
          </button>
          <button className="btn action-primary search-btn" onClick={onReset}>
            {t('RM_reset')}
          </button>
        </div>
      </div>
      {isLoading ?
        (<TableLoader />) :
        <>
          <div className={userRole === "Retailer" ? "custom-table processor-suppliers-table is-processor" : "custom-table processor-suppliers-table"}>
            <div className="table-header">
              <div className="farm-wrapper">
                <TableFilter
                  active={sortedByFarmName && SortKeys.includes('FarmName')}
                  text={t('AD_Farm name')}
                  onSort={onSortFarmNAme}
                />
              </div>
              <div className="species-wrapper">
                <span>{t('SUPLIERS_Species')}</span>
              </div>
              <div className="member-number-wrapper">
                <span>{t('SUPLIERS_Supplier number')}</span>
              </div>
              <div className="farmer-wrapper">
                <TableFilter
                  active={sortedByFarmer && SortKeys.includes('Farmer')}
                  text={t('SUPLIERS_Farmer')}
                  onSort={onSortByFarmer}
                />
              </div>
              <div className="system-type-wrapper">
                <TableSelect
                  value={SystemType}
                  options={FilterValues.SystemType.map(e => ({
                    value: e,
                    label: e,
                  }))}
                  disabled={false}
                  onChange={onSystemTypeChange}
                  defaultValue={t('SUPLIERS_System type')}
                  discardFilter={onSystemTypeDiscard}
                />
              </div>
              {userRole === "Retailer" && <div className="processor-wrapper">
                <TableSelect
                  value={Processor}
                  options={FilterValues.Processor.map(e => ({
                    value: e,
                    label: e,
                  }))}
                  disabled={false}
                  onChange={onProcessorChange}
                  defaultValue={t('SUPLIERS_Processor')}
                  discardFilter={onProcessorDiscard}
                />
              </div>}
              <div className="project-wrapper">
                <TableSelect
                  value={Project}
                  options={FilterValues.Project.map(e => ({
                    value: e,
                    label: e,
                  }))}
                  disabled={false}
                  onChange={onProjectChange}
                  defaultValue={t('SUPLIERS_Project')}
                  discardFilter={onProjectDiscard}
                />
              </div>
              <div className="location-wrapper">
                <TableSelect
                  value={Location}
                  defaultValue={t('SUPLIERS_Location')}
                  onChange={onLocationChange}
                  disabled={false}
                  options={FilterValues.Location.map(e => ({
                    value: e,
                    label: e,
                  }))}
                  discardFilter={onLocationDiscard}
                />
              </div>
              <div className="last-assessment-wrapper">
                <TableFilter
                  active={sortedByAssessment}
                  text={t('SUPLIERS_Last assessment')}
                  onSort={onSortByAssessment}
                />
              </div>
              <div className="index-wrapper">
                <TableFilter
                  onSort={onSortByCo2}
                  active={sortedByCo2}
                  text={t('SUPLIERS_CO2 index')}
                />
              </div>
            </div>
            {Results.length > 0 ? (
              <div className="table-body">
                {Results.map(supplier => (
                  <div className="table-item" key={supplier.MemberNumber}>
                    <div className="farm-wrapper">
                      <span>{supplier.FarmName}</span>
                    </div>
                    <div className="species-wrapper">
                      <span>{supplier.Species}</span>
                    </div>
                    <div className="member-number-wrapper">
                      <span>{supplier.MemberNumber}</span>
                    </div>
                    <div className="farmer-wrapper">
                      <span>{supplier.Farmer}</span>
                    </div>
                    <div className="system-type-wrapper">
                      <span>{supplier.SystemType}</span>
                    </div>
                    {userRole === "Retailer" && <div className="processor-wrapper">
                      <span>{supplier.Processor}</span>
                    </div>}
                    <div className="project-wrapper">
                      <span>{supplier.ProjectName}</span>
                    </div>
                    <div className="location-wrapper">
                      <span>{supplier.Location}</span>
                    </div>
                    <div className="last-assessment-wrapper">
                      <span>
                        {formatDate(supplier.LastAssessment, 'MMMM D, YYYY')}
                      </span>
                    </div>
                    <div className="index-wrapper">
                      <span>{supplier.CO2Index}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <TableStub description={t("AD_Your search returned no results")} />
            )}
          </div>
        </>
      }
      <TablePagination
        pagesCount={TotalNumberPages}
        changeItemsPerPageCount={changeItemsPerPage}
        changePage={changeCurrentPage}
        activePageNumber={PageNum}
        itemsPerPage={ResultsPerPage}
        totalItems={TotalResults}
      />
    </div>
  );
}

export { SuppliersPage };
