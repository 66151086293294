import {
  INotificationsSet,
  INotificationsRead,
  INotificationsPending,
  INotification,
  INotificationClose,
  INotificationsSetError,
  NotificationsTypes,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { ActionCreator } from 'redux';
import Api from 'api';

const notificationsPendingAction = (
  isLoading: boolean,
): INotificationsPending => ({
  type: 'NOTIFICATIONS_PENDING',
  payload: isLoading,
});

const notificationsSetAction = (
  notifications: Array<INotification>,
): INotificationsSet => ({
  type: 'NOTIFICATIONS_SET',
  payload: notifications,
});

export const readNotifiactionsAction = (ids: number[]): INotificationsRead => ({
  type: 'NOTIFICATIONS_READ',
  payload: ids,
});

export const closeNotification = (
  notificationType: string,
): INotificationClose => ({
  type: 'NOTIFICATION_CLOSE',
  payload: notificationType,
});

export const setNotificationsError = (
  errorMessage: string,
): INotificationsSetError => ({
  type: 'NOTIFICATIONS_SET_ERROR',
  payload: errorMessage,
});

export const getNotifications: ActionCreator<
  ThunkAction<Promise<void>, any, null, any>
> = () => async dispatch => {
  dispatch(notificationsPendingAction(true));
  try {
    const response = await Api(false).get('/api/Notifications/GetUnread');
    dispatch(
      notificationsSetAction(
        response.data.filter(n => n?.Type?.Name !== NotificationsTypes.general),
      ),
    );
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(notificationsPendingAction(false));
  }
};

export const readNotifications: ActionCreator<
  ThunkAction<Promise<void>, any, null, any>
> = (idsList: number[]) => async dispatch => {
  dispatch(notificationsPendingAction(true));
  try {
    const response = await Api(false).post(
      '/api/Notifications/MarkAsRead',
      idsList,
    );
    if (response.statusText === 'OK') {
      dispatch(readNotifiactionsAction(idsList));
    }
  } catch (e) {
    dispatch(setNotificationsError('read notifications error'));
  } finally {
    dispatch(notificationsPendingAction(false));
  }
};
