import { LocaleActions, IAssessmentState } from './types';
import { Reducer } from 'redux';


const initialAssessmentState: IAssessmentState = {
  assessmentItems: [],
  displayItems: [],
  isLoading: false,
  itemsPerPage: 10,
  currentPageNumber: 1,
  totalPagesNumber: 1,
  sortedByAlphabet: false,
  sortedByEndDate: true,
  sortedByStartDate: true,
  contactFormData: false,
};

export const assessmentReducer: Reducer<IAssessmentState, LocaleActions> = (
  state = initialAssessmentState,
  action,
) => {
 
  // return conditionToShow ?
  switch (action.type) {
    case 'SET_ASSESSMENT':
      const assessments = action.assessmentItems;
      const showAssesments = assessments.filter((item) => {
        const singleAssessment = item.Package === null ? true : false;
        const primaryPackageAssessment = !singleAssessment && item.Template_ID === item.Package.PackageTemplate.PrimaryTemplateID ? true : false;
        const conditionToShow = singleAssessment || primaryPackageAssessment ? true : false;
        if (conditionToShow) {
          return item;
        }
      })
      return {
        ...initialAssessmentState,
        assessmentItems: showAssesments,
        displayItems: showAssesments.slice(
          (initialAssessmentState.currentPageNumber - 1) *
            initialAssessmentState.itemsPerPage,
          initialAssessmentState.currentPageNumber *
            initialAssessmentState.itemsPerPage,
        ).sort(
          (firstAssessment, secondAssessment) => Date.parse(secondAssessment.StartDate) - Date.parse(firstAssessment.StartDate),
        ),
        //.sort((firstAssessment, secondAssessment) => {
        //  return firstAssessment.TemplateName.localeCompare(secondAssessment.TemplateName)
        //}),
        totalPagesNumber: Math.ceil(
          showAssesments.length / initialAssessmentState.itemsPerPage,
        ),
      };
    case 'ASSESSMENT_PENDING':
      return {
        ...state,
        isLoading: action.payload,
      };
      case 'FILL_CONTACT_FORM':
      return {
        ...state,
        contactFormData: action.payload,
      };
    case 'SET_ASSESSMENT_ITEMS_PER_PAGE':
      return {
        ...state,
        totalPagesNumber: Math.ceil(
          state.assessmentItems.length / action.payload,
        ),
        itemsPerPage: action.payload,
        currentPageNumber: initialAssessmentState.currentPageNumber,
        displayItems: state.assessmentItems.slice(
          (initialAssessmentState.currentPageNumber - 1) * state.itemsPerPage,
          action.payload,
        ),
      };
    case 'SET_ASSESSMENT_CURRENT_PAGE':
      return {
        ...state,
        currentPageNumber: action.payload,
        displayItems: state.assessmentItems.slice(
          (action.payload - 1) * state.itemsPerPage,
          action.payload * state.itemsPerPage,
        ),
      };
    case 'SORT_ASSESSMENTS_BY_ALPHABET':
      return {
        ...state,
        displayItems: state.displayItems.sort((firstEl, secondEl) => {
          return action.payload
            ? firstEl.TemplateName.localeCompare(secondEl.TemplateName)
            : secondEl.TemplateName.localeCompare(firstEl.TemplateName);
        }),
        sortedByAlphabet: action.payload,
      };
    case 'SORT_ASSESSMENTS_BY_END_DATE':
      return {
        ...state,
        displayItems: state.displayItems.sort(
          (firstAssessment, secondAssessment) => {
            return action.payload
              ? Date.parse(secondAssessment.EndDate) -
                  Date.parse(firstAssessment.EndDate)
              : Date.parse(firstAssessment.EndDate) -
                  Date.parse(secondAssessment.EndDate);
          },
        ),
        sortedByEndDate: action.payload,
      };
    case 'SORT_ASSESSMENTS_BY_START_DATE':
      return {
        ...state,
        displayItems: state.displayItems.sort(
          (firstAssessment, secondAssessment) => {
            return action.payload
              ? Date.parse(secondAssessment.StartDate) -
                  Date.parse(firstAssessment.StartDate)
              : Date.parse(firstAssessment.StartDate) -
                  Date.parse(secondAssessment.StartDate);
          },
        ),
        sortedByStartDate: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
