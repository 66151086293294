import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
interface IProps {
  show: boolean;
  handleShowModal: (e: any) => void;
  imgUrl: string;
  modalText: string;
  onClose?: ()=>void
}
export const SuccessModal: FC<IProps> = props => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.handleShowModal(false);
    if(props.onClose) props.onClose();
  }

  return (
    <Modal show={props.show} onHide={handleClose} centered dialogClassName="modal-large request-sent-modal textless">
      <Modal.Header translate>
        <h5 className="modal-title">{props.modalText}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="success-info">
          <img src={props.imgUrl} alt="img" className="success-img"/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn action-primary" onClick={handleClose}>
          {t('SETTINGS_Close')}
      </Button>
      </Modal.Footer>
    </Modal>
  )
}
