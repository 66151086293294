import React, { FC, useState, useMemo, useRef } from 'react';
import useOutsideClick from '../../utils/customHooks/useOutsideClick';
import FilterIcon from '../../assets/icons/ic-filter-outline.svg';
import './TableSelect.scss';
import { IOptionsInterface } from './types';

interface IProps {
  value: string;
  options: IOptionsInterface[];
  disabled: boolean;
  onChange: (value: string) => void;
  defaultValue: string;
  discardFilter: () => void;
}

export const TableSelect: FC<IProps> = props => {
  const [isSelectActive, setIsSelectActive] = useState(false);
  const selectRef = useRef();

  const selectClasses = useMemo(() => {
    let classes = 'table-select';
    if (props.disabled) {
      classes += ' table-select--disabled';
    } else if (isSelectActive) {
      classes += ' table-select--active';
    }
    return classes;
  }, [isSelectActive, props.disabled]);

  const handleSelectActive = () => {
    setIsSelectActive(prev => !prev);
  };

  const value = props.value || props.defaultValue;

  useOutsideClick(selectRef, () => {
    if (isSelectActive) {
      setIsSelectActive(false);
    }
  });

  const changeOption = nv => {
    if (nv === props.value) {
      props.discardFilter();
    } else {
      props.onChange(nv);
    }
    setIsSelectActive(false);
  };

  return (
    <div className={selectClasses} ref={selectRef}>
      <div className="table-select__input-wrapper">
        <input
          className="table-select__input"
          type="text"
          value={value.length < 10 ? value : value.slice(0, 9) + '...'}
          readOnly={true}
        />
        <img
          src={FilterIcon}
          alt="arrow down icon"
          className="table-select__filter-img"
          onClick={handleSelectActive}
        />
      </div>
      {isSelectActive ? (
        <div className="table-select__menu">
          {props.options.map(item => (
            <div
              className={`
            table-select__menu-item 
            ${props.value === item.value ? 'table-select__menu-item-active' : ''
                }
          `}
              key={item.value}
            >
              <p onClick={() => changeOption(item.value)}>{item.label}</p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
