import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../store';
import { readNotifications } from '../../store/notifications/actions';

export const useNotifications = (notificationType: string) => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(
    (state: IAppState) => state.notifications,
  );
  const notificationsIdsList: number[] = useSelector(
    (state: IAppState) => state.notifications.notifications,
  )
    .filter(notif => notif.Type.Name === notificationType)
    .map(n => n.ID);

  useEffect(() => {
    if (notificationsIdsList.length && !isLoading && !error.length) {
      dispatch(readNotifications(notificationsIdsList));
    }
  }, [notificationsIdsList, error, isLoading]);
};
