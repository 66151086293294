export const ROUTES = {
    home: '/',
    callback: '/callback',
    logout: '/logout',
    logoutCallback: '/logout/callback',
    silentRenew: '/silent-renew',
    dashboard: '/dashboard',
    main: '/dashboard/home',
    assessments: '/dashboard/assessments',
    createAssessment: '/dashboard/createAssessments',
    singleAssessment: '/dashboard/assessments/:uid',
    documents: '/dashboard/documents',
    settings: '/dashboard/settings',
    suppliers: '/dashboard/suppliers',
    privacy: '/dashboard/privacy',
    contact: '/dashboard/contact',
    kpi: '/dashboard/kpi',
    notFound: '/404',
    catchAllRoute: '*',
    retailerFarmerKpi: '/dashboard/farmerkpi'
}

export const ROUTES_TYPES = {
    private: 'PRIVATE',
    userType: 'USER_TYPE',

}
