import React, { useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import InfoIcon from '../../../assets/icons/ic-info.svg';
import { Tooltip, OverlayTrigger, FormGroup, FormLabel } from 'react-bootstrap';

interface IProps {
  name: string;
  labelText?: string;
  toolTipText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  isDate?: boolean;
  newData?: any;
  oneField?: any;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  isGrid?: boolean;
}

const TextInput = ({
  name,
  labelText = '',
  toolTipText = '',
  disabled = false,
  fullWidth = false,
  required = false,
  isDate = false,
  newData,
  oneField,
  setChangedFieldsId,
  isGrid
}: IProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const value = isDate
    ? field.value
      ? new Date(field.value).toString().substring(4, 15)
      : ''
    : field.value || '';
    
    const pattern = /percentage/;
    const match = pattern.exec(field.name);
    const percentageWord = match ? match[0] : '';
    
    const changeFieldsValue = checkedValue => {
     
      const findField = newData?.Pages?.map(tab =>
        tab?.Fields?.forEach(f => {
          if (f.Template.ID === oneField.Template.ID) {
            f.Value = checkedValue || null;
          }
        }),
      );
      setFieldValue(field.name, checkedValue)
    };
  return (
    <FormGroup className={`form-group ${fullWidth ? 'full-width' : ''}`}>
      <FormLabel className={`label-wrapper ${toolTipText && 'with-tooltip'}`}>
        {required && <span className="required-indicator">*</span>}
        {labelText}
        {/* // to delete after testing */}
        {/* {labelText} */}
        {toolTipText && !isGrid && (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${field.name}`}>{toolTipText}</Tooltip>
              }
            >
              <img
                src={InfoIcon}
                alt="info img"
                className="tooltip-trigger"
                data-toggle="tooltip"
              />
            </OverlayTrigger>
          </>
        )}
      </FormLabel>
      <div className={`${percentageWord ? "input-with-postfix" : ''}`}>
        
      <input
        type="text"
        id={field.name}
        className={`form-control ${meta.error && 'is-invalid'}`}
        {...field}
        value={value}
        disabled={disabled}
        // onChange={() => {
        //   setChangedFieldsId(prev => {
        //     if (!prev.includes(field.name)) {
        //       return [...prev, field.name];
        //     }
        //     return prev;
        //   });
        // }}\
        onChange={(e) => {
          changeFieldsValue(e.target.value)
        }}
      />
      {meta.error && <p className="error-wrapper">{meta.error}</p>}
      {percentageWord ? <span className="postfix">{`%`}</span> : <></>}
      </div>
    </FormGroup>
  );
};

export { TextInput };
