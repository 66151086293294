import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import ArrowLeft from 'assets/icons/ic-arrow-left.svg';
import './Assessment.scss';
import { Tab, Tabs } from 'react-bootstrap';
import { TabContent } from 'components/common/TabContent';
import { getAssessment, postAssessment } from 'store/assessmentSingle/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store';
import { IAssessmentSingleState } from 'store/assessmentSingle/types';
import { configureValidation, DISABLED_ASSESSMENT_STATUS_FLAG } from './config';
import TableLoader from 'components/TableLoader';
import { CompleteAssessment } from 'components/CompleteAssessment';
import { SaveAssessmentModal } from '../../components/SaveAssessmentModal';
import { ROUTES } from '../../config/routes';
import { getApiAssessment } from '../../store/assessment/actions';
import { useTranslation } from 'react-i18next';
import { translateText } from 'utils/helpers/useTranslation';
import { CONDITIONS } from 'utils/helpers/enum';
import { checkAllConditionsFollow } from 'utils/helpers/useCondition';
import DownloadReportButton from 'components/DownloadReportButton';
import SavePopup from 'components/SavePopup';

interface IInitialValues {
  [key: string]: string | object;
}

interface IValidationScheme {
  [key: string]: any;
}
interface IProps {
  setDoctId?: React.Dispatch<React.SetStateAction<any>>;
}
const Assessment = () => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [changedFieldsId, setChangedFieldsId] = useState([]);
  const [currentTab, setCurrentTab] = useState();
  const [currentId, setCurrentId] = useState();

  const [formikVal, setFormikVal] = useState();
  const [newDataToPost, setNewDataToPost] = useState([]);
  const [shouldAvtoSave, setShouldAvtoSave] = useState(false);
  const [userHandleSaveData, setUserHandleSaveData] = useState(false);

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [isHaveErrors, SetIsHaveErrors] = useState(false);
  const { t } = useTranslation();

  const [currentAssesmentID, setCurrentAssesmentID] = useState(0);
  const [saveBeforeExitPopupOpen, setSaveBeforeExitPopupOpen] = useState(false);


  const { data, isLoading, error } = useSelector<
    IAppState,
    IAssessmentSingleState
  >(state => state.singleAssessment);
  const [newData, setNewData] = useState(data);

  const [key, setKey] = useState('');
  const [packageKey, setPackageKey] = useState('');

  useEffect(() => {
    if (data.length > 1) {
      const firstVisibleTab = data?.find(
        el => el.Template.TemplatePageType !== 0,
      );
      setCurrentAssesmentID(data[0]?.ID)
      if (!packageKey) {
        setPackageKey(firstVisibleTab?.Template.Name);
      }
    } else {
      const firstVisibleTab = data?.[0]?.Pages.find(
        el => el.Template.TemplatePageType !== 0,
      );
      setCurrentAssesmentID(data[0]?.ID)
      if (key === '' || !key) {
        setKey(firstVisibleTab?.Template.Name);
      }
    }
  }, [data]);

  useEffect(() => {
    setKey(undefined);
  }, [packageKey]);

  const [initialValues, validSchema] = useMemo(() => {
    const initial: IInitialValues = {};
    const validation: IValidationScheme = {};
    data.forEach(ass => {
      ass?.Pages.forEach(page => {
        page.Fields.forEach(field => {
          if (field.Template.FieldType.Name === 'Grid') {
            const arrayOfFields = [];
            field.GridChildren.forEach(fieldArray => {
              const fieldsObject = {};
              fieldArray.forEach(f => {
                fieldsObject[f.Template.Name] = f.Value;
              });
              arrayOfFields.push(fieldsObject);
            });
            initial[field.Guid] = arrayOfFields;
          } else {
            initial[field.Guid] = field.Value || '';
            validation[field.Guid] = configureValidation(
              field.Template.Validator.Discriminator || undefined,
              field.Template.Required,
            );
          }
        });
      });
    });
    const vScheme = yup.object().shape({ ...validation });
    return [initial, vScheme];
  }, [data]);

  useEffect(() => {
    dispatch(getAssessment(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    setNewData(data);
  }, [dispatch, data, key]);

  useEffect(() => {}, [newData, data]);

  const onGoBack = (isFormTouched: any) => {
    if (data?.[0]?.StatusFlag === 2 || data?.[0]?.StatusFlag === 5) {
      history.goBack();
      return null;
    }
    if (!!Object.keys(isFormTouched).length) {
      setExitModalOpen(true);
    } else {
      history.goBack();
    }
  };

  const onModalAgree = async () => {
    await dispatch(getApiAssessment());
    setExitModalOpen(false);
    history.goBack();
  };

  const onModalCancel = () => {
    setExitModalOpen(false);
  };

  const haveErrorsHandler = () => {
    SetIsHaveErrors(true);
  };

  console.log(error);

  const saveFieldsData = (pData, values, changedFieldsId) => {
    const dataToPost = [];

    const processGridField = f => {
      if (f.Template.Validator.MaxRows > f.GridChildren.length) {
        const lastRow = f.GridChildren[f.GridChildren.length - 1];
        if (lastRow) {
          const objEmpty = [];
          let lastCeil = lastRow[lastRow.length - 1];
          let id = lastCeil?.ID + 1 || 1;

          lastRow.forEach(item => {
            const newObj = {
              ...item,
              ID: id++,
              Value: null,
              GridRow: item.GridRow + 1,
            };
            objEmpty.push(newObj);
          });

          f.GridChildren.push(objEmpty);
        }
      }

      f.GridChildren.forEach(fieldsArray => {
        fieldsArray.forEach(field => {
          if (field.GridRow < f.GridChildren.length) {
            field.Value = (values[f.Guid] &&
              values[f.Guid][field.GridRow] &&
              values[f.Guid][field.GridRow][field.Template.Name]) as
              | string
              | null;
          }
        });
      });

      if (changedFieldsId.includes(f.Guid)) {
        dataToPost.push({ ...f });
        setNewDataToPost([...dataToPost]);
      }
    };

    const processField = f => {
      if (f.Template.FieldType.Name === 'Grid') {
        processGridField(f);
      } else {
        if (changedFieldsId.includes(f.Guid)) {
          dataToPost.push({
            ...f,
            Value: values[f.Guid] as string,
          });
          setNewDataToPost([...dataToPost]);
        }
      }
    };

    if (data.length > 0) {
      data.forEach(pData =>
        pData?.Pages.forEach(p => p.Fields.forEach(processField)),
      );
    } else {
      pData?.forEach(p => p?.Fields.forEach(processField));
    }
  };

  useEffect(() => {
    if (changedFieldsId.length > 0)
      saveFieldsData(currentTab, formikVal, changedFieldsId);
  }, [changedFieldsId, formikVal]);

  useEffect(() => {
    let interval;
    if (changedFieldsId.length > 0 && interval === undefined) {
      interval = setTimeout(() => setShouldAvtoSave(true), 300000);
    }
    if (changedFieldsId.length === 0 && !shouldAvtoSave) {
      clearInterval(interval);
    }
  }, [userHandleSaveData, changedFieldsId]);

  const postAndGetAss = async () => {
    console.log('AUTOSAVE STARTED');
    await dispatch(postAssessment(newDataToPost, true));
    setChangedFieldsId([]);
    setNewDataToPost([]);
    setShouldAvtoSave(false);
    console.log('AUTOSAVE COMPLITED');

    // dispatch(getAssessment(currentId));
  };
  useEffect(() => {
    if (shouldAvtoSave && changedFieldsId.length > 0) {
      postAndGetAss();
    }
  }, [shouldAvtoSave]);

  useEffect(() => {
    const form = document.getElementById('main-form123');
    form?.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
    if (changedFieldsId.length === 0) {
      setShouldAvtoSave(false);
    }
  }, [changedFieldsId, shouldAvtoSave]);

  return (
    <>
      <div className="page-content">
        <div
          className={
            isHaveErrors
              ? 'container-custom container assessment-container invalid'
              : 'container-custom container assessment-container'
          }
        >
          <div className="row">
            <div className="col-12">
              {isLoading ? (
                <TableLoader />
              ) : error ? (
                <p>sorry we have some troubles, reload the page please</p>
              ) : (
                <>
                  <div className="assessment-header">
                    <h2
                      className="assessment-name"
                      onClick={() => {
                        setUserHandleSaveData(true);
                        setShouldAvtoSave(false);
                        setSaveBeforeExitPopupOpen(true);
                      }}
                    >
                      <button className="assessment-back">
                        <img src={ArrowLeft} alt="arrow image" />
                      </button>

                      {data.length > 0 && data[0].Template.Name}
                    </h2>
                    <div className="assessment-buttons">
                      {/* <CompleteAssessment
                              assessmentsId={data?.map(ass => ass.ID)}
                              isHaveErrors={haveErrorsHandler}
                            /> */}
                    </div>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validSchema}
                    // validateOnChange={false}
                    // validateOnBlur={false}
                    onSubmit={() => console.log('')}
                  >
                    {formik => (
                      <form
                        id="main-form123"
                        onSubmit={e => {
                          e.preventDefault();
                          formik.handleSubmit(e);
                        }}
                      >
                        <div className="tabs-wrapper">
                          {data?.length > 1 ? (
                            <Tabs
                              onSelect={k => setPackageKey(k)}
                              activeKey={packageKey}
                              className="nav nav-tabs primary-tabs"
                            >
                              {data?.map(
                                (ass, assesmentIndex) => {
                                  
                                  return (
                                    // ass.Template.TemplatePageType == 0 && (
                                    <Tab
                                      onEnter={() => {
                                        setCurrentAssesmentID(ass.ID);
                                        return null;
                                      }}
                                      className="nav-item nav-link"
                                      title={translateText(
                                        ass.Template.Name,
                                        data,
                                      )}
                                      eventKey={ass.Template.Name}
                                      key={ass.ID}
                                    >
                                      <div className="inner-tabs-wrapper">
                                        <Tabs
                                          className="nav nav-tabs"
                                          activeKey={key}
                                          onSelect={k => setKey(k)}
                                        >
                                          {ass?.Pages.map((p, i) => {
                                            const noDisplayConditions =
                                              p.Template.DisplayConditions
                                                .length < 1 ||
                                              p.Template.DisplayConditions ===
                                                undefined ||
                                              p.Template.DisplayConditions ===
                                                null;
                                            const conditionToShow =
                                              noDisplayConditions ||
                                              (!noDisplayConditions &&
                                                checkAllConditionsFollow(
                                                  p,
                                                  newData &&
                                                    newData[assesmentIndex],
                                                ));

                                            return (
                                              p.Template.TemplatePageType !==
                                                0 &&
                                              conditionToShow && (
                                                <Tab
                                                  className="nav-item nav-link"
                                                  title={p.Template.Name}
                                                  eventKey={p.Template.Name}
                                                  key={p.ID}
                                                >
                                                  <TabContent
                                                    newDataToPost={
                                                      newDataToPost
                                                    }
                                                    setUserHandleSaveData={
                                                      setUserHandleSaveData
                                                    }
                                                    newData={
                                                      newData &&
                                                      newData[assesmentIndex]
                                                    }
                                                    setFormikVal={setFormikVal}
                                                    setCurrentTab={
                                                      setCurrentTab
                                                    }
                                                    setCurrentId={setCurrentId}
                                                    changedFieldsId={
                                                      changedFieldsId
                                                    }
                                                    setChangedFieldsId={
                                                      setChangedFieldsId
                                                    }
                                                    translations={
                                                      data?.[0]?.Template
                                                        .Translations
                                                    }
                                                    data={p}
                                                    fieldsDisabled={
                                                      ass.StatusFlag ===
                                                      DISABLED_ASSESSMENT_STATUS_FLAG
                                                    }
                                                    pageNum={i}
                                                    assId={ass.ID}
                                                  >
                                                    <CompleteAssessment
                                                      userHandleSaveData={
                                                        userHandleSaveData
                                                      }
                                                      setUserHandleSaveData={
                                                        setUserHandleSaveData
                                                      }
                                                      changedFieldsId={
                                                        changedFieldsId
                                                      }
                                                      setChangedFieldsId={
                                                        setChangedFieldsId
                                                      }
                                                      assessmentsId={
                                                        data?.[0].ID
                                                      }
                                                      isHaveErrors={
                                                        haveErrorsHandler
                                                      }
                                                      dataP={p}
                                                    />
                                                  </TabContent>
                                                </Tab>
                                              )
                                            );
                                          })}
                                        </Tabs>
                                        <DownloadReportButton
                                          assesmentID={ass.ID}
                                        />
                                      </div>
                                    </Tab>
                                  );
                                },
                                // ),
                              )}
                            </Tabs>
                          ) : (
                            <Tabs
                              className="nav nav-tabs"
                              activeKey={key}
                              onSelect={k => setKey(k)}
                            >
                              {data?.[0]?.Pages.map((p, i) => {
                                const noDisplayConditions =
                                  p.Template.DisplayConditions.length < 1 ||
                                  p.Template.DisplayConditions === undefined ||
                                  p.Template.DisplayConditions === null;
                                const conditionToShow =
                                  noDisplayConditions ||
                                  (!noDisplayConditions &&
                                    checkAllConditionsFollow(p, newData));

                                if (p.Template.TemplatePageType !== 0) {
                                  if (conditionToShow) {
                                    return (
                                      <Tab
                                      onEnter={() => {
                                        setCurrentAssesmentID(data?.[0]?.ID);
                                        return null;
                                      }}
                                        className="nav-item nav-link"
                                        title={translateText(
                                          p.Template.Name,
                                          data,
                                        )}
                                        eventKey={p.Template.Name}
                                        key={p.ID}
                                      >
                                        {/* <SavePopup 
                                                   data={newData && newData[0]}
                                                    isOpen={saveBeforeExitPopupOpen} 
                                                    setIsOpen={setSaveBeforeExitPopupOpen} 
                                                    changedFieldsId={changedFieldsId} 
                                                    setChangedFieldsId={setChangedFieldsId} 
                                                    setUserHandleSaveData={setUserHandleSaveData} 
                                                  />  */}
                                        <TabContent
                                          setCurrentId={setCurrentId}
                                          setUserHandleSaveData={
                                            setUserHandleSaveData
                                          }
                                          setFormikVal={setFormikVal}
                                          setCurrentTab={setCurrentTab}
                                          changedFieldsId={changedFieldsId}
                                          setChangedFieldsId={
                                            setChangedFieldsId
                                          }
                                          initialValues={initialValues}
                                          translations={
                                            data?.[0]?.Template.Translations
                                          }
                                          data={p}
                                          newData={newData && newData[0]}
                                          setNewData={setNewData}
                                          fieldsDisabled={
                                            data?.[0].StatusFlag ===
                                            DISABLED_ASSESSMENT_STATUS_FLAG
                                          }
                                          pageNum={i}
                                          assId={data?.[0].ID}
                                        >
                                          <CompleteAssessment
                                            userHandleSaveData={
                                              userHandleSaveData
                                            }
                                            setUserHandleSaveData={
                                              setUserHandleSaveData
                                            }
                                            changedFieldsId={changedFieldsId}
                                            setChangedFieldsId={
                                              setChangedFieldsId
                                            }
                                            assessmentsId={data?.[0].ID}
                                            isHaveErrors={haveErrorsHandler}
                                            dataP={p}
                                          />
                                        </TabContent>
                                        <DownloadReportButton
                                          assesmentID={data?.[0]?.ID}
                                        />
                                      </Tab>
                                    );
                                  }
                                }
                              })}
                            </Tabs>
                          )}
                        </div>
                        <SavePopup
                          data={newData.find(
                            assesment => assesment.ID === currentAssesmentID,
                          )}
                          isOpen={saveBeforeExitPopupOpen}
                          setIsOpen={setSaveBeforeExitPopupOpen}
                          changedFieldsId={changedFieldsId}
                          setChangedFieldsId={setChangedFieldsId}
                          setUserHandleSaveData={setUserHandleSaveData}
                        />
                      </form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <SaveAssessmentModal
        onSave={onModalAgree}
        onExit={onModalCancel}
        isOpen={exitModalOpen}
        text={t('AP_sav?')}
        primaryButtonText={t('AP_agree')}
        cancelButtonText={t('AP_cancel')}
      /> */}
    </>
  );
};

export { Assessment };
