import { Reducer } from 'redux';
import { INotificationsState, INotificationsActions } from './types';

const initialValues: INotificationsState = {
  notifications: [
    // {
    //   Type: { ID: 1, Name: 'Assessment' },
    //   Priority: { ID: 1, Name: 'Low', Level: 1 },
    //   Created: '2021-03-10T00:00:00',
    //   DateRead: null,
    //   ID: 1,
    //   Message: 'Hello this is a test for assessments',
    // },
    // {
    //   Type: { ID: 1, Name: 'Document' },
    //   Priority: { ID: 1, Name: 'Low', Level: 1 },
    //   Created: '2021-03-15T15:40:53.397',
    //   DateRead: null,
    //   ID: 4,
    //   Message: 'This is another test for docs',
    // },
  ],
  isLoading: false,
  General: { closed: false },
  Assessment: { closed: false },
  KPI: { closed: false },
  Document: { closed: false },
  error: '',
};

const notificationsReducer: Reducer<
  INotificationsState,
  INotificationsActions
> = (state = initialValues, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_PENDING':
      return { ...state, isLoading: action.payload };
    case 'NOTIFICATIONS_SET':
      return { ...state, notifications: action.payload };
    case 'NOTIFICATIONS_READ':
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => !action.payload.some(n => n === notification.ID),
        ),
      };
    case 'NOTIFICATION_CLOSE':
      return {
        ...state,
        [action.payload]: { closed: true },
      };
    case 'NOTIFICATIONS_SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export { notificationsReducer };
