import React from 'react';

const IconFeedMills = ({ color = '#5B6670' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.095 1.333a.32.32 0 0 1 .32.32v1.334a.32.32 0 0 1-.018.106l6.799 2.45.064.03a10.663 10.663 0 0 1 2.634 1.74.32.32 0 0 1 .101.185l.005.054v6.623a.32.32 0 0 1-.262.315l-.058.005H.32a.32.32 0 0 1-.315-.263L0 14.175V7.552a.32.32 0 0 1 .106-.238 10.58 10.58 0 0 1 2.52-1.685l.01-.01 2.152-2.313h-.026a.32.32 0 0 1-.311-.246l-.009-.073V1.653a.32.32 0 0 1 .32-.32zM5.113 8.27H.64v5.585h2.24a4.363 4.363 0 0 0 2.234-3.527l-.001-2.058zm5.136 0H6.113v5.585h1.885a4.363 4.363 0 0 0 2.244-3.82l.007-.065v-1.7zm5.111 0h-4.111v5.585h1.867a4.363 4.363 0 0 0 2.244-3.82V8.269zm-1.13 5.586h1.13v-1.37c-.29.518-.673.982-1.13 1.37zm-5.118 0h1.137v-1.383a5.008 5.008 0 0 1-1.137 1.383zm-5.118 0h1.119l.001-1.352c-.29.511-.668.97-1.12 1.352zM2.868 6.224l-.163.08a9.956 9.956 0 0 0-1.543.965H4.57a10.186 10.186 0 0 0-1.533-.962l-.169-.083zm5.138 0-.166.082c-.544.278-1.058.6-1.536.963h3.402a10.186 10.186 0 0 0-1.533-.962l-.167-.083zm5.13 0-.166.082c-.544.278-1.058.6-1.536.963h3.404c-.48-.366-.994-.689-1.538-.965l-.163-.08zM6.58 3.838l1.66 1.768.025.032.24.12c.75.386 1.445.853 2.066 1.386l-.216.188c.6-.54 1.269-1.017 1.99-1.417L6.579 3.838zm-1.466.058L3.377 5.762c.62.32 1.203.696 1.738 1.12l-.002-2.986zm1 .381v2.337c.438-.317.904-.603 1.391-.855L6.113 4.277zm-.338-2.304h-.693v.694h.693v-.694z"
        fill={color} fillRule="evenodd"/>
    </svg>

  );
};
export default IconFeedMills;
