import { SettingsModel } from './types';
import * as yup from 'yup';
import { SuppliersModel } from 'store/suppliers/types';
import { SettingsProcessorStateInterface } from './types';

const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const PHONE_REG_EXP = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export const SETTINGS_INITIAL_VALUES: SettingsModel = {
  email: '',
  firstName: '',
  lastName: '',
  mailAddress1: '',
  mailAddress2: '',
  mailTown: '',
  mailDistrict: '',
  mailPostalCode: '',
  farmAddress1: '',
  farmAddress2: '',
  farmTown: '',
  farmDistrict: '',
  farmPostalCode: '',
  countryCode: '',
  telephoneOne: '',
  telephoneTwo: '',
  alltechID: '',
  eulA_Accepted: false,
  userRole: {
    id: 1,
    roleName: '',
  },
  registrationDate: '',
  tradingName: '',
  farmName: '',
};

export const SETTINGS_VALIDATION_SCHEME = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(EMAIL_REGEXP, 'Please enter a valid email address.'),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  mailAddress1: yup.string().nullable(),
  mailAddress2: yup.string().nullable(),
  mailTown: yup.string().nullable(),
  mailDistrict: yup.string().nullable(),
  mailPostalCode: yup.string().nullable(),
  farmAddress1: yup.string().nullable(),
  farmAddress2: yup.string().nullable(),
  farmTown: yup.string().nullable(),
  farmDistrict: yup.string().nullable(),
  farmPostalCode: yup.string().nullable(),
  countryCode: yup.string().nullable(),
  telephoneOne: yup
    .string()
    .trim()
    .matches(PHONE_REG_EXP, 'Please enter a valid phone number.'),
  telephoneTwo: yup
    .string()
    .trim()
    .matches(PHONE_REG_EXP, 'Please enter a valid phone number.'),
  alltechID: yup.string().nullable(),
  eulA_Accepted: yup.boolean(),
  userRole: yup.object().shape({
    id: yup.number(),
    roleName: yup.string().nullable(),
  }),
  registrationDate: yup.string().nullable(),
  tradingName: yup.string().nullable(),
  farmName: yup.string().nullable(),
});

export const transformSuppliersIntoRetailersAndProcessors = (
  data: SuppliersModel,
): SettingsProcessorStateInterface => {
  const transformedData: SettingsProcessorStateInterface = {
    processors: '',
    retailers: '',
  };
  const processors: Set<string> = new Set();
  const retailers: Set<string> = new Set();

  data.Results.forEach(supplier => {
    processors.add(supplier.Processor);
    retailers.add(supplier.Retailer);
  });

  transformedData.processors = Array.from(processors).join(', ');
  transformedData.retailers = Array.from(retailers).join(', ');

  return transformedData;
};

export const transfromDataWithoutNull = (data: SettingsModel): SettingsModel => {
  const obj = { ...data };
  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      obj[key] = '';
    }
  }
  return obj;
};

export const getClearObjectForBe = (values: any): any => {
  delete values.eulA_Accepted;
  return values
}
