import React, { useEffect, useMemo, useState } from 'react';
import { SelectComponent } from 'components/SelectComponent';
import InfoSvg from 'assets/icons/ic-info.svg';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Pie,
  PieChart,
  Label,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRetailerKpi,
  setRetailerSelectedSystemTypeAction,
  setRetailerSelectedProcessorAction,
  setRetailerSelectedFarmerAction,
  setRetailerKpiSelectedProjectAction, setRetailerFilterSpecies, setRetailerFilterProcessor, setRetailerFilterProject,
} from 'store/retailerKpi/actions';
import { IAppState } from 'store';
import TableLoader from '../../../TableLoader';
import { IOptionsInterface } from '../../../TableSelect/types';
import { GraphDataModel } from '../FarmerKpiGeneralTab/types';
import {
  EMISSIONS_BY_SOURCE,
  getPerformanceIndicatorByValue, getPerformanceIndicatorByValueNew,
  PERFORMANCE_INDICATORS,
} from '../FarmerKpiGeneralTab/config';
import { PIE_CHART_COLORS } from './config';
import { IAssessmentPart } from '../../../../store/farmerKpi/types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { setFarmerKpiChosenCategory } from '../../../../store/farmerKpi/actions';
import { log } from 'util';
import { formatDate } from '../../../../services';
import { useTranslation } from "react-i18next";


export const labelsForRetailerGraph = {
  AlltechAverage: 'Alltech Average',
  TopTenPercent: 'Alltech Top 10%',
  PreviousAssessment: 'Previous Assessment'
};
export const labelsForRetailerDrillGraph = {
  TopResult: 'Top Result',
  TopTenPercent: 'Alltech Top 10%',
};

const RetailerKpiGeneralTab = ({update}) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const labelsForPieChart = {
    Assessments_Completed: t('AP_complet'),
    Assessments_InProgress: t('AP_in_progress'),
    Assessments_NotStarted: 'To be completed',
  };

  const [speciesList, setSpeciesList] = useState([]);

  const [processorList, setProcessorList] = useState([]);

  const [projectList, setProjectList] = useState([]);
  const userRole = useSelector(
    (state: IAppState) => state.auth.profile?.UserRoleName,
  );
  const isProcessor = userRole === "Processor";

  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    // if(!update)
      dispatch(getRetailerKpi());
  }, [dispatch]);

  const {
    dataList,
    isLoading,
    projectsInfo,
    filteredSpecies,
    filteredProject,
    filteredProcessor
  } = useSelector((state: IAppState) => state.retailerKpi);
  useEffect(()=>{
    setSpeciesList(filteredSpecies);
    setProjectList(filteredProject);
    setProcessorList(filteredProcessor);
  },[filteredSpecies, filteredProcessor, filteredProject])

  const [dataForPieChart, totalAssessments] = useMemo(() => {
    let dataToDraw = [];
    let dataForPieChart = {
      Assessments_Completed: 0,
      Assessments_InProgress: 0,
      Assessments_NotStarted: 0,
    };
    let sumOfAssessments;
    if (projectsInfo.length) {
      sumOfAssessments = projectsInfo.reduce((acc, v, i) => {
        acc += v.Assessments_Completed;
        acc += v.Assessments_InProgress;
        acc += v.Assessments_NotStarted;
        dataForPieChart.Assessments_Completed += v.Assessments_Completed;
        dataForPieChart.Assessments_InProgress += v.Assessments_InProgress;
        dataForPieChart.Assessments_NotStarted += v.Assessments_NotStarted;
        return acc;
      }, 0);
      Object.entries(dataForPieChart).forEach(([key, value]) => {
        if(value) dataToDraw.push({ value: value, name: labelsForPieChart[key] });
      });
    }
    projectsInfo.forEach((project : any)=>{
      if(project?.Species.every(species=> species===speciesList)) {
        let selectedInfo = [];
          dataForPieChart.Assessments_Completed = project.Assessments_Completed;
          dataForPieChart.Assessments_InProgress = project.Assessments_InProgress;
          dataForPieChart.Assessments_NotStarted = project.Assessments_NotStarted;
        Object.entries(dataForPieChart).forEach(([key, value]) => {
          if(value) selectedInfo.push({ value: value, name: labelsForPieChart[key] });
        });
        dataToDraw = selectedInfo;
        sumOfAssessments = project?.Total_Assessments;
      }
    })
    return [dataToDraw, sumOfAssessments];
  }, [projectsInfo, speciesList]);

  const metaData = dataList?.KpiInfoModels?.[0]?.INFORMATION?.MetaData ? JSON.parse(dataList?.KpiInfoModels?.[0]?.INFORMATION?.MetaData): null;
  const isMetaData = Boolean(metaData?.Good);



  let [dataToDraw, assessment] = useMemo(() => {
    let assessment;
    let dataToShow: Array<GraphDataModel> = [];
      const result = dataList.KpiDataModels?.filter(assessment => assessment.AssessmentParts[0].Values.length === 1)

      assessment = result?.[0];
      if(assessment) {
        dataToShow=[];
        if(assessment.AssessmentParts[0].Values[0].AlltechAverage) dataToShow.push(          {
          name: 'Alltech Average',
          value: assessment.AssessmentParts[0].Values[0].AlltechAverage
        })
        if(assessment.AssessmentParts[0].Values[0].TopTenPercent) dataToShow.push(          {
          name: 'Alltech Top 10%',
          value: assessment.AssessmentParts[0].Values[0].TopTenPercent
        })
      }
      result?.forEach((assessment, ind) => {
        let assessmentPart: IAssessmentPart = assessment.AssessmentParts[0];
        if(assessmentPart?.Values[0].Value) {
          dataToShow = [
            ...dataToShow,
            {
              name: `${assessment.Name.slice(0, 8)}, ${assessment.FarmerName} ${new Date(assessment.AssessmentDate)
                .toString()
                .substring(4, 15)}, #${ind+1}`,
              value: assessmentPart?.Values[0].Value,
            },
          ];
        }

      }
      )
    return [dataToShow, assessment];
  }, [dataList]);


  const onFarmersChange = (species: any) => {
    dispatch(setRetailerFilterSpecies(species.value));
    setSpeciesList(species.value)
  };

  const onProcessorChange = (processor: any) => {
    dispatch(setRetailerFilterProcessor(processor))
    setProcessorList(processor)
  };
  const onProjectChange = (project: any) => {
    dispatch(setRetailerFilterProject(project))
    setProjectList(project)
  };
  const onSystemTypeChange = (sysType: any) => {
    setTypeList(sysType)
  };

  const resetFilterHandler = () => {
    setSpeciesList([])
    setProcessorList([])
    setProjectList([])
    setTypeList([])
    dispatch(getRetailerKpi());
  }

  const searchHanler = () => {
    const data = {
      selectedFarmers: [speciesList],
      selectedProcessors: processorList.map(item => item.value),
      selectedProjects: projectList.map(item => item.value),
      selectedSystemTypes: typeList.map(item => item.value)
    }
    dispatch(getRetailerKpi(data));
  }

  return (
    <div className="tab-pane">
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <div className="chart-wrapper general-chart">
            <h2 className="chart-heading heading-large">
              {t('RM_carbon')}
            </h2>
            <div className="kpi-row">
              <div className="kpi-item">
                <div className="form-group">
                  <SelectComponent
                    options={dataList?.SpeciesFilterValues?.map(c => ({ value: c, label: t(c) }))}
                    label={t('RM_spec')}
                    onChange={onFarmersChange}
                    value={speciesList}
                  />
                </div>
              </div>
              {!isProcessor && <div className="kpi-item">
                <div className="form-group">
                  <SelectComponent
                    isMulti
                    options={dataList?.ProcessorFilterValues?.map(c => ({ value: c, label: c }))}
                    label={t('RM_proc')}
                    onChange={onProcessorChange}
                    value={processorList}
                  />
                </div>
              </div>}
              <div className="kpi-item">
                <div className="form-group">
                  <SelectComponent
                    isMulti
                    options={dataList?.ProjectFilterValues?.map(c => ({ value: c, label: c }))}
                    label={t('RM_proj')}
                    onChange={onProjectChange}
                    value={projectList}
                  />
                </div>
              </div>
              <div className="kpi-item">
                <div className="form-group">
                  <SelectComponent
                    isMulti
                    options={dataList?.SystemTypeFilterValues?.map(c => ({ value: c, label: c }))}
                    label={t('RM_system')}
                    onChange={onSystemTypeChange}
                    value={typeList}
                  />
                </div>
              </div>
              <div className="kpi-row action-row">
                  <button className="btn action-primary" onClick={resetFilterHandler}>{t('AD_Reset')}</button>
                  <button
                    className="btn action-primary"
                    onClick={searchHanler}
                    disabled={!speciesList.length}
                  >{t('AD_Search')}</button>
              </div>
            </div>
            {!isLoading && dataToDraw.length == 0 ? <h1 className={"empty-msg"}>{t('RM_filters')}</h1> :
              (<>
                <div className="kpi-row">
                  <div className="kpi-item">
                    <div className="comparison-item performance-item">
                  <span
                    className="comparison-data"
                    style={{
                      color:
                        isMetaData ?
                        getPerformanceIndicatorByValueNew(
                          assessment?.AssessmentParts?.[0]?.Values[0].Value, metaData?.Good, metaData?.Bad
                        ).color: 'black',
                    }}
                  >
                    {isMetaData ?
                    t(getPerformanceIndicatorByValueNew(
                      assessment?.AssessmentParts?.[0]?.Values[0].Value, metaData?.Good, metaData?.Bad
                    ).word) : '—'}
                  </span>
                      <span className="comparison-subtitle">{t('RM_f_perf')}</span>
                    </div>
                  </div>
                  <div className="kpi-item">
                    <div className="comparison-item">
                      <span className="comparison-title">{t('RM_pr_ava')}</span>
                      <span className="comparison-data">
                    {`${dataList?.KpiInfoModels?.[0]?.RetailerProcessorAverage?.toFixed(
                      2,
                    ) || '—'}`}
                  </span>
                      <span className="comparison-subtitle">
                    {dataList?.KpiInfoModels?.[0]?.INFORMATION?.UnitPostFix}
                    {/*<OverlayTrigger*/}
                    {/*  placement="right"*/}
                    {/*  overlay={*/}
                    {/*    <Popover id="Current Assessment">*/}
                    {/*      <Popover.Title as="span" className="tooltip-heading">*/}
                    {/*        What does g CO2/kg FPCM mean?*/}
                    {/*      </Popover.Title>*/}
                    {/*      <Popover.Content className="tooltip-text">*/}
                    {/*        Grams of carbon dioxide equivalent per kg of fat and*/}
                    {/*        protein corrected milk produced. The sum of all*/}
                    {/*        emissions generated on farm in 365 days, divided by*/}
                    {/*        the total volume of fat and protein corrected milk*/}
                    {/*        produced.*/}
                    {/*      </Popover.Content>*/}
                    {/*    </Popover>*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <img*/}
                    {/*    src={InfoSvg}*/}
                    {/*    alt="info img"*/}
                    {/*    className="tooltip-trigger"*/}
                    {/*  />*/}
                    {/*</OverlayTrigger>*/}
                  </span>
                    </div>
                  </div>
                  <div className="kpi-item">
                    <div className="comparison-item">
                      <span className="comparison-title">{t('RM_al_ava')}</span>
                      <span className="comparison-data">
                    {`${dataList?.KpiInfoModels?.[0]?.AlltechAverage?.toFixed(
                      2,
                    ) || '—'}`}
                  </span>
                      <span className="comparison-subtitle">
                        {dataList?.KpiInfoModels?.[0]?.INFORMATION?.UnitPostFix}
                    {/*<OverlayTrigger*/}
                    {/*  placement="right"*/}
                    {/*  overlay={*/}
                    {/*    <Popover id="Alltech Average">*/}
                    {/*      <Popover.Title as="span" className="tooltip-heading">*/}
                    {/*        What does g CO2/kg FPCM mean?*/}
                    {/*      </Popover.Title>*/}
                    {/*      <Popover.Content className="tooltip-text">*/}
                    {/*        Grams of carbon dioxide equivalent per kg of fat and*/}
                    {/*        protein corrected milk produced. The sum of all*/}
                    {/*        emissions generated on farm in 365 days, divided by*/}
                    {/*        the total volume of fat and protein corrected milk*/}
                    {/*        produced.*/}
                    {/*      </Popover.Content>*/}
                    {/*    </Popover>*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <img*/}
                    {/*    src={InfoSvg}*/}
                    {/*    alt="info img"*/}
                    {/*    className="tooltip-trigger"*/}
                    {/*  />*/}
                    {/*</OverlayTrigger>*/}
                  </span>
                    </div>
                  </div>
                  <div className="kpi-item">
                    <div className="comparison-item">
                      <span className="comparison-title">{t('RM_10')}</span>
                      <span className="comparison-data">
                    {`${dataList?.KpiInfoModels?.[0]?.TopTenPercentCarbonBased?.toFixed(
                      2,
                    ) || '—'}
                    `}
                  </span>
                      <span className="comparison-subtitle">
                    {dataList?.KpiInfoModels?.[0]?.INFORMATION?.UnitPostFix}
                    {/*<OverlayTrigger*/}
                    {/*  placement="right"*/}
                    {/*  overlay={*/}
                    {/*    <Popover id="Alltech Top 10%">*/}
                    {/*      <Popover.Title as="span" className="tooltip-heading">*/}
                    {/*        What does g CO2/kg FPCM mean?*/}
                    {/*      </Popover.Title>*/}
                    {/*      <Popover.Content className="tooltip-text">*/}
                    {/*        Grams of carbon dioxide equivalent per kg of fat and*/}
                    {/*        protein corrected milk produced. The sum of all*/}
                    {/*        emissions generated on farm in 365 days, divided by*/}
                    {/*        the total volume of fat and protein corrected milk*/}
                    {/*        produced.*/}
                    {/*      </Popover.Content>*/}
                    {/*    </Popover>*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <img*/}
                    {/*    src={InfoSvg}*/}
                    {/*    alt="info img"*/}
                    {/*    className="tooltip-trigger"*/}
                    {/*  />*/}
                    {/*</OverlayTrigger>*/}
                  </span>
                    </div>
                  </div>
                </div>
                <ResponsiveContainer width="100%" height={dataToDraw.length * 57 + 50}>
                  <BarChart
                    layout="vertical"
                    data={dataToDraw}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="500 1" horizontal={false} />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" scale="band" width={90} />
                    <Tooltip />
                    <Bar dataKey="value" barSize={30} radius={[0, 4, 4, 0]}>
                      {dataToDraw.map(d => (
                        <Cell
                          fill={metaData?.Good ? getPerformanceIndicatorByValueNew(
                            d.value, metaData?.Good, metaData?.Bad
                          ).color : '#37a041'}
                          key={d.name}
                        />
                      ))}
                      <LabelList
                        dataKey="value"
                        position="right"
                        style={{ fill: '#000' }}
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                {isMetaData && <div className="chart-legend traffic-light-legend">
                  <ul className="legend-list">
                    <li className="list-item">
                  <span
                    style={{
                      backgroundColor: PERFORMANCE_INDICATORS.good.color,
                    }}
                  />
                      <span className="list-item-text">{`< ${metaData?.Good}`}</span>
                      {dataList?.KpiInfoModels?.[0]?.INFORMATION?.UnitPostFix}
                    </li>
                    <li className="list-item">
                  <span
                    style={{
                      backgroundColor:
                      PERFORMANCE_INDICATORS.satisfactory.color,
                    }}
                  />
                      <span className="list-item-text">{`${metaData?.Good} - ${metaData?.Bad}`}</span>
                      {dataList?.KpiInfoModels?.[0]?.INFORMATION?.UnitPostFix}
                    </li>
                    <li className="list-item">
                  <span
                    style={{
                      backgroundColor:
                      PERFORMANCE_INDICATORS.unsatisfactory.color,
                    }}
                  />
                      <span className="list-item-text">{`> ${metaData?.Bad}`}</span>
                      {dataList?.KpiInfoModels?.[0]?.INFORMATION?.UnitPostFix}
                    </li>
                  </ul>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="Performance indicator">
                        <Popover.Content className="tooltip-text">
                          {dataList?.KpiInfoModels?.[0]?.INFORMATION?.InfoText}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <img src={InfoSvg} alt="info img" className="tooltip-trigger" />
                  </OverlayTrigger>
                </div>}
              </>)}

          </div>
          {dataToDraw.length !==0 && <div className="chart-wrapper emissions-chart">
            <h2>{t('RM_status')}</h2>
            <PieChart width={900} height={440}>
              <Pie
                data={dataForPieChart}
                cx="50%"
                cy="50%"
                outerRadius={175}
                fill="#8884d8"
                dataKey="value"
                stroke=""
                labelLine={false}
                label={({
                          cx,
                          cy,
                          midAngle,
                          innerRadius,
                          outerRadius,
                          value,
                          index,
                        }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius);
                  const x = cx + (radius + 25) * Math.cos(-midAngle * RADIAN);
                  const additionalLineX =
                    cx + radius * Math.cos(-midAngle * RADIAN);
                  const additionalLineY =
                    cy + radius * Math.sin(-midAngle * RADIAN);
                  const y = cy + (radius + 25) * Math.sin(-midAngle * RADIAN);
                  return (
                    <>
                      <line
                        x1={additionalLineX}
                        y1={additionalLineY}
                        x2={x}
                        y2={y}
                        stroke="#3e3e3f"
                      />

                      <line
                        x1={cx >= x ? x - 150 : x}
                        y1={y}
                        x2={cx >= x ? x : x + 150}
                        y2={y}
                        stroke="#3e3e3f"
                      />
                      <text
                        x={x - 5}
                        y={y - 10}
                        fill="#3e3e3f"
                        textAnchor={x > cx ? 'start' : 'end'}
                        dominantBaseline="central"
                        fontSize="14px"
                      >
                        {t(dataForPieChart[index].name)}{' '}
                        {Math.round((value / totalAssessments) * 100) + `% (${value} ${t('of')} ${totalAssessments})` }
                      </text>
                    </>
                  );
                }}
              >
                {dataForPieChart.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            {/*<div className="chart-legend traffic-light-legend">*/}
            {/*  <ul className="legend-list">*/}
            {/*    <li className="list-item">*/}
            {/*      <span className="list-item-text">Total Farmers:</span>*/}
            {/*    </li>*/}
            {/*    <li className="list-item">*/}
            {/*      <span*/}
            {/*        style={{*/}
            {/*          backgroundColor: PIE_CHART_COLORS[0],*/}
            {/*        }}*/}
            {/*      />*/}
            {/*      <span className="list-item-text">*/}
            {/*        {labelsForPieChart.Assessments_Completed}*/}
            {/*      </span>*/}
            {/*    </li>*/}
            {/*    <li className="list-item">*/}
            {/*      <span*/}
            {/*        style={{*/}
            {/*          backgroundColor: PIE_CHART_COLORS[1],*/}
            {/*        }}*/}
            {/*      />*/}
            {/*      <span className="list-item-text">*/}
            {/*        {labelsForPieChart.Assessments_InProgress}*/}
            {/*      </span>*/}
            {/*    </li>*/}
            {/*    <li className="list-item">*/}
            {/*      <span*/}
            {/*        style={{*/}
            {/*          backgroundColor: PIE_CHART_COLORS[2],*/}
            {/*        }}*/}
            {/*      />*/}
            {/*      <span className="list-item-text">*/}
            {/*        {labelsForPieChart.Assessments_NotStarted}*/}
            {/*      </span>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>}
        </>
      )}
    </div>
  );
};

export { RetailerKpiGeneralTab };
