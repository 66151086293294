import React from 'react';
import { Modal } from 'react-bootstrap';

const ErrorAssessmentModal = ({ onExit, isOpen, text }) => {
  return (
    <Modal
      show={isOpen}
      className="accept-agreement-modal"
      centered
      onHide={onExit}
    >
      <div className="modal-content">
        <Modal.Header className="modal-header" translate="">
          <h5 className="modal-title">{text}</h5>
        </Modal.Header>
        <Modal.Footer className="modal-footer">
          <button
            type="button"
            className="btn action-secondary"
            data-dismiss="modal"
            onClick={onExit}
          >
            Ok
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export { ErrorAssessmentModal };
