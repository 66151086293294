import React from 'react';

const IconReload = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.763 3.007V1.436c0-.087.11-.134.183-.08l3.229 2.373a.1.1 0 0 1 0 .161L7.946 6.263c-.074.054-.183.005-.183-.08V4.617a4.226 4.226 0 0 0-4.147 4.51c.139 2.094 1.846 3.793 3.94 3.927a4.231 4.231 0 0 0 4.445-3.542c.063-.39.401-.674.796-.674.489 0 .868.434.791.914a5.84 5.84 0 0 1-6.427 4.878c-2.673-.3-4.819-2.438-5.122-5.11a5.835 5.835 0 0 1 5.724-6.512z"
        fill={color} fillRule="evenodd"/>
    </svg>
  );
};
export default IconReload;
