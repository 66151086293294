import {
  IProcessorRetailerSortByTypeAction,
  IProcessorRetailerSortByMemberNumberAction,
  IProcessorRetailerSortByFarmNameAction,
  IProcessorRetailerSortByFarmerNameAction,
  IProcessorRetailerSortByDateAction,
  IProcessorRetailerPendingAction,
  ISetProcessorRetailerDocumentsAction,
} from './types';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createGetParams } from '../../utils/getParams';
import Api from '../../api';

const setProcessorDocsAction = (
  data,
): ISetProcessorRetailerDocumentsAction => ({
  type: 'SET_PROCESSOR_RETAILER_DOCUMENTS',
  payload: data,
});
const pendingProcessorDocsAction = (
  isLoading: boolean,
): IProcessorRetailerPendingAction => ({
  type: 'PROCESSOR_RETAILER_DOCUMENTS_PENDING',
  payload: isLoading,
});
const sortProcessorDocsByTypeAction = (
  ascSort: boolean,
): IProcessorRetailerSortByTypeAction => ({
  type: 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_TYPE',
  payload: ascSort,
});
const sortProcessorByMemberNumberAction = (
  ascSort: boolean,
): IProcessorRetailerSortByMemberNumberAction => ({
  type: 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_MEMBER_NUMBER',
  payload: ascSort,
});
const sortProcessorDocsByFarmNameAction = (
  sortAsc: boolean,
): IProcessorRetailerSortByFarmNameAction => ({
  type: 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_FARM_NAME',
  payload: sortAsc,
});
const sortProcessorDocsByFarmerNameAction = (
  sortAsc: boolean,
): IProcessorRetailerSortByFarmerNameAction => ({
  type: 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_FARMER_NAME',
  payload: sortAsc,
});
const sortProcessorDocsByDateAction = (
  sortAsc: boolean,
): IProcessorRetailerSortByDateAction => ({
  type: 'PROCESSOR_RETAILER_DOCUMENTS_SORT_BY_DATE',
  payload: sortAsc,
});

const getProcessorDocsAction: ActionCreator<
  ThunkAction<
    Promise<
      ISetProcessorRetailerDocumentsAction | IProcessorRetailerPendingAction
    >,
    any,
    null,
    ISetProcessorRetailerDocumentsAction
  >
> = (filters = {}, filterObjectForReducer:any = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(pendingProcessorDocsAction(true));

    let getParamsString: string = '';
    if (Object.entries(filters).length) {
      getParamsString = createGetParams(filters);
    }

    try {
      const response = await Api(false).get(
        `/api/Documents/GetProcessorRetailer${getParamsString}`, 
      );
      if (response.status === 200) {
        const documentsData = response.data;
        const data = {...documentsData, ...filters, ...filterObjectForReducer};
        return dispatch(
          setProcessorDocsAction(data),
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(pendingProcessorDocsAction(false));
    }
  };
};

export {
  getProcessorDocsAction,
  sortProcessorByMemberNumberAction,
  setProcessorDocsAction,
  sortProcessorDocsByDateAction,
  sortProcessorDocsByFarmerNameAction,
  sortProcessorDocsByFarmNameAction,
  sortProcessorDocsByTypeAction,
};
