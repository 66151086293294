import React, { FC }  from 'react';
import stubSvg from "../../assets/images/ic-assessment.svg"
import "./TableStub.scss"

interface IProps {
  description: string;
}

export const TableStub: FC<IProps> = ({description}) => {

  return (
  <div className="empty-table">
    <img src={stubSvg} alt="assessment logo" className="empty-img" />
      <p className="empty-message">{ description }</p>
  </div>
  );
};
