import { IAuthState } from './types';
import { Reducer } from 'redux';
import { AUTH_TYPES } from './actions';

const initialAuthState: IAuthState = {
  profile: null,
  language: 'en',
};

export const authReducer: Reducer<IAuthState, any> = (
  state: IAuthState = initialAuthState,
  action: any,
): IAuthState => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN_USER:
      return { ...state, profile: action.payload };
    case AUTH_TYPES.SWITCH_LANGUAGE:
      return { ...state, language: action.payload };
    default:
      return { ...state };
  }
};
