import React, { useContext } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';
import { ROUTES } from '../config/routes';

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {

  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props => {
        const ComponentToRender = component;
        return isAuthenticated() ? (
          <ComponentToRender {...props} />
        ) : (
          <Redirect to={ROUTES.home} />
        );
      }}
    />
  );
};
