import React from 'react';
import { translateTabContentText } from 'utils/helpers/useTranslation';
import './SimpleOption.scss';

const SimpleOption = ({
  innerRef,
  innerProps,
  isSelected,
  children,
  label,
  ...rest
}) => {
  const translations = rest?.selectProps.selectProps;
  let restObj = !rest.hasValue && { ...rest };

  return (
    <div
      className="option-container"
      ref={innerRef}
      {...innerProps}
      {...restObj}
    >
      <span className={`option-label ${isSelected && 'active'}`}>
        {translateTabContentText(label, translations)}
      </span>
    </div>
  );
};
export { SimpleOption };

// const SimpleOption = ({
//   innerRef,
//   innerProps,
//   isSelected,
//   children,
//   label,
//   ...rest
// }) => {
//   let restObj = !rest.hasValue && { ...rest };
//   return (
//     <div
//       className="option-container"
//       ref={innerRef}
//       {...innerProps}
//       {...restObj}
//     >
//       <span className={`option-label ${isSelected && 'active'}`}>{label}</span>
//     </div>
//   );
// };
// export { SimpleOption };
