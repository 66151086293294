import React, { useState } from 'react';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../../services';

interface IProps {
  name: string;
  labelText: string;
  disabled?: boolean;
  required?: boolean;
  newData?: any
  oneField?: any
}

const CALENDAR_DATE_FORMAT = 'dd.MM.yyyy';
const maxDate = new Date();
const DateInput = ({
  name,
  labelText,
  disabled = false,
  required = false,
  newData,
  oneField
}: IProps) => {
  const [field, meta, helpers] = useField(name);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const changeFieldsValue = checkedValue => {
     
    const findField = newData?.Pages?.map(tab =>
      tab?.Fields?.forEach(f => {
        if (f.Template.ID === oneField.Template.ID) {
          f.Value = checkedValue || null;
        }
      }),
    );
    // setFieldValue(field.name, checkedValue)
  };
  return (
    <div className="form-group">
      {labelText && (
        <div className="label-wrapper">
          <label htmlFor={field.name}>
            {required && <span className="required-indicator">*</span>}
            {labelText}
          </label>
        </div>
      )}
      <DatePicker
        {...field}
        dateFormat={CALENDAR_DATE_FORMAT}
        value={
          field?.value && new Date(field.value).toString().substring(4, 15)
        }
        selected={field?.value && new Date(field?.value)}
        className={`form-control ${meta.error && 'is-invalid'}`}
        name={field.name}
        id={field.name}
        onChange={val => {
          helpers.setValue(new Date(val as Date).toISOString());
          changeFieldsValue(new Date(val as Date).toISOString())
        }}
        disabled={disabled}
        maxDate={maxDate}
      />
      {meta.error && <p className="error-wrapper">{meta.error}</p>}
    </div>
  );
};

export { DateInput };
