import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorAssessmentModal } from '../ErrorAssessmentModal';
import { CompleteAssessmentModal } from '../CompleteAssessmentModal';
import {
  getAssessment,
  postAssessment,
  putAssessment,
} from '../../store/assessmentSingle/actions';
import { IAppState } from '../../store';
import { useHistory } from 'react-router';
import { ROUTES } from '../../config/routes';
import { getApiAssessment } from '../../store/assessment/actions';
import { useTranslation } from 'react-i18next';

interface IProps {
  assessmentsId: any;
  isHaveErrors?: (boolean) => void;
  dataP: any;
  changedFieldsId?: any;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  setUserHandleSaveData?: React.Dispatch<React.SetStateAction<boolean>>;
  userHandleSaveData: boolean;
}

const CompleteAssessment = ({
  changedFieldsId,
  userHandleSaveData,
  setUserHandleSaveData,
  setChangedFieldsId,
  assessmentsId,
  isHaveErrors,
  dataP,
}: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = useSelector((state: IAppState) => state.singleAssessment);
  const { Pages, ID, StatusFlag } = useSelector(
    (state: IAppState) => state.singleAssessment.data,
  ).find(ass => ass.Pages.some(p => p.ID === dataP.ID));
  const { errors, values } = useFormikContext();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showAgreePopup, setShowAgreePopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSaveClick = async () => {
    const dataToPost = [];
    let objEmpty = [];
    Pages.forEach(p =>
      p.Fields.forEach(f => {
        if (f.Template.FieldType.Name === 'Grid') {
          if (f.Template.Validator.MaxRows > f.GridChildren.length) {
            let lastRow = f.GridChildren[f.GridChildren.length - 1];
            if (lastRow) {
              objEmpty = [];
              let lastCeil = lastRow[lastRow?.length - 1];
              let id = lastCeil?.ID + 1;
              f.GridChildren[f.GridChildren.length - 1]?.forEach(item => {
                let newObj = {
                  ...item,
                  ID: id++,
                  Value: null,
                  GridRow: item.GridRow + 1,
                };
                objEmpty.push(newObj);
              });
            }
          }
          f.GridChildren.forEach(fieldsArray => {
            fieldsArray.forEach(field => {
              field.Value = values[f.Guid][field.GridRow][
                field.Template.Name
              ] as string | null;
            });
          });
          f.GridChildren.push(objEmpty);
          if (changedFieldsId.includes(f.Guid)) {
            dataToPost.push({ ...f });
          }
        } else {
          if (changedFieldsId.includes(f.Guid)) {
            dataToPost.push({
              ...f,
              Value: values[f.Guid] as string,
            });
          }
        }
      }),
    );
    await dispatch(postAssessment(dataToPost, false));

    dispatch(putAssessment(assessmentsId));

    //dispatch(getAssessment(`${ID}`));
  };

  const pagesSet: Set<string> = new Set();
  Object.keys(errors).forEach(key => {
    const page = data
      .find(ass => ass.Pages.some(p => p.Fields.some(f => f.Guid === key)))
      .Pages.find(p => p.Fields.some(f => f.Guid === key));
    pagesSet.add(page.Template.Name);
  });
  let tabsToFix = Array.from(pagesSet).join(', ');

  const onClick = () => {
    setButtonClicked(true);
    isHaveErrors(true);
  };

  const onExitAgreePopup = () => {
    setShowAgreePopup(false);
    setButtonClicked(false);
  };

  const onExitErrorPopup = () => {
    setShowErrorPopup(false);
    setButtonClicked(false);
  };

  const completeAssessmentHandler = async () => {
    setButtonClicked(false);
    //await dispatch(putAssessment(assessmentsId));
    setUserHandleSaveData(true);
    handleSaveClick();
    dispatch(getApiAssessment());
    history.push(ROUTES.assessments);
  };

  useEffect(() => {
    if (buttonClicked && Object.keys(errors).length) {
      setShowErrorPopup(true);
    } else if (buttonClicked) {
      setShowAgreePopup(true);
    }
    if (Object.keys(errors).length) {
      setShowAgreePopup(false);
    }
  }, [
    buttonClicked,
    errors,
    showAgreePopup,
    setShowErrorPopup,
    setShowAgreePopup,
  ]);

  return (
    <>
      <button
        className="assessment-complete btn action-primary"
        onClick={onClick}
        // onClick={completeAssessmentHandler}
        disabled={data?.[0]?.StatusFlag === 2 || data?.[0]?.StatusFlag === 5}
      >
        {t('AP_as_compl')}
      </button>
      <CompleteAssessmentModal
        isOpen={showAgreePopup}
        onComplete={completeAssessmentHandler}
        onExit={onExitAgreePopup}
      />
      <ErrorAssessmentModal
        isOpen={showErrorPopup}
        onExit={onExitErrorPopup}
        text={`${t('AP_tabs')} ${tabsToFix}`}
      />
    </>
  );
};

export { CompleteAssessment };
