import axios, { AxiosRequestConfig } from 'axios';
import { readAuthDataFromSessionStorage } from '../utils/sessionstorage';

const axiosConfig: AxiosRequestConfig = {
    headers: {
        'Access-Control-Allow-Origin': '*',
        get: {
            'Content-Type': 'application/json',
        },
        post: {
            'Content-Type': 'application/json',
        },
        put: {
            'Content-Type': 'application/json',
        },
    },
};

if (process.env.NODE_ENV === 'production') {
    axiosConfig.baseURL = '/api';
}

const instance = (isUser: boolean) => {
    const baseURL = isUser
        ? 'https://eco2userinfo.azurewebsites.net'
        : process.env.REACT_APP_ECO_CUSTOMER_URL;
    return axios.create({
        headers: {
            ...axiosConfig.headers,
            Authorization: `Bearer ${readAuthDataFromSessionStorage()?.access_token}`,
        },
        baseURL,
    });
};
export default instance;
