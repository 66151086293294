import React from 'react';
import { Modal } from 'react-bootstrap';

interface IProps {
  onComplete: () => void;
  onExit: () => void;
  isOpen: boolean;
}

const CompleteAssessmentModal = ({ onComplete, onExit, isOpen }) => {
  return (
    <Modal
      show={isOpen}
      className="modal-large accept-agreement-modal data-protection-modal"
      centered
      onHide={onExit}
    >
      <div className="modal-content">
        <Modal.Header className="modal-header" translate="">
          <h5 className="modal-title">
            Are you sure you want to complete the assessment?
          </h5>
        </Modal.Header>

        <Modal.Footer className="modal-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn action-secondary"
                  data-dismiss="modal"
                  onClick={onExit}
                >
                  No, cancel
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn action-primary"
                  onClick={onComplete}
                >
                  Yes, continue
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export { CompleteAssessmentModal };
