import React, { useContext } from 'react';
import logo from 'assets/images/alltech-connect-logo2.svg';
import './LoginScreen.scss';
import { AuthContext } from 'providers/AuthProvider';

const LoginScreen = () => {
  const { signinRedirect } = useContext(AuthContext);

  const onLoginButtonClick = e => {
    e.preventDefault();
    signinRedirect();
  };

  return (
    <div className="login-page">
      <div className="welcome-col">
        <div className="welcome-text">
          <h1 className="welcome-heading">Welcome!</h1>
        </div>
      </div>
      <div className="sign-up-col">
        <div className="sign-up-wrapper">
          <div className="sing-up-form">
            <img src={logo} alt="alltech-logo" className="sign-up-logo" />
            <button
              className="btn action-primary get-started"
              type="submit"
              onClick={onLoginButtonClick}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginScreen };
