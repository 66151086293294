import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { RetailerKpiGeneralTab } from 'components/common/Kpi/RetailerKpiGeneralTab';
import { RetailerKpiDrillDownTab } from 'components/common/Kpi/ReatailerKpiDrillDown';
import { useNotifications } from '../../hooks/useNotification';
import { NotificationsTypes } from '../../store/notifications/types';
import '../KPI/KPI.scss';
import { useLocation } from 'react-router';
import { useTranslation } from "react-i18next";
import { DashBoard } from '../../components/common/Kpi/DashBoard';

const RetailerKpi = () => {
  useNotifications(NotificationsTypes.kpi);
  const { t } = useTranslation();
  const { state } = useLocation();
  // @ts-ignore
  const drill = state?.drillDown || false;

  return (
    <div className="container-custom container">
      <div className="row">
        <div className="col-12">
          {/* <Tabs
            className="nav nav-tabs no-scroll-tabs kpi-tabs"
            defaultActiveKey={drill ? "DRILL-DOWN" : "GENERAL"}
          >
            <Tab
              title={t('RM_main')}
              eventKey="GENERAL"
              className="nav-link nav-item"
            >
              <RetailerKpiGeneralTab update={drill} />
            </Tab>
            <Tab
              title={t('RM_all')}
              eventKey="DRILL-DOWN"
              className="nav-link nav-item"
            >
              <RetailerKpiDrillDownTab />
            </Tab>
            <Tab
              title={t('Dashboard')}
              eventKey="DASHBOARD"
              className="nav-link nav-item"
            >
            </Tab>
          </Tabs> */}
              <DashBoard dashboard={true} />
        </div>
      </div>
    </div>
  );
};

export { RetailerKpi };
