import { useEffect, useState } from 'react';
import { getImage } from 'store/assessmentSingle/actions';
import React from 'react';

interface IProps {
  HasImage: boolean;
  imageID: number;
  template?: any;
  showRowsAsColumns?: boolean;
  isTabContent?: boolean;
}

export const ImageInHeader = ({
  HasImage,
  imageID,
  template,
  isTabContent,
  showRowsAsColumns,
}: IProps) => {
  const [imageSrc, setImageSrc] = useState('');
  const [croppedWidth, setCrooppedWidth] = useState();

  const getImageSrc = async () => {
    try {
      const imageBase64 = await getImage(imageID);
      setImageSrc(imageBase64);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (HasImage && !template.ImageOnly) {
      getImageSrc();
      if(!isTabContent){

        setCrooppedWidth(
          template.OriginalWidth > 236 ? 236 : template.OriginalWidth,
        );
      }
    }
  }, []);

  return (
    <>
      {HasImage && (
        <th
          className={
            showRowsAsColumns ? 'table-header-column' : 'table-header-row'
          }
        >
          <div className="header-fit">
            {HasImage && !template.ImageOnly ? (
              <div className={isTabContent && template?.DisplayName ? " image-with-header-tab-content": ""}>
                {isTabContent && template?.DisplayName }
              <img
                src={imageSrc}
                width={croppedWidth}
                className="table-heading-img"
                alt="img"
              />
              </div>
            ) : (
              <></>
            )}
          </div>
        </th>
      )}

      {template?.Name?.includes('used-for-space') ? (
        <th
          className={
            showRowsAsColumns ? 'table-header-column' : 'table-header-row'
          }
        >
          <div className="used-for-space">used-for-space</div>
        </th>
      ) : (
        <></>
      )}
    </>
  );
};
