import { useEffect, useState } from 'react';
import { getImage } from 'store/assessmentSingle/actions';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translateTabContentText } from 'utils/helpers/useTranslation';
import InfoIcon from '../../../src/assets/icons/ic-info.svg';

interface IProps {
  headerText: string;
  template?: any;
  showRowsAsColumns: boolean;
  translations: any
}

export const TableHeader = ({
  headerText,
  template,
  showRowsAsColumns,
  translations
}: IProps) => {
  return (
    <>
      <th
        className={
          showRowsAsColumns ? 'table-header-column' : 'table-header-row'
        }
        >
        <div className="header-fit ">
          <div className={template.HelpText ? "header-with-tooltip" : ""}>
            <div>

            <p className={template.HelpText ? "header-with-tooltip-text" : ""}>{!template.ImageOnly && headerText}</p>
            </div>
            <div>
          {template.HelpText && (
            <div className="heading-tooltip">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${template.DisplayName}`}>
                      {translateTabContentText(
                        template.HelpText,
                        translations,
                        )}
                    </Tooltip>
                  }
                >
                  <img
                    src={InfoIcon}
                    alt="info img"
                    className="tooltip-trigger"
                    data-toggle="tooltip"
                  />
                </OverlayTrigger>
              </div>
            )}
            </div>
          </div>
        </div>
      </th>
    </>
  );
};
