type TtranslationPairs = {
  ID: number;
  Parent_ID: number;
  Source: string;
  Translation: string;
};
type TtranslationTabArray = {
  Active?: boolean;
  ID: number;
  Language_ID: number;
  ParentLanguage: {
    Alpha2: string;
    ID: number;
    Name: string;
  };
  ParentTemplate?: null | any;
  ParentTemplate_ID: number;
  TranslationPairs: [TtranslationPairs];
};
interface ItranslationArray {
  Guid: string;
  ID: number;
  Package: any;
  Package_ID: number | null;
  Pages: any;
  StatusFlag: number;
  Template: any;
}

export const translateText = (
  text: string,
  translationArray: Array<ItranslationArray>,
) => {
  const lang = localStorage.getItem('lang') || 'en';
  switch (lang) {
    case 'en':
      return text;

    case 'po':
      let translatedPtWord = '';
      translationArray?.[0]?.Template.Translations.map(l => {
        if (l.ParentLanguage.Alpha2 === 'pt') {
          const translatedText = l.TranslationPairs.find(
            el => el.Source === text,
          );
          translatedPtWord = translatedText?.Translation;
        }
      });
      if (!translatedPtWord) {
        return text;
      } else {
        return translatedPtWord;
      }
    case 'es':
      let translatedEsWord = '';
      translationArray?.[0]?.Template.Translations.map(l => {
        if (l.ParentLanguage.Alpha2 === 'es') {
          const translatedText = l.TranslationPairs.find(
            el => el.Source === text,
          );
          translatedEsWord = translatedText?.Translation;
        }
      });
      if (!translatedEsWord) {
        return text;
      } else {
        return translatedEsWord;
      }
    case 'ch':
      let translatedChWord = '';
      translationArray?.[0]?.Template.Translations.map(l => {
        if (l.ParentLanguage.Alpha2 === 'ch') {
          const translatedText = l.TranslationPairs.find(
            el => el.Source === text,
          );
          translatedChWord = translatedText?.Translation;
        }
      });
      if (!translatedChWord) {
        return text;
      } else {
        return translatedChWord;
      }
    default:
      return text;
  }
};

export const translateTabContentText = (
  text: string,
  translationArray: Array<TtranslationTabArray>,
) => {
  const lang = localStorage.getItem('lang') || 'en';
  switch (lang) {
    case 'en':
      return text;
    case 'po':
      let translatedPtWord = '';
      translationArray?.map(l => {
        if (l.ParentLanguage.Alpha2 === 'pt') {
          const translatedText = l.TranslationPairs.find(
            el => el.Source === text,
          );
          translatedPtWord = translatedText?.Translation;
        }
      });
      if (!translatedPtWord) {
        return text;
      } else {
        return translatedPtWord;
      }
    case 'es':
      let translatedEsWord = '';
      translationArray?.map(l => {
        if (l.ParentLanguage.Alpha2 === 'es') {
          const translatedText = l.TranslationPairs.find(
            el => el.Source === text,
          );
          translatedEsWord = translatedText?.Translation;
        }
      });
      if (!translatedEsWord) {
        return text;
      } else {
        return translatedEsWord;
      }
    case 'ch':
      let translatedChWord = '';
      translationArray?.map(l => {
        if (l.ParentLanguage.Alpha2 === 'ch') {
          const translatedText = l.TranslationPairs.find(
            el => el.Source === text,
          );
          translatedChWord = translatedText?.Translation;
        }
      });
      if (!translatedChWord) {
        return text;
      } else {
        return translatedChWord;
      }
    default:
      return text;
  }
};
