
import React from "react";
import "./Footer.scss";
import { FooterConfig } from '../Header/config';
import { HeaderLinkInterface } from "../Header/types";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";
import {IAppState} from "../../../store";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const userRole = useSelector(
      (state: IAppState) => state.auth.profile?.UserRoleName,
  );
  const data: Array<HeaderLinkInterface> = FooterConfig[userRole] || [];
  return (
    <footer className="footer">
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <div className="footer-inner">
              <nav className="footer-nav">
                <ul className="nav-list">
                  {data.map(({ to, value }) => (
                    <li
                      className={`list-item ${to === pathname && "active"}`}
                      key={to}
                    >
                      <Link to={to} className="item-link">
                        {t(value)}{' '}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <p className="footer-rights">
                Copyright Alltech 2024. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export { Footer };
