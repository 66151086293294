import React from 'react';

const IconAll = ({ color = '#5B6670' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.642 3.52a.577.577 0 0 1 .849 0 .662.662 0 0 1 0 .898l-7.613 8.063a.577.577 0 0 1-.848 0L.842 9.105a.662.662 0 0 1 0-.899.577.577 0 0 1 .849 0l2.763 2.927zm3.516 0c.208.22.231.562.069.81l-.07.088-7.612 8.063a.576.576 0 0 1-.774.067l-.075-.067-.423-.448 7.191-7.615.02-.026.825-.873a.577.577 0 0 1 .849 0zM4.283 8.14l.075.066.763.808-.849.9-.763-.809a.664.664 0 0 1-.07-.81l.07-.089a.575.575 0 0 1 .774-.067z"
        fill={color} fillRule="evenodd"/>
    </svg>
  );
};
export default IconAll;
