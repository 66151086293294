import React, { useEffect, useMemo, useState } from 'react';
import TableLoader from 'components/TableLoader';

import { useTranslation } from "react-i18next";
import { getCertsInfo, getReportsInfo } from '../utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { log } from 'util';
import { TableStub } from '../../../TableStub';

import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';



const DashBoard = (props) => {
  const [reports, setReports] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const profile = useSelector((state: IAppState) => state.auth?.profile);
  const [ispowerBi, setIsPowerBi] = useState<any>(reports[0]);
  const [isExpended, setIsExpended] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (props.dashboard) {
      getReportsInfo()
        .then(res => setReports(res))
    } else if (!props.dashboard) {
      getCertsInfo()
        .then(res => setReports(res))
    }
  }, [])

  return (
    <div
      className="tab-pane"
      id="DASHBOARD"
      role="tabpanel"
      aria-labelledby="certificates-tab"
    >
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          <div>
            {reports.length > 0 && <>
              <SideNav
                expanded={isExpended}
                onToggle={(expanded) => {
                  setIsExpended(expanded)
                }}
                onSelect={(selected) => {
                  setIsPowerBi(selected)
                  setIsExpended(false)
                 
                }}
              >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected={reports[0]}>
                  {reports.map((report, index) => {
                    return (
                      <NavItem eventKey={report}>
                        <NavIcon>
                          {index + 1}
                        </NavIcon>
                        <NavText>
                          {report.name.length > 28 ? report.name.slice(0, 29).concat('...') : report.name}
                        </NavText>
                      </NavItem>
                    )
                  })}
                </SideNav.Nav>
              </SideNav>
              {ispowerBi ?
                <main>
                  <h2 className="table-heading">{ispowerBi.name}</h2>
                  <iframe width="1080" height="680"
                    src={`${ispowerBi.link}`}
                    frameBorder="0" allowFullScreen={true}></iframe>
                </main>
                :
                <main>
                  <h2 className="table-heading">{reports[0].name}</h2>
                  <iframe width="1080" height="680"
                    src={`${reports[0].link}`}
                    frameBorder="0" allowFullScreen={true}></iframe>
                </main>
              }
            </>}
            {/* {reports.length > 0 && reports.map(report => {
              console.log(`${report.link}&filter=${report.tableName}/AlltechID eq '${profile.AlltechID}'`)
              return (
                <>
                  <h2 className="table-heading">{report.name}</h2>
                  <iframe width="1140" height="680"
                    src={`${report.link}&filter=${report.tableName}/AlltechID eq '${profile.AlltechID}'`}
                    frameBorder="0" allowFullScreen={true}></iframe>
                </>
              )
            })} */}
            {reports.length < 1 && <TableStub description={t('DOCUMENTS_There are currently no documents to view')} />}
          </div>
        </>
      )}
    </div>
  );
};

export { DashBoard };
