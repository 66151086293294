import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { LoginScreen } from 'pages/LoginScreen';
import { PrivateRoute } from './PrivateRoute';
import { DashboardScreen } from 'pages/DashboardScreen';
import { Callback } from '../components/auth/Callback';
import { Logout } from '../components/auth/Logout';
import { LogoutCallback } from '../components/auth/LogoutCallback';
import { SilentRenew } from '../components/auth/SilentRenew';
import { NotFoundPage } from '../pages/NotFoundPage';
import { authActions } from '../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../providers/AuthProvider';
import { ROUTES } from '../config/routes';
import { IAppState } from '../store';

export const GlobalRouter = () => {
  const dispatch = useDispatch();
  const { getUser, isAuthenticated } = useContext(AuthContext);
  const userRole = useSelector(
    (state: IAppState) => state.auth.profile?.UserRoleName,
  );

  useEffect(() => {
    try {
      if (isAuthenticated() && !userRole) {
        getUser().then(user => {
          if (user) {
            dispatch(authActions.authorizeUserAction(user?.profile));
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, getUser, isAuthenticated, userRole]);

  return (
    <BrowserRouter basename={'/'}>
      <div className="global-wrapper">
        <Route exact path={ROUTES.home} component={LoginScreen} />
        <Route path={ROUTES.callback} component={Callback} />
        <Route exact path={ROUTES.logout} component={Logout} />
        <Route exact path={ROUTES.logoutCallback} component={LogoutCallback} />
        <Route exact path={ROUTES.silentRenew} component={SilentRenew} />
        <Route exact path={ROUTES.notFound} component={NotFoundPage} />
        <PrivateRoute path={ROUTES.dashboard} component={DashboardScreen} />
      </div>
    </BrowserRouter>
  );
};
