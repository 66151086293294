import React from 'react';
import ArrowDown from '../../assets/icons/ic-arrow-down.svg';
import './TableFilter.scss';

interface IProps {
  active: boolean;
  text: string;
  onSort: () => void;
}

const TableFilter = ({ active, text, onSort }: IProps) => {
  return (
    <button className="table-select__input-wrapper" onClick={onSort}>
      <p className="table-select__input">{text}</p>
      <img
        src={ArrowDown}
        alt="arrow down icon"
        className={`table-select__filter-img ${active && 'active'}`}
      />
    </button>
  );
};
export { TableFilter };
