import { Reducer } from 'redux';
import {
  RetailerKpiState,
  RetailerKpiActions,
  RetailerKpiValueModel,
} from './types';

const initialRetailerKpiState: RetailerKpiState = {
  isLoading: false,
  kpisWithoutDrillDown: [],
  selectedFarmer: '',
  selectedProcessor: '',
  selectedProject: '',
  selectedSystemType: '',
  systemTypes: [],
  farmers: [],
  filteredKpis: [],
  projects: [],
  processors: [],
  projectsInfo: [],
  drillDownKpi: [],
  drillDownSelectedFarmer: '',
  drillDownSelectedProject: '',
  drillDownSelectedProcessor: '',
  drillDownFarmers: [],
  drillDownProjects: [],
  drillDownProcessors: [],
  drillDownSelectedParts: [],
  selectedKpis: [],
  sortKey: '',
  filter: '',
  sortDescending: false,
  selectedAssessments: [],
  selectedFarmers: [],
  selectedTopFarmersKpiId: null,
  topFarmersLoading: false,
  topFarmersData: [],
  dataList: [],
  dataListDrill: [],
  filteredSpecies: [],
  filteredProcessor: [],
  filteredProject: [],
};

const createListOfFiltersFromAssessments = (
  assessments,
  key: string,
): string[] => {
  const newSet: Set<string> = new Set();

  assessments.forEach(assessment => {
    newSet.add(assessment[key]);
  });
  return Array.from(newSet);
};

export const retailerKpiReducer: Reducer<
  RetailerKpiState,
  RetailerKpiActions
> = (state = initialRetailerKpiState, action) => {
  switch (action.type) {
    case 'RETAILER_KPI_PENDING_INFO':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'RETAILER_KPI_SET_KPI_INFO':
      return {
        ...state,
        dataList: action.payload,
        // systemType: createListOfFiltersFromAssessments(action.payload, "SpeciesFilterValues")
        // kpisWithoutDrillDown: action.payload,
        // farmers: createListOfFiltersFromAssessments(
        //   action.payload,
        //   'SpeciesFilterValues',
        // ),
      };
    case 'RETAILER_KPI_SET_SELECTED_FARMER':
      return {
        ...state,
        selectedFarmer: action.payload,
        processors: createListOfFiltersFromAssessments(
          state.kpisWithoutDrillDown.filter(
            kpi => kpi.FarmerName === action.payload,
          ),
          'ProcessorName',
        ),
        selectedSystemType: initialRetailerKpiState.selectedSystemType,
        selectedProject: initialRetailerKpiState.selectedProject,
        selectedProcessor: initialRetailerKpiState.selectedProcessor,
        projects: initialRetailerKpiState.projects,
        systemTypes: initialRetailerKpiState.systemTypes,
      };
    case 'RETAILER_KPI_SET_SELECTED_PROCESSOR':
      return {
        ...state,
        selectedProcessor: action.payload,
        selectedProject: initialRetailerKpiState.selectedProject,
        selectedSystemType: initialRetailerKpiState.selectedSystemType,
        projects: createListOfFiltersFromAssessments(
          state.kpisWithoutDrillDown
            .filter(kpi => kpi.FarmerName === state.selectedFarmer)
            .filter(kpi => kpi.ProcessorName === action.payload),
          'ProjectName',
        ),
        systemTypes: initialRetailerKpiState.systemTypes,
      };
    case 'RETAILER_KPI_SET_SELECTED_PROJECT':
      return {
        ...state,
        selectedProject: action.payload,
        selectedSystemType: initialRetailerKpiState.selectedSystemType,
        systemTypes: createListOfFiltersFromAssessments(
          state.kpisWithoutDrillDown
            .filter(kpi => kpi.FarmerName === state.selectedFarmer)
            .filter(kpi => kpi.ProcessorName === state.selectedProcessor)
            .filter(kpi => kpi.ProjectName === action.payload),
          'SystemType',
        ),
      };
    case 'RETAILER_KPI_SET_SELECTED_SYSTEM_TYPE':
      return {
        ...state,
        selectedSystemType: action.payload,
      };
    case 'RETAILER_SET_FILTER_SPECIES':
      return {
        ...state,
        filteredSpecies: action.payload,
      };
    case 'RETAILER_SET_FILTER_PROJECT':
      return {
        ...state,
        filteredProject: action.payload,
      };
    case 'RETAILER_SET_FILTER_PROCESSOR':
      return {
        ...state,
        filteredProcessor: action.payload,
      };
    case 'RETAILER_KPI_SET_PROJECTS_INFO':
      return {
        ...state,
        projectsInfo: action.payload,
      };
    case 'RETAILER_DRILLDOWN_SET_KPI':
      return {
        ...state,
        dataListDrill: action.payload,
      };
    case 'RETAILER_DRILLDOWN_SET_FARMER':
      return {
        ...state,
        drillDownSelectedFarmer: action.payload,
        drillDownProjects: createListOfFiltersFromAssessments(
          state.drillDownKpi.filter(a => a.FarmerName === action.payload),
          'ProjectName',
        ),
        drillDownSelectedProject:
          initialRetailerKpiState.drillDownSelectedProject,
        drillDownProcessors: initialRetailerKpiState.drillDownProcessors,
        drillDownSelectedProcessor:
          initialRetailerKpiState.drillDownSelectedProcessor,
      };
    case 'RETAILER_DRILLDOWN_SET_PROJECT':
      return {
        ...state,
        drillDownSelectedProject: action.payload,
        drillDownProcessors: createListOfFiltersFromAssessments(
          state.drillDownKpi
            .filter(a => a.FarmerName === state.drillDownSelectedFarmer)
            .filter(a => a.ProjectName === action.payload),
          'ProcessorName',
        ),
        drillDownSelectedProcessor:
          initialRetailerKpiState.drillDownSelectedProcessor,
      };
    case 'RETAILER_DRILLDOWN_SET_PROCESSOR':
      return {
        ...state,
        drillDownSelectedProcessor: action.payload,
      };
    case 'RETAILER_DRILL_DOWN_SET_ASSESSMENT_PARTS':
      return {
        ...state,
        drillDownSelectedParts: state.drillDownSelectedParts.some(
          p => p === action.payload,
        )
          ? state.drillDownSelectedParts.filter(p => p !== action.payload)
          : [...state.drillDownSelectedParts, action.payload],
      };
    case 'RETAILER_DRILL_DOWN_SET_ASSESSMENT_PARTS_CLEAR':
      return {
        ...state,
        drillDownSelectedParts: [],
      };
    case 'RETAILER_SET_TOP_FARMERS_PENDING':
      return {
        ...state,
        topFarmersLoading: action.payload,
      };
    case 'RETAILER_SET_TOP_FARMERS':
      return {
        ...state,
        topFarmersData: action.payload.data,
        ...action.payload.filters,
      };
    default:
      return { ...state };
  }
};
