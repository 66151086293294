import { CONDITIONS } from './enum';

const checkfieldCondition = (type, value, idToSearch, data) => {
  const assesmentData = data.length > 0 ? data?.[0] : data;

  const findFieldArray = assesmentData.Pages?.map(tab =>
    tab.Fields.filter(f => f.Template.ID === idToSearch),
  ).filter(f => f.length >= 1)?.[0];

  let arr = [];
  assesmentData.Pages?.map(tab =>
    tab.Fields?.map(field => {
      if (field.GridChildren.length !== 0) {
        arr.push(field.GridChildren);
        return field.GridChildren;
      }
    }),
  );
  const allFieldsWithChildren = arr
    .flat(Infinity)
    ?.filter(el => el.Template.ID === idToSearch);
  if (findFieldArray !== undefined) {
    allFieldsWithChildren.push(findFieldArray);
  }
  const fields = allFieldsWithChildren.flat(Infinity);

  if (allFieldsWithChildren !== undefined) {
    switch (type) {
      case CONDITIONS.GREATER:
        if (fields.some(el => el?.Value < value)) {
          return true;
        } else {
          return false;
        }

      case CONDITIONS.LESS:
        if (fields.some(el => el?.Value > value)) {
          return true;
        } else {
          return false;
        }
      case CONDITIONS.EQUAL:
        if (fields.some(el => {
          return el?.Value?.toString() == value?.toString()})) {
          return true;
        } else {
          return false;
        }
      case CONDITIONS.GREATER_OR_EQUAL:
        if (fields.some(el => el?.Value <= value)) {
          return true;
        } else {
          return false;
        }

      case CONDITIONS.LESS_OR_EQUAL:
        if (fields.some(el => el?.Value >= value)) {
          return true;
        } else {
          return false;
        }
      case CONDITIONS.NO_EQUAL:
        if (fields.some(el => el?.Value != value)) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  } else {
    return false;
  }
};

export const checkAllConditionsFollow = (p, data) => {
  const allConditionsFollow = p.Template.DisplayConditions?.map(condition => {
    const type = condition.FieldConditionType.TypeName;
    const value = condition.Value;
    const id = condition.LinkedField_ID;
    const checkOneFieldCond = checkfieldCondition(type, value, id, data);
    // console.log(type, value, id, checkOneFieldCond)
    return checkOneFieldCond;
  });
  // console.log(allConditionsFollow)
  let display = false;
  if(allConditionsFollow.includes(true)){
    display = true;
  }
  else{
    display = false;
  }
  return display;
  // if (allConditionsFollow.length > 1) {
  //   return allConditionsFollow.every(el => el === true);
  // } else {
  //   return allConditionsFollow[0];
  // }
};
