export const createGetParams = (obj: any): string => {
  let getParams = [];
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value) && value.length) {
      getParams.push(`${key}=${value.join(',')}`);
    } else if (value.toString().length) {
      getParams.push(`${key}=${value}`);
    }
  }
  return `?${getParams.join('&')}`;
};
