import React from "react";
import { AuthConsumer } from "../../../providers/AuthProvider";

export const Logout = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return <span>loading</span>;
    }}
  </AuthConsumer>
);
