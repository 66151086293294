export const userManagerConfig = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/callback`,
  response_type: "code",
  scope: "openid profile eco2userapi eco2customerapi",
  authority: "https://alltecheco2auth.azurewebsites.net/",
  silent_redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/silent-renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}`
};
