import { Reducer } from 'redux';
import { FarmerKpiState, FarmerKpiActions, KpiValueModel } from './types';

const initialFarmerKpiState: FarmerKpiState = {
  selectedAssessment: '',
  selectedSpecies: '',
  isLoading: false,
  error: null,
  kpisWithoutDrillDown: [],
  filteredKpis: [],
  assessments: [],
  categories: [],
  selectedCategory: '',
  emissionsFilteredAssessments: [],
  emissionsSelectedAssessment: '',
  emissionsSelectedSpecies: '',
  drillDownAssessments: [],
  drillDownSelectedAssessment: '',
  drillDownSelectedParts: [],
  drillDownSelectedSpecies: '',
  drillDownSpecies: [],
  filteredDrillDownAssessments: [],
  dataList: {},
  dataListDrill: {},
  filteredSpecies: [],
  filteredProject: [],
};

const createListOfCategoriesFromKpis = (
  kpis: Array<KpiValueModel>,
): Array<string> => {
  const kpisSet: Set<string> = new Set();
  kpis.forEach(kpi => {
    kpisSet.add(kpi.Category);
  });
  return Array.from(kpisSet);
};

export const farmerKpiReducer: Reducer<FarmerKpiState, FarmerKpiActions> = (
  state = initialFarmerKpiState,
  action,
) => {
  switch (action.type) {
    case 'FARMER_KPI_PENGING_KPI_INFO':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'FARMER_KPI_SET_KPI_VALUES':
      return {
        ...state,
        dataList: action.payload,
      };
    case 'FARMER_KPI_SET_CHOSEN_CATEGORY':
      return {
        ...state,
        filteredKpis: state.kpisWithoutDrillDown.filter(
          k => k.Category === action.payload,
        ),
        selectedCategory: action.payload,
        selectedAssessment: '',
      };
    case 'FARMER_KPI_SET_CHOSEN_ASSESSMENT':
      return {
        ...state,
        selectedAssessment: action.payload,
      };
    case 'FARMER_KPI_SET_CHOSEN_EMISSIONS_ASSESSMENT':
      return {
        ...state,
        emissionsSelectedAssessment: action.payload,
      };
    case 'FARMER_SET_FILTER_SPECIES':
      return {
        ...state,
        filteredSpecies: action.payload,
      };
    case 'FARMER_SET_FILTER_PROJECT':
      return {
        ...state,
        filteredProject: action.payload,
      };
    case 'FARMER_KPI_SET_CHOSEN_EMISSIONS_CATEGORY':
      return {
        ...state,
        emissionsSelectedSpecies: action.payload,
        emissionsFilteredAssessments: state.kpisWithoutDrillDown.filter(
          k => k.Category === action.payload,
        ),
        emissionsSelectedAssessment: '',
      };
    case 'FARMER_KPI_SET_DRILL_DOWN_DATA':
      return {
        ...state,
        dataListDrill: action.payload,
      };
    case 'FARMER_KPI_SET_CHOSEN_DRILL_DOWN_CATEGORY':
      return {
        ...state,
        filteredDrillDownAssessments: state.drillDownAssessments.filter(
          k => k.Category === action.payload,
        ),
        drillDownSelectedSpecies: action.payload,
        drillDownSelectedAssessment: '',
        drillDownSelectedParts: [],
      };
    case 'FARMER_KPI_SET_DRILL_DOWN_CHOSEN_ASSESSMENT':
      return {
        ...state,
        drillDownSelectedAssessment: action.payload,
        drillDownSelectedParts: [],
      };
    case 'FARMER_KPI_SET_DRILL_DOWN_SELECTED_PARTS':
      return {
        ...state,
        drillDownSelectedParts: state.drillDownSelectedParts.some(
          p => p === action.payload,
        )
          ? state.drillDownSelectedParts.filter(p => p !== action.payload)
          : [...state.drillDownSelectedParts, action.payload],
      };
    default:
      return { ...state };
  }
};
