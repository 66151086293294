import { AxiosResponse } from 'axios';
import { ISetSuppliersAction, SuppliersModel } from '../../store/suppliers/types';
import Api from '../../api';

export const getCategories = async () => {
  try {
    const response = await Api(false).get(
      `/api/Assessments/GetCategories`,
    );
    if (response.status === 200) {
      const categories = response.data;

      return categories;
    }
  } catch (e) {
    console.log(e);
  }
}

export const getSubCategoriesTemplate = async (id) => {
  try {
    const response = await Api(false).get(
      `/api/Assessments/GetSubCategoryTemplates?selectedSubCategories[]=${id}`,
    );
    if (response.status === 200) {
      const categories = response.data;

      return categories;
    }
  } catch (e) {
    console.log(e);
  }
}
export const createTemplate = async (id) => {
  try {
    const response = await Api(false).post(
      `/api/Assessments/CreateAssessment`, {TemplateID: id}
    );
    if (response.status === 200) {
      const categories = response.data;

      return categories;
    }
  } catch (e) {
    console.log(e);
  }
}
