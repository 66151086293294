import React from 'react';
import { AuthConsumer } from '../../../providers/AuthProvider';
import { useHistory } from 'react-router';
import TableLoader from '../../TableLoader';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/auth/actions';
import {ROUTES} from "../../../config/routes";

export const Callback = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectCallback = () => {
    history.replace(ROUTES.main);
  };
  const dispatchUserAuth = user => {
    dispatch(authActions.authorizeUserAction(user));
  };
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback(redirectCallback, dispatchUserAuth);
        return <TableLoader />;
      }}
    </AuthConsumer>
  );
};
