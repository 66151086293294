import React from 'react';
const IconCopy = ({color}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.333 14h7.334V4.667H3.333m0-1.334h7.334c.736 0 1.333.597 1.333 1.334V14c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 14V4.667c0-.737.597-1.334 1.333-1.334m2-2.666h8c.737 0 1.334.597 1.334 1.333v9.333h-1.334V2h-8V.667z" fill={color} fill-rule="evenodd"/>
    </svg>

  )
}
export default IconCopy;
