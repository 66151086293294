import React, { useRef } from 'react';
import './NumberInput.scss';
import { useField, useFormikContext } from 'formik';
import InfoIcon from '../../../assets/icons/ic-info.svg';
import {
  Tooltip,
  OverlayTrigger,
  FormGroup,
  FormLabel,
  Form,
} from 'react-bootstrap';

interface IProps {
  name: string;
  labelText: string;
  toolTipText: string;
  disabled?: boolean;
  required?: boolean;
  postfixText?: string;
  imageFile?: any;
  OriginalHeight?: number | null;
  ImageHeight?: number | null;
  OriginalWidth?: number | null;
  ImageWidth?: number | null;
  OriginalSize?: boolean;
  MaintainAspectRatio?: boolean;
  newData?: any;
  oneField?: any;
  isGrid?:boolean
}

const NumberInput = ({
  name,
  labelText,
  imageFile,
  toolTipText,
  disabled = false,
  required = false,
  postfixText,
  OriginalSize,
  OriginalWidth,
  OriginalHeight,
  ImageHeight,
  ImageWidth,
  MaintainAspectRatio,
  newData,
  oneField,
  isGrid

}: IProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  let prefixText = '';
  let ifPrefixText = false;
  switch (field.name) {
    case '4803b5b9-3d25-4855-8c3e-8383ec5ddbdb[0][number-of-eggs_0]':
      ifPrefixText = true;
      prefixText = '< 1040 kg/m3';
      break;
    case '4803b5b9-3d25-4855-8c3e-8383ec5ddbdb[1][number-of-eggs_0]':
      ifPrefixText = true;
      prefixText = '1050 kg/m3';
      break;
    case '4803b5b9-3d25-4855-8c3e-8383ec5ddbdb[2][number-of-eggs_0]':
      ifPrefixText = true;
      prefixText = '1060 kg/m3';
      break;
    case '4803b5b9-3d25-4855-8c3e-8383ec5ddbdb[3][number-of-eggs_0]':
      ifPrefixText = true;
      prefixText = '1070 kg/m3';
      break;
    case '4803b5b9-3d25-4855-8c3e-8383ec5ddbdb[4][number-of-eggs_0]':
      ifPrefixText = true;
      prefixText = '1080 kg/m3';
      break;
    case '4803b5b9-3d25-4855-8c3e-8383ec5ddbdb[5][number-of-eggs_0]':
      ifPrefixText = true;
      prefixText = '> 1090 kg/m3';
      break;
  }
  const changeFieldsValue = checkedValue => {
    const findField = newData?.Pages?.map(tab =>
      tab?.Fields?.forEach(f => {
        if (f.Template.ID === oneField.Template.ID) {
          f.Value = +checkedValue || null;
        }
      }),
    );
    if (+checkedValue) {
      setFieldValue(field.name, +checkedValue);
    } else {
      setFieldValue(field.name, null);
    }
  };
  return (
    <FormGroup className="form-group">
      <FormLabel className={`label-wrapper ${toolTipText && 'with-tooltip'}`}>
        {required && <span className="required-indicator">*</span>}
        {labelText}
        {/* //to delete after testing */}
        {toolTipText && !isGrid && (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${field.name}`}>{toolTipText}</Tooltip>
              }
            >
              <img
                src={InfoIcon}
                alt="info img"
                className="tooltip-trigger"
                data-toggle="tooltip"
              />
            </OverlayTrigger>
          </>
        )}
      </FormLabel>
      {imageFile && OriginalSize ? (
        <img
          height={OriginalHeight}
          width={OriginalWidth}
          src={`data:image/png;base64, ${imageFile}`}
          alt="img"
        />
      ) : null}
      {imageFile && !OriginalSize && !MaintainAspectRatio ? (
        <img
          height={ImageHeight}
          width={ImageWidth}
          src={`data:image/png;base64, ${imageFile}`}
          alt="img"
        />
      ) : null}
      {imageFile && !OriginalSize && MaintainAspectRatio ? (
        <img
          width={ImageWidth}
          src={`data:image/png;base64, ${imageFile}`}
          alt="img"
        />
      ) : null}
      <div className="input-with-postfix">
        <div className={ifPrefixText ? 'ifPrefixText' : ''}>{prefixText}</div>
        <input
          type="number"
          id={field.name}
          className={`form-control ${meta.error && 'is-invalid'}`}
          {...field}
          value={field.value}
          disabled={disabled}
          onChange={e => {
            changeFieldsValue(e.target.value);
          }}
        />
        {meta.error && <p className="error-wrapper">{meta.error}</p>}
        {postfixText && <span className="postfix">{` ${postfixText}`}</span>}
      </div>
    </FormGroup>
  );
};

export { NumberInput };
