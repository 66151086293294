import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { userManagerConfig } from './userManager';
import { readAuthDataFromSessionStorage } from '../utils/sessionstorage';

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...userManagerConfig,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    // this.UserManager.events.addUserLoaded(user => {
    //   if (window.location.href.indexOf('signin-oidc') !== -1) {
    //     this.navigateToScreen();
    //   }
    // });
    this.UserManager.events.addSilentRenewError(e => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.silentLogOut();
      // this.signinSilent();
    });
  }

  signinRedirectCallback = (
    redirectCallback: () => void,
    dispatchAuth: (user) => void,
  ) => {
    this.UserManager.signinRedirectCallback().then(user => {
      redirectCallback();
      dispatchAuth(user.profile);
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  isAuthenticated = () => {
    const oidcStorage = readAuthDataFromSessionStorage();

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        console.log('signed in silently', user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  signinSilentCallback = async () => {
    const user = await this.UserManager.signinSilentCallback();
    console.log('user silent renew', user);
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: readAuthDataFromSessionStorage()?.id_token,
    });
    this.UserManager.clearStaleState();
  };

  silentLogOut = () => {
    this.UserManager.clearStaleState();
    sessionStorage.clear();
    window.location.pathname = '/';
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };
}
