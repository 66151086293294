import React from 'react';
import "./TableLoader.scss"

const TableLoader = () => {
  return (
  <div className="preloader">
    <div className="preloader__circle"/>
  </div>
  );
};

export default TableLoader;